import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import APIContext from "context/APIContext";
import PageTitle from "components/layout-components/PageTitle";
import "./style.scss";
import FormikPersist from "components/utils/FormikPersist";
import {
  Chip,
  CircularProgress,
  Collapse,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { FormikSelectField } from "formik-material-fields";
import MyButton, { GeneratingButton } from "components/Controls/MyButton";
import ShowIf, { ShowVisibleIf } from "components/common/ShowIf";
import { Formik, Form } from "formik";
import UniversalInput, {
  ChangeDataOnLocation,
  convertUniversalInput,
  topicToUniversalOption,
} from "components/Controls/UniversalInput";
import GameCard, { GameIcon } from "components/common/GameCard";
import * as Yup from "yup";
import usePersistedState from "hooks/usePersistedState";
import DetailsPanelContext from "context/DetailsPanelContext";
import Plot from "react-plotly.js";
import { SOURCE_LABELS } from "pages/Trends";
import PerformanceUtils from "helpers/PerformanceUtils";
import { Hint } from "scenes/Headquarters";
import ErrorBoundary from "components/utils/ErrorBoundary";
import _ from "lodash";
import SocketContext from "context/SocketContext";
import { useLocation } from "react-router";
import LoadingTip, { LOADING_TIPS_SECTIONS } from "components/utils/LoadingTip";
import AuthContext from "../../context/AuthContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import {ChevronRightOutlined, TryOutlined} from "@mui/icons-material";
import LudoCarousel from "../../components/common/LudoCarousel";
import { getGameScoreData } from "../Trends/TrendGames";
import textVersion from "textversionjs";
import ExpandIcon from "../../components/common/ExpandIcon";

const competitiveAnalysis = "competitiveAnalysis";
const getTrendOptions = "getTrendOptions";
const getGameInformation = "getGameInformation";
const getGamesInformation = "getGamesInformation";
const getTopChartsCountries = "getTopChartsCountries";
const getGamesTrends = "getGamesTrends";

const DEFAULT_ARRAY = [];

const SOURCES_WITHOUT_COUNTRY = ["steam", "itch"];

const SHOWN_GAMES = 5;

const DEFAULT_REQUEST = {
  country: "United States",
  sources: "appstore+playstore",
  topics: [],
};

var lastReqId;

const CompetitiveAnalysis = ({
  defaultInput = undefined,
  formId = "",
  onChat,
}) => {
  const location = useLocation();
  const { auth } = useContext(AuthContext);
  const { track } = useContext(SocketContext);
  const { showCompetitiveAnalysis } = useContext(DetailsPanelContext);
  const { call, loading } = useContext(APIContext);
  const [scrollToMetric, setScrollToMetric] = useState();

  useEffect(() => {
    setScrollToMetric();
  },[scrollToMetric])

  const defaultSources =
    defaultInput?.sources ||
    (auth.user.platform === "Mobile"
      ? "appstore+playstore"
      : auth.user.platform === "Desktop"
      ? "steam"
      : DEFAULT_REQUEST.sources);

  const [overrideInput, setOverrideInput] = useState(defaultInput);

  const isGDD = !!defaultInput;

  const [results, setResults, loadingResults] = usePersistedState(
    "CompetitiveAnalysis.results4" + formId,
    undefined
  );
  const [selectedCluster, setSelectedCluster] = usePersistedState(
    "CompetitiveAnalysis.selectedCluster1" + formId,
    undefined
  );
  const [resizeIndex, setResizeIndex] = useState(0);
  const [sourceCountry, setSourceCountry] = usePersistedState(
    "CompetitiveAnalysis.sourceCountry" + formId,
    {
      country: DEFAULT_REQUEST.country,
      sources: defaultSources,
    }
  );

  useEffect(() => {
    function handleResize() {
      setResizeIndex((prevState) => prevState + 1);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (overrideInput && !loadingResults && !results) {
      onSubmit(overrideInput, undefined, []);
    }
  }, [overrideInput, loadingResults, results]);

  useEffect(() => {
    if (
      !loadingResults &&
      !results?.clusters &&
      !results?.metrics &&
      !defaultInput
    ) {
      onSubmit(undefined, undefined, []);
    }
  }, [results, loadingResults, defaultInput]);

  async function onUpdateScore() {
    await onSubmit(overrideInput, undefined, []);
  }

  async function onSubmit(
    values,
    formik,
    currentClusters = results?.clusters,
    sourceCountryData = sourceCountry,
    formikUpdate = true,
    overrideLocationData = false
  ) {
    if (!!location?.state?.data && !overrideLocationData) return;

    const topics = (currentClusters || [])
      .filter((cluster) => !cluster.is_benchmark)
      .map((cluster) => cluster.topic_query);

    let newSelectedCluster = topics[0]?.id;

    if (sourceCountryData) delete sourceCountryData.key;

    let data;

    if (formik) {
      data = { ...(values || {}), ...(sourceCountryData || {}) };

      if (SOURCES_WITHOUT_COUNTRY.includes(data.sources)) {
        data.country = "All";
      }

      let id = PerformanceUtils.generateId();
      newSelectedCluster = id;

      let newTopics = [
        ...topics,
        {
          id,
          //cluster_width: data.cluster_width,
          ...convertUniversalInput(data.search),
          genres_filter: [],
        },
      ];

      data = {
        country: data.country,
        sources: data.sources,
        topics: newTopics,
      };
    } else {
      data = {
        ...DEFAULT_REQUEST,
        ...(values || {}),
        ...sourceCountryData,
        topics: _.uniqBy([...topics, ...(values?.topics || [])], "id"),
      };
    }

    let lastId = PerformanceUtils.generateId();
    lastReqId = lastId;
    let response = await call(competitiveAnalysis, { data });
    if (response.ok && lastReqId === lastId) {
      if (formik && formikUpdate) {
        formik.setValues({ ...values, search: [] });
      }
      setResults(response.body);

      if (!newSelectedCluster)
        newSelectedCluster = response.body.clusters[0]?.topic_query?.id;

      setSourceCountry({ ...sourceCountryData, key: lastId });

      setSelectedCluster(newSelectedCluster);
    }
  }

  function onRemoveCluster(cluster) {
    let newResults = {
      ...results,
      clusters: results.clusters.filter(
        ({ topic_query }) => topic_query?.id !== cluster.topic_query?.id
      ),
      metrics: [],
    };
    setResults(newResults);
    setSelectedCluster(
      cluster.topic_query?.id === selectedCluster
        ? newResults.clusters[0]?.topic_query?.id
        : selectedCluster
    );
    onSubmit(undefined, undefined, newResults.clusters, sourceCountry);
    track("competitive-analysis.remove-cluster", { cluster });
  }

  function onChangedSourceCountry(name, value) {
    track("competitive-analysis.changed-source-country", { [name]: value });
    setSourceCountry((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    onSubmit(undefined, undefined, results.clusters, {
      ...sourceCountry,
      [name]: value,
    });
  }

  const resultClusters = results?.clusters || DEFAULT_ARRAY;

  const orderedClusters = useMemo(() => {
    let nonBenchmarks = _.orderBy(
      resultClusters.filter((cluster) => !cluster.is_benchmark),
      "topic_query.added_time"
    );
    let benchmarks = resultClusters.filter((cluster) => !!cluster.is_benchmark);
    return [..._.reverse(nonBenchmarks), ...benchmarks];
  }, [resultClusters]);

  function onSeeAll(data) {
    let id = data.cluster?.topic_query?.id || data.metric?.id;
    showCompetitiveAnalysis({ ...data, id });
    track("competitive-analysis.see-all-games", { data });
  }

  const firstMetric = (results?.metrics || [])[0];

  return (
    <div className="competitive-analysis w-100">
      <PageTitle
        titleHeading="Market Analysis"
        titleDescription="Analyse and compare the market conditions of game topics"
      />
      {!loadingResults && (
        <AnalysisForm
          loading={loading[competitiveAnalysis]}
          onSubmit={onSubmit}
          sourceCountry={sourceCountry}
        />
      )}
      <SourceCountryForm
        onChangeForm={onChangedSourceCountry}
        loading={loading[competitiveAnalysis]}
        sourceCountry={sourceCountry}
        key={sourceCountry.key}
      />
      <ShowIf condition={loading[competitiveAnalysis]}>
        <LoadingTip
          style={{ marginLeft: "60px", marginTop: "30px" }}
          section={LOADING_TIPS_SECTIONS.marketAnalysis}
          visible={loading[competitiveAnalysis]}
          key={loading[competitiveAnalysis]}
        />
        <div className="spinner">
          <GeneratingButton
            loadingText="Loading Analysis...."
            className="gradient"
            loading={true}
            style={{ height: "60px", width: "400px" }}
            loadProgressSecs={20}
          />
        </div>
      </ShowIf>
      <div className="clusters" key={results?.clusters?.length}>
        <Grid container spacing={4}>
          {orderedClusters.map((cluster) => (
            <Grid item>
              <Cluster
                key={cluster.topic_query?.id}
                cluster={cluster}
                onRemove={onRemoveCluster}
                onSeeAll={onSeeAll}
                selectedCluster={selectedCluster}
                setSelectedCluster={setSelectedCluster}
                location={sourceCountry?.country}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="metrics" key={results?.metrics}>
        {isGDD && firstMetric && (
          <ErrorBoundary
            fallback={<span className="ml-4">An error has occurred</span>}
            key={firstMetric.id}
          >
            <MainMetric
              key={firstMetric.id}
              clusters={orderedClusters}
              metric={firstMetric}
              metrics={results?.metrics}
              selectedCluster={selectedCluster}
              resizeIndex={resizeIndex}
              location={sourceCountry?.country}
              onUpdateScore={onUpdateScore}
              scrollToMetric={(index) => setScrollToMetric(index)}
            />
          </ErrorBoundary>
        )}
        {isGDD && firstMetric && (
          <span className="additional-title gradient-text2">
            Detailed Views
          </span>
        )}
        {(results?.metrics || []).map(
          (metric, index) =>
            (!isGDD || index > 0) && (
              <ErrorBoundary
                fallback={<span className="ml-4">An error has occurred</span>}
                key={metric.id}
              >
                <Metric
                  key={metric.id}
                  clusters={orderedClusters}
                  metric={metric}
                  selectedCluster={selectedCluster}
                  setSelectedCluster={setSelectedCluster}
                  onSeeAll={onSeeAll}
                  resizeIndex={resizeIndex}
                  location={sourceCountry?.country}
                  isGDD={isGDD}
                  index={index}
                  onChat={onChat}
                  scroll={scrollToMetric === index}
                />
              </ErrorBoundary>
            )
        )}
      </div>
    </div>
  );
};

const SourceCountryForm = ({ onChangeForm, loading, sourceCountry }) => {
  const { call } = useContext(APIContext);
  const [countries, setCountries, loadingCountries] = usePersistedState(
    "CompetitiveAnalysis.Form.countries2",
    ["United States"]
  );
  const [sourcesOptions, setSourcesOptions, loadingSources] = usePersistedState(
    "CompetitiveAnalysis.Form.sourcesOptions1",
    []
  );

  useEffect(() => {
    if (!loadingCountries && countries.length === 1) {
      call(getTopChartsCountries).then((response) => {
        if (response.ok) setCountries(response.body);
      });
    }
  }, [countries, loadingCountries]);

  useEffect(() => {
    if (!loadingSources && sourcesOptions.length === 0) {
      call(getTrendOptions, { type: "charts" }).then((response) => {
        if (response.ok) {
          setSourcesOptions(response.body?.sources);
        }
      });
    }
  }, [sourcesOptions, loadingSources]);

  const initialValues = {
    country: sourceCountry?.country || "United States",
    sources:
      sourceCountry?.sources ||
      (sourcesOptions || [])[0] ||
      "appstore+playstore",
  };

  const formId = "CompetitiveAnalysis.Form.SourceCountry";

  return (
    <div className="source-country-form-wrapper my-2 pl-5">
      <Formik key={formId} initialValues={initialValues}>
        {(formik) => (
          <div className="d-flex flex-row">
            {/*<FormikPersist name={formId}/>*/}
            <Form>
              <Grid container>
                <Grid item xs={12} sm="auto" style={{ minWidth: "200px" }}>
                  <FormikSelectField
                    key={sourcesOptions.length}
                    disabled={loading}
                    onChange={(event) => {
                      onChangeForm(event.target.name, event.target.value);
                    }}
                    name="sources"
                    label="Source"
                    options={sourcesOptions.map((source) => {
                      return {
                        value: source,
                        label: SOURCE_LABELS[source] || source,
                      };
                    })}
                    fullWidth
                  />
                </Grid>
                <ShowVisibleIf
                  condition={
                    !SOURCES_WITHOUT_COUNTRY.includes(formik.values.sources)
                  }
                  className="show-visible-if"
                >
                  <Grid
                    item
                    xs={12}
                    sm="auto"
                    style={{ minWidth: "200px", marginLeft: "20px" }}
                  >
                    <FormikSelectField
                      disabled={loading}
                      onChange={(event) => {
                        onChangeForm(event.target.name, event.target.value);
                      }}
                      name="country"
                      label="Country"
                      options={countries.map((country) => {
                        return { value: country, label: country };
                      })}
                      fullWidth
                    />
                  </Grid>
                </ShowVisibleIf>
              </Grid>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

const Cluster = ({
  cluster,
  onRemove,
  onSeeAll,
  selectedCluster,
  setSelectedCluster,
  location,
}) => {
  const {
    title,
    keywords,
    game_ids,
    color,
    is_benchmark,
    in_charts_now,
    highest_rank_now,
    highest_ranked_games,
    topic_query,
  } = cluster;

  const { call } = useContext(APIContext);
  const { showGame } = useContext(DetailsPanelContext);
  const [topGame, setTopGame] = useState();

  const canRemove = !is_benchmark;
  const isActive = selectedCluster === topic_query?.id;
  const style = getClusterStyle(cluster, isActive);

  const topGameId = (highest_ranked_games || [])[0];

  useEffect(() => {
    if (topGameId) {
      call(getGameInformation, { id: topGameId }).then((response) => {
        if (response.ok) {
          setTopGame(response.body);
        }
      });
    }
  }, [topGameId]);

  return (
    <div className="cluster rounded-box" style={style}>
      <Chip
        label={title}
        style={{ background: color }}
        onClick={() => setSelectedCluster(topic_query?.id)}
        onDelete={canRemove ? () => onRemove(cluster) : null}
      />
      <span className="keywords min-height text-capitalize">
        {keywords.join(", ")}
      </span>
      <GameIcons
        ids={game_ids}
        key={game_ids}
        onClickMore={() => onSeeAll({ cluster })}
        size={cluster.size}
      />
      <div className="stats">
        <div className="highest-ranked">
          <span className="title">Highest ranked now</span>
          <div className="bottom-row">
            <ShowIf condition={highest_rank_now > 0}>
              <div className="big-number">{highest_rank_now}</div>
            </ShowIf>
            <span
              className="game-title text-truncate pointer"
              onClick={() => showGame(topGame, undefined, location)}
            >
              {topGame?.title}
            </span>
          </div>
        </div>

        <ShowIf condition={in_charts_now > 0}>
          <div className="in-charts">
            <span className="title">In charts</span>
            <span
              className="blue-number pointer"
              onClick={() =>
                onSeeAll({
                  cluster,
                  game_ids: highest_ranked_games,
                })
              }
            >
              {humanizeNumber(in_charts_now)}
            </span>
          </div>
        </ShowIf>
      </div>
    </div>
  );
};

const MainMetric = ({
  clusters,
  metric,
  metrics,
  selectedCluster,
  resizeIndex,
  location,
  onUpdateScore,
  scrollToMetric,
}) => {
  const [updatingScore, setUpdatingScore] = useState(false);

  const {
    name,
    plotly_plot,
    value_max,
    value_min,
    group_metrics,
    game_ids_title,
  } = metric;

  const groupMetrics = useMemo(() => {
    let result = [];
    clusters.forEach((cluster) => {
      let matchingMetric = group_metrics.find(
        (metric) => metric.topic_id === cluster.topic_query?.id
      );
      if (matchingMetric) {
        result.push({ metricData: matchingMetric, cluster });
      }
    });
    return result;
  }, [group_metrics, clusters]);

  const selectedMetricData = useMemo(() => {
    return metric.group_metrics.find(
      (metric) => metric.topic_id === selectedCluster
    );
  }, [selectedCluster, metric.group_metrics]);

  const selectedClusterData = useMemo(() => {
    return clusters.find(
      (cluster) => cluster.topic_query.id === selectedCluster
    );
  }, [clusters, selectedCluster]);

  const color = selectedClusterData?.color;

  const { metricData, cluster } = groupMetrics[0];
  const { value, value_text, value_unit } = metricData;

  const badge = selectedClusterData?.ludo_score?.badge;

  return (
    <>
      <div className="metric main-metric">
        <Grid container className="content">
          <Grid item className="content-left" sm="auto" lg="auto">
            <>
              <div className="header">
                <span className="name">
                  {name} <Hint hint={metric.help} iconClassName="help" />
                </span>
                <span className="description">
                  <img src={badge?.icon_url} />
                  {selectedMetricData?.text}
                </span>
              </div>
            </>
            <Grid container className="data-wrapper">
              <div className="value">
                <div className="numbers">
                  <span className="number">{value_text}</span>
                  <span className="unit">{value_unit}</span>
                </div>
                <MyButton
                  className="ask-ludo-button"
                  onClick={async () => {
                    setUpdatingScore(true);
                    await onUpdateScore();
                    setUpdatingScore(false);
                  }}
                  loading={updatingScore}
                >
                  Update Score
                </MyButton>
              </div>
              <div className="other-metrics">
                {metrics.map((metric, index) => {
                  const metricData = metric.group_metrics[0];
                  return (
                    index > 0 &&
                    metric.core_metric && (
                      <div className="other-metric clickable" key={metric.id} onClick={() => scrollToMetric(index)}>
                        <div className="top-row">
                          <span className="other-name">{metric.name} <ChevronRightOutlined/></span>
                          <span className="percentage">
                            {metricData.value_text}
                            {metricData.value_unit}
                          </span>
                        </div>
                        <LinearProgress
                          variant="determinate"
                          value={Math.ceil(metricData.value * 10) * 10}
                        />
                      </div>
                    )
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="metric secondary-metrics">
        <div className="content">
          <Grid item className="content-left" sm="auto" lg="auto">
            <>
              <div className="header">
                <span className="name">
                  Complementary Metrics Overview{" "}
                  <Hint
                    hint="These metrics are not used to calculate your game's Ludo Score but can offer more insights about how it positions itself in the current market"
                    iconClassName="help"
                  />
                </span>
              </div>
            </>
            <div className="data-wrapper">
              <div className="other-metrics">
                {metrics.map((metric, index) => {
                  const metricData = metric.group_metrics[0];
                  return (
                    !metric.core_metric && (
                      <div className="other-metric clickable" key={metric.id} onClick={() => scrollToMetric(index)}>
                        <div className="top-row">
                          <span className="other-name">{metric.name} <ChevronRightOutlined/></span>
                          <span className="percentage">
                            {metricData.value_text}
                            {metricData.value_unit}
                          </span>
                        </div>
                        <LinearProgress
                          variant="determinate"
                          value={Math.ceil(metricData.value * 10) * 10}
                        />
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};
const Metric = ({
  clusters,
  metric,
  selectedCluster,
  setSelectedCluster,
  onSeeAll,
  resizeIndex,
  location,
  isGDD,
  index,
  onChat,
  scroll = false,
}) => {
  const { call } = useContext(APIContext);
  const { showGame } = useContext(DetailsPanelContext);
  const ref = useRef();

  const [games, setGames] = useState([]);
  const [gameInfos, setGameInfos] = useState({});
  const [collapsePlot, setCollapsePlot] = useState(index > 0);

  const plotRef = useRef();

  useEffect(() => {
    if (scroll) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [ref, scroll]);

  const plotWidth = useMemo(() => {
    return plotRef?.current?.el?.offsetWidth;
  }, [resizeIndex, plotRef.current]);
  const {
    name,
    plotly_plot,
    value_max,
    value_min,
    group_metrics,
    game_ids_title,
  } = metric;

  const selectedMetricData = useMemo(() => {
    return metric.group_metrics.find(
      (metric) => metric.topic_id === selectedCluster
    );
  }, [selectedCluster, metric.group_metrics]);

  useEffect(() => {
    if (isGDD) {
      const gameIds = selectedMetricData?.game_ids;
      call(getGamesInformation, {
        data: { ids: gameIds },
      }).then((response) => {
        if (response.ok) {
          setGames(response.body);
        }
      });
      call(getGamesTrends, { data: { ids: gameIds } }).then((response) => {
        if (response.ok) {
          let newInfos = {};
          response.body.forEach((trend) => {
            if (trend.game_id) newInfos[trend.game_id] = trend.infos;
          });
          setGameInfos(newInfos);
        }
      });
    }
  }, [selectedMetricData, isGDD]);

  const selectedClusterData = useMemo(() => {
    return clusters.find(
      (cluster) => cluster.topic_query.id === selectedCluster
    );
  }, [clusters, selectedCluster]);

  const color = selectedClusterData?.color;

  function onClickedCluster(metric) {
    setSelectedCluster(metric.topic_id);
  }

  const groupMetrics = useMemo(() => {
    let result = [];
    clusters.forEach((cluster) => {
      let matchingMetric = group_metrics.find(
        (metric) => metric.topic_id === cluster.topic_query?.id
      );
      if (matchingMetric) {
        result.push({ metricData: matchingMetric, cluster });
      }
    });
    return result;
  }, [group_metrics, clusters]);

  async function onPlotClick({ points = [] }) {
    let ids = points[0]?.customdata;
    let id = Array.isArray(points[0]?.customdata)
      ? points[0].customdata[0]
      : points[0]?.customdata;
    if (id) {
      if (id && typeof id === "string") {
        let response = await call(getGameInformation, { id });
        if (response.ok) {
          showGame(response.body, undefined, location);
        }
      }
    }
  }

  const { plotData, plotLayout } = useMemo(() => {
    let { layout, data } = plotly_plot || {};

    let plotData = JSON.parse(JSON.stringify(data));
    const clusterTitles = clusters.map(({ title }) => title);
    const plotTitles = plotData.map(({ name }) => name);

    plotData = plotData.map((plotData) => {
      if (
        clusterTitles.includes(plotData.name) &&
        plotTitles.includes(selectedClusterData?.title)
      ) {
        let selected = plotData.name === selectedClusterData.title;
        let opacity = selected ? 1 : 0.7;
        if (plotData.line) {
          let color = PerformanceUtils.hexToRgbA(plotData.line.color).join(",");
          plotData.line.color = `rgba(${color},${opacity})`;
        }
        plotData.opacity = opacity;
        if (plotData.marker) plotData.marker.opacity = opacity;
        if (plotData.marker.line) plotData.marker.line.opacity = opacity;
      }
      return plotData;
    });

    return { plotData, plotLayout: layout };
  }, [plotly_plot, selectedClusterData]);

  const { metricData, cluster } = groupMetrics[0];
  const { value, value_text, value_unit } = metricData;

  const badge = selectedClusterData?.ludo_score?.badge;

  return (
    <div className={"metric " + metric.id} ref={ref}>
      <Grid container className="content">
        <Grid item className="content-left" sm="auto" lg="auto">
          {isGDD ? (
            <>
              <div className="header">
                <span className="name">
                  {name} <Hint hint={metric.help} iconClassName="help" />
                </span>
                <span className="separator" />
                <div className="value">
                  <span className="number">{value_text}</span>
                  <span className="unit">{value_unit}</span>
                </div>
                <span className="grow" />
                <MyButton
                  className="ask-ludo-button"
                  onClick={() =>
                    onChat(cluster.ludo_score, metric.id, metric.name)
                  }
                >
                  <TryOutlined className="font-size-xl mr-2" />
                  Ask Ludo
                </MyButton>
              </div>
              <LinearProgress
                variant="determinate"
                value={Math.ceil(value * 10) * 10}
              />
            </>
          ) : (
            <span className="name">
              {name} <Hint hint={metric.help} iconClassName="help" />
            </span>
          )}
          <Grid container className="data-wrapper">
            <div className="comparison">
              <div className="bar-graph">
                {groupMetrics.map(({ metricData, cluster }, index) => {
                  const { value, value_text, value_unit } = metricData;
                  const maxSize = 100;
                  const color = cluster?.color;

                  const isRight = value >= 0;
                  const totalDiff = Math.abs(value_max - value_min);
                  const percentToPixelRatio = maxSize / totalDiff;
                  const leftSidePx =
                    value_min < 0
                      ? percentToPixelRatio *
                        Math.min(Math.abs(value_min), Math.abs(totalDiff))
                      : 0;
                  const rightSidePx = maxSize - leftSidePx;

                  const leftInnerWidth = isRight
                    ? 0
                    : Math.abs(value / value_min) * leftSidePx;
                  const rightInnerWidth =
                    isRight && value_max !== 0
                      ? (value / value_max) * rightSidePx
                      : 0;

                  const spanStyle = { color };
                  let className = "bar-wrapper pointer";
                  if (selectedCluster === metricData.topic_id)
                    className += " active";

                  const leftBarInnerStyle = {
                    width: leftInnerWidth + "px",
                    borderLeft: `1px solid ${color}`,
                    background: color,
                  };
                  const rightBarInnerStyle = {
                    ...leftBarInnerStyle,
                    width: rightInnerWidth + "px",
                  };

                  return (
                    <div
                      className={className}
                      key={index}
                      onClick={() => onClickedCluster(metricData)}
                    >
                      <div
                        className="bar left"
                        style={{ width: leftSidePx + "px" }}
                      >
                        <div className="bar-inner" style={leftBarInnerStyle} />
                      </div>
                      <div className="bar right">
                        <div className="bar-inner" style={rightBarInnerStyle} />
                        <span style={spanStyle}>
                          {value_text}
                          {value_unit}
                        </span>
                      </div>
                      <div className="filler" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className={
                "cluster description-box rounded-box " +
                (!!selectedMetricData ? "active" : "inactive")
              }
              style={getClusterStyle(selectedClusterData)}
            >
              <Chip
                label={selectedClusterData?.title}
                style={{ background: color }}
              />
              <div className="title" style={{ color }}>
                <span>{selectedMetricData?.value_text}</span>
                <span className="unit">{selectedMetricData?.value_unit}</span>
              </div>
              <span className="description">
                {isGDD && metric.id === "ludo_score" && badge?.icon_url && (
                  <img src={badge.icon_url} />
                )}
                {selectedMetricData?.text}
              </span>
              <ShowIf
                condition={(selectedMetricData?.game_ids || []).length > 0}
              >
                <>
                  <span className="keywords">{game_ids_title}</span>
                  {isGDD ? (
                    <LudoCarousel className="games-carousel">
                      {games.map((game, index) => {
                        let gameInfo = gameInfos[game._id] || {};
                        let altText = getGameScoreData({
                          game_info: gameInfo,
                          game,
                        });
                        return (
                          <GameCard
                            key={game._id + index}
                            game={game}
                            lazyLoad={false}
                            altText={altText}
                          />
                        );
                      })}
                    </LudoCarousel>
                  ) : (
                    <GameIcons
                      ids={selectedMetricData?.game_ids}
                      key={selectedMetricData?.game_ids}
                      onClickMore={() =>
                        onSeeAll({
                          metric: selectedMetricData,
                          cluster: selectedClusterData,
                        })
                      }
                    />
                  )}
                </>
              </ShowIf>
            </div>
          </Grid>
        </Grid>
        {isGDD ? (
          <div className="collapse-wrapper">
            <div
              className="collapse-control"
              onClick={() => setCollapsePlot(!collapsePlot)}
            >
              <span className="flex-grow-1">
                <span className="font-weight-bold">
                  {textVersion(
                    plotLayout?.title?.text || `${name} Plot`
                  )?.trim()}
                </span>
              </span>
              <span className="hide-show">
                {!collapsePlot ? "Hide" : "Show"}
              </span>
              <ExpandIcon
                expanded={!collapsePlot}
                setExpanded={() => setCollapsePlot(!collapsePlot)}
              />
            </div>
            <Collapse in={!collapsePlot} timeout="auto" unmountOnExit>
              <CompetitivePlot
                plotWidth={plotWidth}
                plotRef={plotRef}
                onPlotClick={onPlotClick}
                plotData={plotData}
                plotLayout={{ ...plotLayout, title: undefined }}
              />
            </Collapse>
          </div>
        ) : (
          <CompetitivePlot
            plotWidth={plotWidth}
            plotRef={plotRef}
            onPlotClick={onPlotClick}
            plotData={plotData}
            plotLayout={plotLayout}
          />
        )}
      </Grid>
    </div>
  );
};

const CompetitivePlot = ({
  plotWidth,
  plotRef,
  onPlotClick,
  plotData,
  plotLayout,
}) => (
  <Grid
    item
    className="plot"
    sm={0}
    lg="auto"
    style={{ minWidth: plotWidth < 450 ? "100%" : "449px" }}
  >
    <ErrorBoundary fallback="An error has occurred">
      <Plot
        ref={plotRef}
        onClick={onPlotClick}
        data={plotData}
        layout={plotLayout}
        useResizeHandler={true}
        style={{ width: "100%" }}
      />
    </ErrorBoundary>
  </Grid>
);

export const GameIcons = ({
  ids = DEFAULT_ARRAY,
  onClickMore,
  size,
  showNumber = SHOWN_GAMES,
}) => {
  const { call } = useContext(APIContext);
  const [games, setGames] = useState();
  const { showGame } = useContext(DetailsPanelContext);

  const slicedIds = useMemo(() => {
    return ids.slice(0, showNumber);
  }, [ids, showNumber]);

  let additional = humanizeNumber((size || ids.length) - slicedIds.length);

  useEffect(() => {
    call(getGamesInformation, { data: { ids: slicedIds } }).then((response) => {
      if (response.ok) {
        setGames(response.body);
      }
    });
  }, [slicedIds]);

  return (
    <ShowIf condition={ids.length > 0}>
      <div className="game-icons">
        <ShowIf condition={ids.length > 0 && !!games}>
          {(games || []).map((game) => (
            <>
              <Tooltip
                title={game.title}
                arrow
                PopperProps={{
                  className:
                    "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                }}
                placement="top"
              >
                <span>
                  <GameIcon
                    game={game}
                    key={game._id}
                    onClick={showGame}
                    fallbackToCover={true}
                  />
                </span>
              </Tooltip>
            </>
          ))}
          {!!additional && (
            <span className="see-all pl-2" onClick={onClickMore}>
              +{additional}
            </span>
          )}
        </ShowIf>
        {!games && <CircularProgress size={15} />}
      </div>
    </ShowIf>
  );
};

/*export const CLUSTER_WIDTHS = [
  {
    label: <span>👍️ Balanced reach</span>,
    description: "Analyze a sensible group of games around the query",
    value: 2.5,
  },
  {
    label: <span>🎯️ Focused</span>,
    description: "Analyze a group of games tightly related to the query",
    value: 1.0,
  },
  {
    label: <span>🌐 Wide</span>,
    description: "Analyze a broader group of games around the query",
    value: 4.0,
  },
];*/

const AnalysisForm = ({ onSubmit, loading, sourceCountry = {} }) => {
  const [persistedData, setPersistedData] = useState();

  const initialValues = {
    search: [],
    //cluster_width: CLUSTER_WIDTHS[0].value,
  };

  function overrideChangeData(data, formik) {
    if (data.topic) {
      let values = {
        ...formik.values,
        search: [topicToUniversalOption(data.topic)],
        //cluster_width: 1.0,
      };
      let sourceCountryData = { ...sourceCountry };
      if (data.sources) sourceCountryData.sources = data.sources;

      onSubmit(values, formik, undefined, sourceCountryData, false, true);
    }
  }

  return (
    <div className="form-wrapper pb-3">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
      >
        {(formik) => (
          <Form>
            <FormikPersist
              name="CompetitiveAnalysis.Form"
              onLoad={(data) => setPersistedData(data)}
            />
            {!!persistedData && (
              <ChangeDataOnLocation
                shouldOverride={() => true}
                override={overrideChangeData}
              />
            )}
            <div className="d-flex flex-column">
              <Grid
                item
                container
                justifyContent="flex-start"
                alignItems="flex-end"
              >
                <Grid
                  item
                  sm={12}
                  md={12}
                  xs={12}
                  className="input-fields-wrapper"
                >
                  <div className="d-flex input-fields full-border-radius">
                    <UniversalInput
                      label="Type the game topic, or choose a game idea or an existing game"
                      name="search"
                      formik={formik}
                      onSetData={(data) => {
                        formik.setFieldValue("search", data);
                      }}
                      value={formik.values.search}
                      uploadMedia={false}
                      allowed={["game", "generated_game", "gdd", "topic"]}
                    />
                  </div>
                </Grid>
                <div className="d-flex align-self-center">
                  <GeneratingButton
                    id="competitive-analysis.analyse"
                    label="Add to Analysis"
                    className="gradient"
                    loadingText="Analysing..."
                    loading={loading}
                    style={{ margin: 0, marginLeft: "10px" }}
                    disabled={formik.values.search.length === 0}
                    trackOptions={{
                      ...formik.values,
                      search: convertUniversalInput(formik.values.search),
                    }}
                    loadProgressSecs={5}
                  />
                </div>
              </Grid>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

function humanizeNumber(number) {
  if (number >= 1000) {
    number = number / 1000.0;
    number = number.toFixed(0) + "k";
  }
  return number;
}

function getClusterStyle(cluster, isActive = true) {
  const rgbaColor = PerformanceUtils.hexToRgbA(cluster?.color) || [];
  return {
    border: isActive
      ? `3px solid ${rgbaColorString(rgbaColor, 0.15)}`
      : "3px solid transparent",
    background: isActive ? rgbaColorString(rgbaColor, 0.03) : undefined,
  };
}

function rgbaColorString(colorArray, opacity) {
  return `rgba(${colorArray.join(",")},${opacity})`;
}

const ValidationSchema = Yup.object().shape({});

export default CompetitiveAnalysis;
