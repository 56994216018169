import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { TextField } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  CardElement,
} from "@stripe/react-stripe-js";
import React, {
  useContext,
  useState,
  useRef,
  useImperativeHandle,
} from "react";
import config from "config/config.json";
import MyButton from "components/Controls/MyButton";
import { Form, Formik } from "formik";
import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";
import APIContext from "context/APIContext";
import StripeIcon from "assets/images/stripe.png";
import { LockOutlined } from "@mui/icons-material";
import PerformanceUtils from "../../../helpers/PerformanceUtils";
import AuthContext from "../../../context/AuthContext";
import { Confirm3DSecureRedirect } from "../../../scenes/SubscriptionPage/SubscriptionFlow";
import AnalyticsContext from "../../../context/AnalyticsContext";

const addCreditCard = "addCreditCard";
const confirmSetupIntent = "confirmSetupIntent";
const stripePromise = loadStripe(config.STRIPE_API_KEY);

var elementStyles = {
  base: {
    color: "#ffffff",

    ".MuiFormLabel-root.Mui-focused": {
      color: "#ffffff",
    },

    ".MuiFormLabel-root": {
      color: "#ffffff",
    },
    ":focus": {
      color: "#ffffff",
    },

    ":focus::placeholder": {
      color: "#999999",
    },

    "::placeholder": {
      color: "#999999",
    },
    ":-webkit-autofill": {
      color: "#999999",
    },
  },
  invalid: {
    color: "#f83245",
    "::placeholder": {
      color: "#FFCCA5",
    },
  },
};

const CaptchaWrapper = ({ onSuccess }) => {
  const key = "6LfqqbQqAAAAAB9SUn-ybwBM1fLVN1PGNtkDxqEj";
  return <ReCAPTCHA sitekey={key} onChange={onSuccess} />;
};

const CreditCardForm = ({
  onSuccess,
  whiteForm,
  slim = false,
  showStripeText = false,
  buttonText,
  buttonClassName,
  name,
  onTokenGenerated,
  loading,
  buttonDisabled = false,
  additionalContent,
}) => {
  const { call } = useContext(APIContext);
  const { auth } = useContext(AuthContext);
  const [ThreeDSecureRedirect, setThreeDSecureRedirect] = useState();
  const [captchaOk, setCaptchaOk] = useState(false);

  const { trackTagManager, trackPosthog } = useContext(AnalyticsContext);

  if (!captchaOk)
    return <CaptchaWrapper onSuccess={() => setCaptchaOk(true)} />;

  const email = auth.user.email;

  async function onTokenGeneratedWrapper(token) {
    if (onTokenGenerated) return onTokenGenerated(token);
    let response = await call(
      addCreditCard,
      { data: { value: token } },
      { bypass402: true }
    );
    if (response.ok) {
      trackPosthog("add-credit-card");
      trackTagManager("add-credit-card", {
        user_data: {
          email_address: email,
          sha256_email_address: await PerformanceUtils.sha256(email),
        },
      });
      if (onSuccess) onSuccess(response.body);
    } else {
      if (response.status === 406) {
        setThreeDSecureRedirect(response.body.error_payload);
      }
    }
  }

  async function on3DSComplete(intentId) {
    call(confirmSetupIntent, { data: { value: intentId } }).then((response) => {
      if (response.ok) {
        setThreeDSecureRedirect();
        if (onSuccess) onSuccess(response.body);
      } else {
        if (response.status === 406) {
          setThreeDSecureRedirect(response.body.error_payload);
        }
      }
    });
  }

  return (
    <div className="new-credit-card">
      {ThreeDSecureRedirect && (
        <Confirm3DSecureRedirect
          url={ThreeDSecureRedirect}
          onSetupIntent={(sId) => on3DSComplete(sId)}
        />
      )}
      <Elements stripe={stripePromise}>
        {!slim && (
          <CardForm
            onTokenGenerated={onTokenGeneratedWrapper}
            showStripeText={showStripeText}
            whiteForm={whiteForm}
            buttonClassName={buttonClassName}
            buttonText={buttonText}
            name={name}
            loading={loading}
            buttonDisabled={buttonDisabled}
            additionalContent={additionalContent}
          />
        )}
        {slim && (
          <CardFormSlim
            onTokenGenerated={onTokenGeneratedWrapper}
            showStripeText={showStripeText}
            whiteForm={whiteForm}
            buttonClassName={buttonClassName}
            buttonText={buttonText}
            name={name}
            loading={loading}
            buttonDisabled={buttonDisabled}
            additionalContent={additionalContent}
          />
        )}
      </Elements>
    </div>
  );
};

const StripeText = () => (
  <div className="payment-info">
    <span className="left">
      <LockOutlined className="font-size-xl" /> Encrypted, safe & secure
    </span>
    <span className="right">
      Powered by <img src={StripeIcon} alt="Stripe" height="20p" />
    </span>
  </div>
);

const CardFormSlim = ({
  onTokenGenerated,
  whiteForm,
  showStripeText,
  buttonClassName,
  buttonText = "Add Credit Card",
  name = "",
  loading,
  buttonDisabled,
  additionalContent,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [localLoading, setLocalLoading] = useState(false);

  async function submitForm(values) {
    try {
      if (!stripe || !elements) return;
      setLocalLoading(true);
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      if (!error) {
        await onTokenGenerated(paymentMethod.id);
      }
    } finally {
      setLocalLoading(false);
    }
  }

  let className = "form";
  if (whiteForm) className += " white-controls";

  return (
    <Formik
      initialValues={{ name }}
      onSubmit={submitForm}
      validationSchema={ValidationSchema}
    >
      {(formik) => (
        <Form className={className}>
          <span className="label">Cardholder's Full Name</span>
          <FormikTextField
            name="name"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            placeholder="John Smith"
          />
          <span className="label mt-4">Credit Card Info</span>
          <TextField
            className="mb-3"
            name="cc"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardElement,
                options: {
                  style: whiteForm ? elementStyles : undefined,
                },
              },
            }}
          />

          {showStripeText && <StripeText />}

          {additionalContent}

          <div className="text-align-center">
            <MyButton
              className={buttonClassName}
              id="subscription.credit-card-add"
              disabled={!stripe || buttonDisabled}
              loading={loading || localLoading}
              color="secondary"
            >
              {buttonText}
            </MyButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const CardForm = ({
  onTokenGenerated,
  whiteForm,
  showStripeText,
  buttonClassName,
  buttonText = "Add Credit Card",
  loading,
  buttonDisabled,
  additionalContent,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [localLoading, setLocalLoading] = useState(false);

  async function submitForm(values) {
    try {
      if (!stripe || !elements) return;
      setLocalLoading(true);
      const cardElement = elements.getElement(CardNumberElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      if (!error) {
        await onTokenGenerated(paymentMethod.id);
      }
    } finally {
      setLocalLoading(false);
    }
  }

  let className = "form";
  if (whiteForm) className += " white-controls";

  return (
    <Formik
      initialValues={{ name: "" }}
      onSubmit={submitForm}
      validationSchema={ValidationSchema}
    >
      {(formik) => (
        <Form className={className}>
          <TextField
            className="mb-3"
            label="Credit Card Number"
            name="ccnumber"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardNumberElement,
                options: {
                  style: whiteForm ? elementStyles : undefined,
                },
              },
            }}
          />
          <TextField
            className="mb-3"
            label="Expiration Date"
            name="ccexp"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            style={{ width: "calc(50% - 10px)", marginRight: "20px" }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardExpiryElement,
                options: {
                  style: whiteForm ? elementStyles : undefined,
                },
              },
            }}
          />
          <TextField
            className="mb-3"
            label="CVC"
            name="cvc"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            style={{ width: "calc(50% - 10px)" }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardCvcElement,
                options: {
                  style: whiteForm ? elementStyles : undefined,
                },
              },
            }}
          />
          <FormikTextField
            name="name"
            label="Name"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            placeholder="John Smith"
          />

          {showStripeText && <StripeText />}

          {additionalContent}

          <div className="text-align-center">
            <MyButton
              id="subscription.credit-card-add"
              className={buttonClassName}
              disabled={!stripe}
              loading={loading || localLoading || buttonDisabled}
              color="secondary"
            >
              {buttonText}
            </MyButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const StripeInput = ({ component: Component, inputRef, ...props }) => {
  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));
  return (
    <Component
      onReady={(element) => (elementRef.current = element)}
      {...props}
    />
  );
};

export default CreditCardForm;

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("No name provided"),
});
