import { useBus, useListener } from "react-bus";
import React, { useContext, useEffect, useMemo, useState } from "react";
import ShowIf from "components/common/ShowIf";
import { GeneratingButton } from "components/Controls/MyButton";
import { MenuTopBar } from "pages/GDD3/GDDSideMenu";
import CacheContext from "context/CacheContext";
import APIContext from "context/APIContext";
import {
  AutoAwesomeOutlined,
  FavoriteOutlined,
  ModeEditOutlined,
  SearchOutlined,
  Sync,
  TryOutlined,
} from "@mui/icons-material";
import GDDContext from "context/GDDContext";
import PerformanceUtils from "helpers/PerformanceUtils";
import { Chip, CircularProgress, Grid, TextField } from "@material-ui/core";
import { prepareGeneratedImage } from "pages/ImageGenerator";
import ImageGallery from "components/common/ImageGallery";
import {
  MoodboardFavorites,
  MoodboardGenerator,
} from "pages/GDD3/GDDSideMenu/MoodboardMenu";
import SocketContext from "context/SocketContext";
import Chat from "components/common/Chat";
import { GeneratedImage } from "../../../components/common/GeneratedGameCard";

const DEFAULT_ARRAY = [];

const suggestProjectGeneratedImagesComponent =
  "suggestProjectGeneratedImagesComponent";
const generateImagesForGenerations = "generateImagesForGenerations";
const suggestProjectText = "suggestProjectText";
const suggestProjectTextFollowUp = "suggestProjectTextFollowUp";

var requestId;

export const MENUS = {
  suggestions: {
    id: "suggestions",
    showInput: true,
    buttonLabel: (
      <span className="px-2">
        <AutoAwesomeOutlined className="font-size-lg mr-2" />
        Suggestions
      </span>
    ),
  },
  chat: {
    id: "chat",
    buttonLabel: (
      <span className="px-2">
        <TryOutlined className="font-size-lg mr-2" />
        Ask Ludo
      </span>
    ),
  },
};

export const IMAGE_MENUS = {
  suggestionsGenerated: {
    id: "suggestionsGenerated",
    showInput: true,
    buttonLabel: (
      <span className="px-2">
        <AutoAwesomeOutlined className="font-size-lg mr-2" />
        Suggestions
      </span>
    ),
  },
  editImage: {
    id: "editImage",
    label: "Edit Image",
    buttonLabel: <ModeEditOutlined />,
  },
  favorites: {
    id: "favorites",
    label: "Favorites",
    buttonLabel: <FavoriteOutlined />,
  },
};

export const IMAGE_MENUS2 = {
  editImage: {
    id: "editImage",
    buttonLabel: (
      <span className="px-2">
        <ModeEditOutlined className="font-size-lg mr-2" />
        Edit Image
      </span>
    ),
  },
};

const GameElementsMenu = ({ component, gdd }) => {
  const { menu } = useContext(GDDContext);

  const section = useMemo(() => {
    return gdd.sections.find(
      ({ id, name }) => id === menu.section || name === menu.section
    );
  }, [gdd, menu]);

  return (
    <div className="menu-section">
      <MenuTopBar />
      <div className="menu-section-content">
        <ShowIf condition={menu?.option === IMAGE_MENUS.editImage.id}>
          <MoodboardGenerator
            key="edit-elements"
            section={menu?.section}
            value={section?.value}
            artStyle={gdd.art_style}
            gdd={gdd}
            initialImage={menu?.image || menu?.element?.image}
          />
        </ShowIf>
        <ShowIf condition={menu?.option === IMAGE_MENUS.favorites.id}>
          <MoodboardFavorites section={menu?.section} value={section?.value} />
        </ShowIf>
        <ShowIf
          condition={menu?.option === IMAGE_MENUS.suggestionsGenerated.id}
        >
          <ImageSuggestions section={section} menu={menu} />
        </ShowIf>

        <ShowIf condition={menu?.option === MENUS.chat.id}>
          <Chat
            component={component}
            section={section?.id || component.section}
            element={menu.element?.id}
          />
        </ShowIf>

        <ShowIf condition={menu?.option === MENUS.suggestions.id}>
          <GameElementSuggestions
            component={component}
            gdd={gdd}
            section={section}
            key={component?.section + menu?.input + menu?.clickId}
            input={menu?.input}
          />
        </ShowIf>
      </div>
    </div>
  );
};

var imageGenerationRequestId = undefined;

const ImageSuggestions = ({ section, menu }) => {
  const bus = useBus();
  const { call } = useContext(APIContext);
  const { cache } = useContext(CacheContext);
  const { selectedProjectId } = cache;

  const [isLoading, setIsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState();
  const [currentInput, setCurrentInput] = useState(menu?.input);
  function onHover(value) {
    bus.emit(`${section?.id}.hover`, { image: value });
  }

  useEffect(() => {
    loadSuggestions(menu?.input);

    return () => {
      if (imageGenerationRequestId) {
        call(
          "cancelGeneration",
          { generationId: imageGenerationRequestId },
          { hideErrorMessage: true }
        );
      }
    };
  }, [menu?.input]);

  function onClick(value) {
    bus.emit(`${section?.id}.clickImage`, { image: value });
    let newSuggestions = (suggestions || []).filter(
      ({ url }) => url !== value.url
    );
    setSuggestions(newSuggestions);
  }

  async function loadSuggestions(input = currentInput) {
    setSuggestions([]);
    setIsLoading(true);

    const component =
      menu?.element ||
      (section?.value?.text ? { text: section?.value?.text } : {});
    component.type = component.type || menu?.component?.section;

    let payload = {
      id: selectedProjectId,
      request_id: PerformanceUtils.generateId(),
      includeGdd: true,
      data: {
        hints: input ? [input] : undefined,
        component,
      },
    };
    imageGenerationRequestId = payload.id;

    setTimeout(async () => {
      let response = await call(
        suggestProjectGeneratedImagesComponent,
        payload
      );
      imageGenerationRequestId = undefined;
      if (response.ok) {
        setSuggestions(response.body.map(prepareGeneratedImage));
      }
      setIsLoading(false);
    }, 300);
  }

  function onInputKeyDown(event) {
    if (event.key === "Enter") {
      loadSuggestions(currentInput);
    }
  }

  return (
    <div className="suggestions p-3 d-flex flex-column">
      <ShowIf condition={suggestions?.length > 0}>
        <span className="explanation m-3">
          Add suggested images to the game element
        </span>
      </ShowIf>
      <div style={{ padding: "0 15px" }}>
        <TextField
          className="text-field ludo-color"
          fullWidth
          placeholder="Enter a description (optional)"
          variant="standard"
          value={currentInput}
          margin="normal"
          onChange={(e) => setCurrentInput(e.target.value)}
          onKeyDown={(e) => onInputKeyDown(e)}
        />
      </div>
      <ImageGallery
        minImages={2}
        images={suggestions || DEFAULT_ARRAY}
        onImageClick={true}
        onImageClickFunc={onClick}
        onHover={onHover}
        showGenerate={false}
        contextMenuOverrides={{
          generateSimilar: null,
        }}
      />
      <GeneratingButton
        id="moodboard.load-suggestions"
        className="mx-auto mt-3"
        color="secondary"
        onClick={loadSuggestions}
        loadProgressSecs={5}
        loading={isLoading}
        loadingText="Generating..."
      >
        <Sync className="font-size-lg mr-2 mt-1" />
        Refresh Suggestions
      </GeneratingButton>
    </div>
  );
};

const GameElementSuggestions = ({ component, section, gdd, input }) => {
  const bus = useBus();
  const { track } = useContext(SocketContext);
  const { call, loading } = useContext(APIContext);
  const { cache } = useContext(CacheContext);
  const { menu } = useContext(GDDContext);
  const { selectedProjectId, generationTypes = DEFAULT_ARRAY } = cache;

  const selectedElement = menu?.element;
  const selectedText = component.multiple_allowed
    ? selectedElement?.text?.trim()
    : section?.value?.text?.trim();

  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState();
  const [currentInput, setCurrentInput] = useState(input);

  const hintsValue = currentInput ? [currentInput] : undefined;

  useListener(`${section?.id}.typed`, () => setSuggestions([]));

  useEffect(() => {
    return () => {
      if (imageGenerationRequestId) {
        call(
          "cancelGeneration",
          { generationId: imageGenerationRequestId },
          { hideErrorMessage: true }
        );
      }
    };
  }, []);

  useEffect(() => {
    loadSuggestions(selectedText, input ? [input] : undefined);
  }, [input]);

  useEffect(() => {
    setSuggestions([]);
  }, [menu?.clickId]);

  useEffect(() => {
    if (component.followups && selectedElement?.id) {
      setSuggestions([]);
    }
  }, [selectedElement?.id, component.followups]);

  async function loadSuggestions(text = selectedText, hints = hintsValue) {
    setSuggestions();
    setIsLoading(true);
    let currentRequestId = PerformanceUtils.generateId();
    requestId = currentRequestId;

    let payload = {
      id: selectedProjectId,
      type: component.section,
      includeGdd: true,
      data: { element_id: selectedElement?.id || section?.id, hints },
    };

    const isFollowUp = component.followups && (!!text || selectedElement?.image?.url)

    let newSuggestions;
    setTimeout(async () => {
      if (isFollowUp) {
        let response = await call(suggestProjectTextFollowUp, payload);
        if (response.ok && requestId === currentRequestId) {
          newSuggestions = response.body.map((element, index) => {
            return {
              id: PerformanceUtils.generateId(),
              index,
              text: element.text,
              element,
              isNew: false,
            };
          });
        }
      } else {
        let response = await call(suggestProjectText, payload);
        if (response.ok && requestId === currentRequestId) {
          newSuggestions = response.body.map((element, index) => {
            return {
              id: PerformanceUtils.generateId(),
              index,
              text: element.text,
              element,
              isNew: true,
            };
          });
        }
      }

      if (newSuggestions) {
        setSuggestions(newSuggestions);
        if (newSuggestions[0]?.isNew) {
          generateImages(newSuggestions, true);
        }
      }
      setIsLoading(false);
    }, 300);
  }

  async function generateImages(suggestions = [], noCredits = false) {
    const type = suggestions[0]?.type || component.section;

    if (!generationTypes.includes(type)) return;

    let data = {
      request_id: PerformanceUtils.generateId(
        noCredits ? "00000000" : undefined
      ),
      components: suggestions.map((suggestion) => {
        return {
          ...suggestion.element,
          type,
        };
      }),
      art_style: gdd.art_style,
      context_gdd: gdd,
      filters: {},
    };
    if (gdd.platform) data.filters.platforms = [gdd.platform];
    imageGenerationRequestId = data.request_id;
    let response = await call(generateImagesForGenerations, { data });
    imageGenerationRequestId = undefined;
    if (response.ok) {
      let changedSuggestions = suggestions.map((suggestion, index) => {
        let image = prepareGeneratedImage(response.body[index]);
        if (image) suggestion.image = image.originalImage;
        return suggestion;
      });

      setSuggestions((prevState) => {
        return (prevState || []).map((suggestion) => {
          return (
            changedSuggestions.find(({ id }) => id === suggestion.id) ||
            suggestion
          );
        });
      });
    }
  }
  function onHover(value) {
    bus.emit(`${section?.id}.hover`, value ? { ...value } : value);
  }

  function onClick(value) {
    let isNew = component.followups ? value?.isNew : true;
    let currentSuggestions = [...suggestions];
    bus.emit(`${section?.id}.click`, { ...value });
    bus.emit(`${section?.id}.hover`, { text: undefined, isNew });
    if (isNew && component.multiple_allowed) {
      let updatedElements = currentSuggestions.filter(
        ({ text }) => text !== value.text
      );
      setSuggestions(updatedElements);
    } else {
      setSuggestions([]);
    }
  }

  function onClickedButton() {
    loadSuggestions(undefined, hintsValue);
  }
  async function onCopy(text) {
    track("gdd.elements.copy-text", { id: component.section, text });
    await navigator.clipboard.writeText(text);
  }

  const addLabel = "Add to Concept";
  const label = component.label || component.section;
  const explanation = !!selectedText
    ? `Expand existing ${label}`
    : `Add new ${label}`;

  function onInputKeyDown(event) {
    if (event.key === "Enter") {
      loadSuggestions(undefined, hintsValue);
    }
  }

  return (
    <>
      <div className="suggestions d-flex flex-column">
        <ShowIf condition={suggestions?.length > 0}>
          {explanation ? (
            <span className="explanation">{explanation}</span>
          ) : null}
        </ShowIf>

        <div style={{ padding: "0 15px" }}>
          <TextField
            className="text-field ludo-color"
            fullWidth
            placeholder="Enter a description (optional)"
            variant="standard"
            value={currentInput}
            margin="normal"
            onChange={(e) => setCurrentInput(e.target.value)}
            onKeyDown={(e) => onInputKeyDown(e)}
          />
        </div>

        <div className="d-flex flex-column suggestions-wrapper">
          <div onMouseLeave={() => onHover(undefined)}>
            {suggestions?.map((suggestion) => {

                const textToShow = selectedText ? suggestion.text.replace(selectedText, '...') : suggestion.text;

                return (
                  <div
                    className="suggestion"
                    onMouseEnter={() => onHover(suggestion)}
                    key={suggestion.text}
                  >
                    <span className="option-index">
                      Option {suggestion.index + 1}
                    </span>
                    {suggestion.image ? (
                      <GeneratedImage
                        image={suggestion.image}
                        background={true}
                      />
                    ) : loading[generateImagesForGenerations] ? (
                      <div className="spinner">
                        <CircularProgress className="text-white" />
                      </div>
                    ) : null}
                    <span>{(textToShow || "").trim()}</span>
                    <div className="actions">
                      <Chip
                        onClick={() => onCopy(suggestion.text)}
                        className="copy-chip font-weight-bold w-fit"
                        label="Copy"
                      />
                      <div className="add">
                        <Chip
                          onClick={() => onClick(suggestion)}
                          className="text-white font-weight-bold w-fit"
                          label={addLabel}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <GeneratingButton
          key={isLoading + requestId}
          id="elements.load-suggestions"
          className="load-more-button"
          onClick={onClickedButton}
          loadProgressSecs={10}
          loading={isLoading}
        >
          <Sync className="font-size-lg mr-2 mt-1" />
          {suggestions?.length > 0 ? "Refresh" : "Load"} Suggestions
        </GeneratingButton>
      </div>
    </>
  );
};

export default GameElementsMenu;
