import {useContext, useState, useEffect} from 'react';
import DetailsPanelContext from "context/DetailsPanelContext";
import SocketContext from "context/SocketContext";
import CacheContext from "context/CacheContext";

export const GDD_TAB_NAMES = {
  edit: "Edit",
  review: "Review",
  analysis: "Ludo Score",
  unityCode: "Code (Unity)",
  developmentGuide: "Tutorial",
}

const GDD_TABS = [
  GDD_TAB_NAMES.edit,
  GDD_TAB_NAMES.review,
  GDD_TAB_NAMES.analysis,
  GDD_TAB_NAMES.unityCode,
  GDD_TAB_NAMES.developmentGuide,
];

const GDD_TABS_LOCKED = [
  GDD_TAB_NAMES.review
]

function useGDDState() {

  const [menu, setMenu] = useState(false);
  const [suggestions, setSuggestions] = useState([])
  const [tab, setTab] = useState(GDD_TABS[0])
  const [locked, setLocked] = useState(false);

  const TABS = locked ? GDD_TABS_LOCKED : GDD_TABS;

  const {cache} = useContext(CacheContext);
  const {track} = useContext(SocketContext);
  const {hidePanel} = useContext(DetailsPanelContext);

  const {detailsPanel} = cache;

  useEffect(() => {
    if (tab === GDD_TAB_NAMES.review) setMenu(false);
  }, [tab]);

  useEffect(() => {
    reset();
  },[locked]);

  function reset() {
    setMenu(false);
    setTab(TABS[0]);
  }

  function closeMenu() {
    track('gdd.menu.close');
    setMenu(false);
  }

  function openMenu(data) {
    track('gdd.menu.open', data);
    if (tab !== GDD_TAB_NAMES.review || data?.component?.section === "concepts") {
      setMenu(data);
      if (detailsPanel.mode) hidePanel();
    }
  }

  return {menu, openMenu, closeMenu, reset, suggestions, setSuggestions, tabs: TABS, tab, setTab, locked, setLocked}
}

export default useGDDState;
