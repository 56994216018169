import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import {Sidebar, Header, Footer} from '../../layout-components';
import GameDetailsPanel from "components/common/GameDetailsPanel";
import {BillingModal, ProfileButton} from "pages/Profile/Profile";
import ShowIf from "components/common/ShowIf";
import CacheContext from "context/CacheContext";
import ImageDetailsPanel from "components/common/ImageDetailsPanel";
import TrendTopicDetailsPanel from "components/common/TrendTopicDetailsPanel";
import {DETAILS_PANEL_TYPES} from "useDetailsPanel";
import DetailsPanelContext from "context/DetailsPanelContext";
import MyIconButton from "components/common/MyIconButton";
import DeveloperDetailsPanel from "components/common/DeveloperDetailsPanel";
import ScrollToTop from "components/utils/ScrollToTop";
import {LockModal} from "components/Controls/MyButton";
import moment from 'moment';
import {NavLink, useLocation} from "react-router-dom";
import AuthContext from "context/AuthContext";
import ScrollContext from 'context/ScrollContext';
import SocketContext from "context/SocketContext";
import GameGenerator from "pages/GameGenerator";
import {Provider} from 'react-bus'
import logoSmall from "assets/images/logo-small.png";
import {IconButton} from "@material-ui/core";
import AchievementsBar, {AchievementToasts} from "pages/Achievements/AchievementsBar";
import creditsIcon from 'assets/images/icons/credit-icon.png';
import useGDDState from "hooks/useGDDState";
import GDDContext from "context/GDDContext";
import {ParallaxProvider} from 'react-scroll-parallax';
import CompetitiveAnalysisDetailsPanel from "components/common/CompetitiveAnalysisDetailsPanel";
import {
  ArrowBackIosNewOutlined,
  ArrowForwardIosOutlined,
  CloseOutlined,
  Menu
} from "@mui/icons-material";
import Chat from "components/common/Chat";
import APIContext from "context/APIContext";
import GameTopicsDetailsPanel from "components/common/GameTopicsDetailsPanel";
import {isFreePlan} from "../../../scenes/SubscriptionPage/Plans";
import AnalyticsContext from "../../../context/AnalyticsContext";

const updateUserInfo = 'updateUserInfo';

const MOBILE_WARNING_TIMEOUT = 3 * 60 * 1000;

const LeftSidebar = (props) => {
  const {
    children,
    sidebarToggle,
    sidebarToggleMobile,
    headerFixed,
    headerSearchHover,
    headerDrawerToggle,
    footerFixed,
    contentBackground
  } = props;

  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation();
  const {setAuth} = useContext(AuthContext);
  const {cache, setCache} = useContext(CacheContext);
  const {call} = useContext(APIContext);
  const {hidePanel} = useContext(DetailsPanelContext);
  const {auth} = useContext(AuthContext);
  const {selectedProjectId, lockScreen, detailsPanel} = cache;
  const scrollRef = useRef();
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [mobileWarning, setMobileWarning] = useState(false);

  const gddState = useGDDState();

  const page = useMemo(() => {
    return (location?.pathname || "").split('/').join(' ').trim();
  }, [location?.pathname]);

  function closeLockScreen() {
    setCache(prevState => {
      return {
        ...prevState,
        lockScreen: undefined,
      }
    })
  }

  let allowExpand = false;
  let showUpgradeBar = false;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    setTimeout(() => setMobileWarning(true), MOBILE_WARNING_TIMEOUT);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  function onToggleMenu() {
    setOpenMobileMenu(!openMobileMenu);
    if (isMobile)
      hidePanel();
  }

  async function onCloseWelcomeForm() {
    let response = await call(updateUserInfo, {data: {welcome_form: true}});
    if (response.ok) {
      setAuth({user: {...response.body}}, true, false);
    }
  }

  useEffect(() => {
    setOpenMobileMenu(false);
    if (isMobile)
      hidePanel();
  }, [page, isMobile]);

  const isGDDMenuOpen = !!gddState.menu || !!detailsPanel.mode;
  const showWelcomeForm = auth.user.welcome_form;

  return (
    <GDDContext.Provider value={{...gddState}}>
      <div className={clsx('full-wrapper', true, {
        'upgrade-bar-on': showUpgradeBar,
      })}>

        <TopMobileBar onToggleMenu={onToggleMenu}/>

        <ShowIf condition={showUpgradeBar}>
          <UpgradeBar/>
        </ShowIf>

        <div
          className={clsx('app-wrapper', contentBackground, {
            'header-drawer-open': headerDrawerToggle,
            'app-sidebar-collapsed': sidebarToggle,
            'app-sidebar-mobile-open': sidebarToggleMobile,
            'app-sidebar-fixed': true,
            'allow-expand': allowExpand,
            'app-header-fixed': headerFixed,
            'app-footer-fixed': footerFixed,
            'search-wrapper-open': headerSearchHover,
            'open-mobile-menu': openMobileMenu,
            //'upgrade-bar-on': showUpgradeBar,
          })}>
          <Sidebar onToggleMenu={onToggleMenu}/>
          {!!lockScreen ? <LockModal
            key={lockScreen}
            open={!!lockScreen}
            onClose={() => closeLockScreen()}
          /> : null}
          <ShowIf condition={!!selectedProjectId}>
            <div className="app-main">

              {/*!showWelcomeForm && <Dialog
                scroll="body"
                maxWidth="lg"
                open={!showWelcomeForm}
                onClose={() => {
                }}
                className="full-screen-modal welcome-form-modal"
              >
                <HubspotWelcomeForm onClose={onCloseWelcomeForm}/>
              </Dialog>*/}

              <AchievementToasts/>
              <ShowIf condition={mobileWarning}>
                <MobileWarning onClose={() => setMobileWarning(false)}/>
              </ShowIf>
              <div className="app-content">
                <div className={"app-content--inner " + page + (isGDDMenuOpen ? " gdd-menu-open" : "")}>
                  <Provider>
                    <div className="app-content--inner__wrapper" id="app-content--inner__wrapper" ref={scrollRef}>
                      <ParallaxProvider scrollContainer={scrollRef.current}>
                        <div className="counters-bar">
                          <AchievementsBar/>
                          <CreditsCounter/>
                        </div>
                        <ProfileButton/>
                        <Header/>
                        <hr className="top-hr hr-blue position-absolute z-over"/>
                        <div className="page-content">
                          <ScrollToTop scrollRef={scrollRef}>
                            <ScrollContext.Provider value={{scrollRef}}>
                              {children}
                            </ScrollContext.Provider>
                          </ScrollToTop>
                        </div>
                        <Footer/>
                      </ParallaxProvider>
                    </div>
                    <SidePanel/>
                  </Provider>
                </div>
              </div>
            </div>
          </ShowIf>
        </div>
      </div>
    </GDDContext.Provider>
  );
};

const MobileWarning = ({onClose}) => {
  return (
    <div className="mobile-warning only-mobile animate__animated animate__fadeIn">
      <span className="top-right">
          <IconButton onClick={onClose}>
          <CloseOutlined
            className="font-size-xxl pointer text-white"
          />
          </IconButton>
        </span>
      <span>
        Don't forget to visit Ludo on your desktop or laptop to get the full experience!
      </span>
    </div>
  )
}

const TopMobileBar = ({onToggleMenu}) => {
  return (
    <div className="mobile-bar">
      <div className="logo-wrapper">
        <NavLink
          to="/home"
          title="Ludo"
          className="app-sidebar-logo minimized">
          <div className="app-sidebar-logo--icon2 d-flex">
            <img
              alt="Ludo"
              height="50px"
              src={logoSmall}
            />
          </div>
        </NavLink>
      </div>
      <div className="counters-bar">
        <AchievementsBar/>
        <CreditsCounter/>
        <ProfileButton/>
      </div>
      <span className="hamburger-wrapper" onClick={onToggleMenu}>
        <Menu/>
      </span>
    </div>
  )
}

const UpgradeBar = () => {

  const {track} = useContext(SocketContext);
  const {cache} = useContext(CacheContext);
  const {auth} = useContext(AuthContext);
  const {trialExpired} = cache;
  const {subscription} = auth;

  const [openBilling, setOpenBilling] = useState(false);

  let {trial_end} = subscription;
  let days = moment.unix(trial_end).diff(moment(), 'days');
  let expire = days > 1 ? `in ${days} days` : days === 1 ? 'in 1 day' : 'today';

  function onClickUpgrade() {
    track('clicked-upgrade-bar');
    setOpenBilling(true);
  }

  const percentOff = useMemo(() => {
    let plan = (cache.plans || []).find(plan => !!plan.percent_off);
    return plan?.percent_off;
  }, [cache.plans]);

  const buttonMessage = percentOff ? `UPGRADE NOW WITH ${percentOff}% DISCOUNT!` : "UPGRADE NOW!"

  return (
    <>
      <BillingModal
        open={openBilling}
        onClose={() => setOpenBilling(false)}
      />
      <div className="upgrade-bar" onClick={onClickUpgrade}>
        <ShowIf condition={trialExpired}>
          <span>Get the best of Ludo with our subscriptions</span>
        </ShowIf>
        <ShowIf condition={!trialExpired}>
          <span>We hope you're enjoying Ludo so far. Your Ludo Indie trial ends {expire}</span>
        </ShowIf>
        <span className="upgrade-button">
        {buttonMessage}
      </span>
      </div>
    </>
  )
}

let timeout;

const CreditsCounter = () => {

  const [openBilling, setOpenBilling] = useState(false);
  const [animatedAmount, setAnimatedAmount] = useState(undefined);
  const [oldAmount, setOldAMount] = useState(undefined);

  const {auth} = useContext(AuthContext);
  const {cache} = useContext(CacheContext);
  const {credits, trialExpired} = cache;
  const {current, max, reset_date, extra = 0} = credits;

  const isFree = isFreePlan(auth.subscription.plan, auth.subscription);
  const isPaywall = auth.user.paywall;
  const showFreeText = isFree && !isPaywall;

  function convertFloatToIntOrFixed(num, forceInt) {
    var fixedNum = parseFloat(num).toFixed(2);
    if (forceInt) {
      return parseInt(fixedNum, 10); // return as int
    }
    return parseFloat(fixedNum);
  }

  const displayCredits = convertFloatToIntOrFixed((max + extra) - Math.floor(current || 0), true);

  useEffect(() => {
    if (oldAmount !== undefined) {
      let diff = convertFloatToIntOrFixed(current - oldAmount);
      if (diff && diff < 200) {
        clearTimeout(timeout);
        setAnimatedAmount(undefined);
        setTimeout(() => {
          setAnimatedAmount(diff);
          timeout = setTimeout(() => {
            setAnimatedAmount(undefined);
          }, 5000);
        }, 100)
      }
    }
    setOldAMount(current);
  }, [current, oldAmount])

  if (!max) return null;

  let nextReset = moment.unix(reset_date).format('MMM DD, YYYY');

  return (
    <>
      <div className="credits-counter counter animate__animated animate__fadeIn animate__slow"
           onClick={() => setOpenBilling(true)}>
        <Tooltip
          arrow
          placement="bottom"
          title={
            !trialExpired && !showFreeText ?
              <span>Credits renew: {nextReset}<br/><br/>These are your generation credits. Use them by generating game ideas, mechanics, images, and more. They will reset every 30 days.</span> :
              <span>These are your generation credits. Use them by generating game ideas, mechanics, images, and more. Click here to see our available subscriptions!</span>
          }
          PopperProps={{className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
        >
          <div className="progress-wrapper">
            <div className="bar">

              <span>
                {displayCredits}
              </span>
            </div>
            <div className="info-icon">
              {animatedAmount &&
                <span className="animated-amount animate__animated">{animatedAmount}</span>}
              <img src={creditsIcon}/>
            </div>
          </div>
        </Tooltip>
      </div>
      <BillingModal
        open={openBilling}
        onClose={() => setOpenBilling(false)}
      />
    </>
  );
}

const DEFAULT_OBJECT = {};

export const SidePanel = () => {

  const location = useLocation();
  const {cache, setCacheValue} = useContext(CacheContext);
  const {auth} = useContext(AuthContext);
  const { consent, trackPosthog } = useContext(AnalyticsContext);
  let {detailsPanel} = cache;
  const ref = useRef();

  const subscriptionActive = auth?.subscription?.status === 'active' && !isFreePlan(auth?.subscription?.plan, auth?.subscription);

  useEffect(() => {
    if (subscriptionActive && consent) {
      trackPosthog('active-subscription');
    }
  },[subscriptionActive, consent]);

  useEffect(() => {
    const LOCATIONS = ['/', '/top-charts'];
    if (detailsPanel.mode === DETAILS_PANEL_TYPES.ideator && !LOCATIONS.includes(location.pathname)) {
      setCacheValue('detailsPanel', {...detailsPanel, mode: undefined});
    }
  }, [location.pathname, detailsPanel]);

  if (!detailsPanel) detailsPanel = DEFAULT_OBJECT;

  let style = {};
  if (!detailsPanel.mode)
    style = {
      width: "0",
    };

  function handleTransitionEnd(event) {
    if (ref.current === event.target) {
      window.dispatchEvent(new CustomEvent('resize'));
    }
  }

  return (
    <div className={"details-panel " + detailsPanel.mode || ""} style={style} ref={ref}
         onTransitionEnd={handleTransitionEnd}>
      <ShowIf condition={!!detailsPanel.mode}>
        <DetailsPanelHistory/>
        <ShowIf condition={detailsPanel.mode === DETAILS_PANEL_TYPES.ideator}>
          <span className="large-title-top">Game Ideator</span>
        </ShowIf>
        <ShowIf condition={detailsPanel.mode === DETAILS_PANEL_TYPES.game_topics && !!detailsPanel.topics}>
          <GameTopicsDetailsPanel
            key={(detailsPanel.topic || {}).topic_id}
            containerRef={ref}
          />
        </ShowIf>
        <ShowIf condition={detailsPanel.mode === DETAILS_PANEL_TYPES.trend_topic && !!detailsPanel.topic}>
          <TrendTopicDetailsPanel
            key={(detailsPanel.topic || {}).topic_id}
            containerRef={ref}
          />
        </ShowIf>
        <ShowIf
          condition={detailsPanel.mode === DETAILS_PANEL_TYPES.competitive_analysis && (!!detailsPanel.cluster || !!detailsPanel.metric || !!detailsPanel.game_ids)}>
          <CompetitiveAnalysisDetailsPanel
            key={detailsPanel?.id}
            containerRef={ref}
          />
        </ShowIf>
        <ShowIf condition={detailsPanel.mode === DETAILS_PANEL_TYPES.game && !!detailsPanel.game}>
          <GameDetailsPanel
            key={detailsPanel.game?._id}
            containerRef={ref}
          />
        </ShowIf>
        <ShowIf condition={detailsPanel.mode === DETAILS_PANEL_TYPES.image && !!detailsPanel.image}>
          <ImageDetailsPanel
            key={detailsPanel.image?.url}
            containerRef={ref}
          />
        </ShowIf>
        <ShowIf condition={detailsPanel.mode === DETAILS_PANEL_TYPES.ideator}>
          <GameGenerator
            fullVersion={false}
            overrideInitial={{genres: []}}
          />
        </ShowIf>
        <ShowIf condition={detailsPanel.mode === DETAILS_PANEL_TYPES.chat}>
          <Chat/>
        </ShowIf>
        <ShowIf
          condition={detailsPanel.mode === DETAILS_PANEL_TYPES.developer && !!detailsPanel.developer_name && !!detailsPanel.developer_store}>
          <DeveloperDetailsPanel
            key={detailsPanel.developer_name + detailsPanel.developer_store}
            containerRef={ref}
          />
        </ShowIf>
      </ShowIf>
    </div>
  )
}

const DetailsPanelHistory = () => {

  const {hidePanel, hasNext, hasPrevious, navigatePrevious, navigateNext} = useContext(DetailsPanelContext);

  return (
    <div className="details-panel-history">
      <span className="navigate">
        <MyIconButton
          onClick={navigatePrevious}
          disabled={!hasPrevious}
          title="Previous"
          iconElement={<ArrowBackIosNewOutlined className="font-size-xxl text-secondary"/>}
        />
        <MyIconButton
          onClick={navigateNext}
          disabled={!hasNext}
          title="Next"
          iconElement={<ArrowForwardIosOutlined className="font-size-xxl text-secondary"/>}
        />
      </span>
      <span className="close" onClick={hidePanel}>
        <MyIconButton
          title="Close"
          iconElement={<CloseOutlined className="font-size-xxxxxl text-secondary"/>}
        />
        </span>
    </div>
  )

}

LeftSidebar.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,
  footerFixed: state.ThemeOptions.footerFixed,
  contentBackground: state.ThemeOptions.contentBackground
});

export default connect(mapStateToProps)(LeftSidebar);
