import { useBus, useListener } from "react-bus";
import React, { useContext, useEffect, useMemo, useState } from "react";
import ShowIf from "components/common/ShowIf";
import { GeneratingButton } from "components/Controls/MyButton";
import APIContext from "context/APIContext";
import { MenuTopBar } from "pages/GDD3/GDDSideMenu";
import CacheContext from "context/CacheContext";
import { FAVORITE_TYPES, filterFavorites } from "pages/Favorites";
import GeneratedGameCard from "components/common/GeneratedGameCard";
import GameGenerator from "pages/GameGenerator";
import { AutoAwesomeOutlined, TryOutlined, Sync } from "@mui/icons-material";
import GDDContext from "context/GDDContext";
import { Chip, TextField } from "@material-ui/core";
import Chat from "components/common/Chat";

const DEFAULT_ARRAY = [];

export const MENUS = {
  ideator: {
    id: "ideator",
    label: "New Game Ideas",
  },
  favorites: {
    id: "favorites",
    label: "Favorite",
  },
  summaryChat: {
    id: "summaryChat",
    label: "Ask Ludo",
    buttonLabel: <TryOutlined />,
  },
};

const NON_LISTED_MENUS = {
  chat: {
    id: "chat",
    label: "Ask Ludo",
    buttonLabel: <TryOutlined />,
  },
};

export const DESCRIPTION_MENUS = {
  suggestions: {
    showInput: true,
    id: "descriptionSuggestions",
    buttonLabel: (
      <span className="px-2">
        <AutoAwesomeOutlined className="font-size-lg mr-2" />
        Suggestions
      </span>
    ),
  },
  chat: {
    id: "descriptionChat",
    buttonLabel: (
      <span className="px-2">
        <TryOutlined className="font-size-lg mr-2" />
        Ask Ludo
      </span>
    ),
  },
};

export const TITLE_MENUS = {
  suggestions: {
    id: "titleSuggestions",
    buttonLabel: (
      <span className="px-2">
        <AutoAwesomeOutlined className="font-size-lg mr-2" />
        Suggestions
      </span>
    ),
  },
  chat: {
    id: "titleChat",
    buttonLabel: (
      <span className="px-2">
        <TryOutlined className="font-size-lg mr-2" />
        Ask Ludo
      </span>
    ),
  },
};

const suggestProjectTitles = "suggestProjectTitles";
const GameSummaryMenu = ({ component, gdd }) => {
  const { menu } = useContext(GDDContext);

  function onChosenSeed() {
    //TODO
    /*
    () => setExpanded(OPTIONS_1.descriptionSuggestions)
     */
  }

  const section = useMemo(() => {
    return gdd.sections.find(
      ({ id, name }) => id === menu.section || name === menu.section
    );
  }, [gdd, menu]);

  return (
    <div className="menu-section">
      <MenuTopBar />
      <div className="menu-section-content">
        <ShowIf condition={menu?.option === TITLE_MENUS.suggestions.id}>
          <TitleSuggestions
            key={menu?.clickId}
            component={component}
            input={menu?.input}
          />
        </ShowIf>
        <ShowIf condition={menu?.option === DESCRIPTION_MENUS.suggestions.id}>
          <GameSummarySuggestions
            key={menu?.clickId}
            component={component}
            gdd={gdd}
            input={menu?.input}
          />
        </ShowIf>
        <ShowIf condition={menu?.option === MENUS.ideator.id}>
          <EmbeddedIdeator
            component={component}
            gdd={gdd}
            onChosenSeed={onChosenSeed}
          />
        </ShowIf>
        <ShowIf condition={menu?.option === MENUS.favorites.id}>
          <GameSummaryFavorites component={component} />
        </ShowIf>

        <ShowIf condition={menu?.option === TITLE_MENUS.chat.id}>
          <Chat component={component} section="title" element={undefined} />
        </ShowIf>

        <ShowIf condition={menu?.option === DESCRIPTION_MENUS.chat.id}>
          <Chat
            component={component}
            section={section?.id || component.section}
            element={undefined}
          />
        </ShowIf>

        <ShowIf condition={menu?.option === NON_LISTED_MENUS.chat.id}>
          <Chat
            component={component}
            section={section?.id || component.section}
            element={undefined}
          />
        </ShowIf>
      </div>
    </div>
  );
};

const EmbeddedIdeator = ({ component, onChosenSeed }) => {
  const bus = useBus();

  function onClickSeed(game) {
    let data = {
      title: game.title,
      text: game.description,
      genres: game.genres,
      isNew: true,
    };
    bus.emit(`${component.section}.replace`, data);
    setTimeout(() => {
      onChosenSeed();
    }, 500);
  }

  function onHoverSeed(game) {
    bus.emit(
      `${component.section}.hover`,
      game
        ? { text: game.description, title: game.title, isNew: true }
        : undefined
    );
  }

  return (
    <div className="game-ideator pb-4">
      <GameGenerator
        fullVersion={false}
        onClickSeed={onClickSeed}
        onHoverSeed={onHoverSeed}
      />
    </div>
  );
};

export const GameSummaryFavorites = ({ component }) => {
  const bus = useBus();

  const { cache } = useContext(CacheContext);
  const { allFavorites = DEFAULT_ARRAY } = cache;

  const generatedGames = useMemo(
    () => filterFavorites(allFavorites, FAVORITE_TYPES.generated_game),
    [allFavorites]
  );

  function onHover(game) {
    bus.emit(
      `${component.section}.hover`,
      game
        ? {
            text: `${game.description}`,
            title: game.title,
            isNew: true,
          }
        : undefined
    );
  }

  function onClick(game) {
    if (game) {
      bus.emit(`${component.section}.hover`, undefined);
      bus.emit(`${component.section}.replace`, {
        text: `${game.description}`,
        title: game.title,
        isNew: true,
      });
    }
  }

  return (
    <div className="favorites-list px-5 py-3">
      <ShowIf condition={generatedGames.length === 0}>
        <span className="explanation">
          No {component.section} favorites found
        </span>
      </ShowIf>
      <ShowIf condition={generatedGames.length > 0}>
        <span className="explanation">
          Replace the current game summary and title
        </span>
      </ShowIf>
      <div onMouseLeave={() => onHover()}>
        {generatedGames.map((game) => (
          <div
            key={game._id}
            onClick={() => onClick(game)}
            onMouseEnter={() => onHover(game)}
          >
            <GeneratedGameCard
              key={game.id}
              game={game}
              onClick={onClick}
              smallVersion={true}
              showDevelopButton={false}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const TitleSuggestions = ({ component }) => {
  const bus = useBus();
  const { call } = useContext(APIContext);
  const { cache } = useContext(CacheContext);
  const { selectedProjectId } = cache;

  const [isLoading, setIsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState();

  useEffect(() => {
    loadSuggestions();
  }, []);

  useListener(`${component.section}.typed`, () => setSuggestions());
  async function loadSuggestions() {
    setSuggestions([]);
    setIsLoading(true);

    let payload = {
      id: selectedProjectId,
      type: component.section,
      includeGdd: true,
    };

    setTimeout(async () => {
      let response = await call(suggestProjectTitles, payload);
      if (response.ok)
        setSuggestions(
          response.body.map(({ text }, index) => {
            return { text, index };
          })
        );
      setIsLoading(false);
    }, 300);
  }

  function onHover(value) {
    bus.emit(`${component.section}.hover`, { title: value?.text });
  }
  function onClick(value) {
    bus.emit(`${component.section}.hover`, undefined);
    bus.emit(`${component.section}.click`, { title: value?.text });
  }

  async function onCopy(text) {
    await navigator.clipboard.writeText(text);
  }

  const addLabel = "Add Text";
  const explanation =
    suggestions?.length > 0 ? "Replace the title with a new one" : null;

  return (
    <div className="d-flex flex-column suggestions">
      {explanation ? <span className="explanation">{explanation}</span> : null}
      <div
        className="suggestions-wrapper"
        onMouseLeave={() => onHover(undefined)}
      >
        {suggestions?.map((suggestion) => {
          return (
            <div
              key={suggestion.id}
              className="suggestion"
              onMouseEnter={() => onHover(suggestion)}
            >
              <span className="option-index">
                Option {suggestion.index + 1}
              </span>
              <span>{suggestion.text}</span>
              <div className="actions">
                <Chip
                  onClick={() => onCopy(suggestion.text)}
                  className="copy-chip font-weight-bold w-fit"
                  label="Copy"
                />
                <div className="add">
                  <Chip
                    onClick={() => onClick(suggestion)}
                    className="text-white font-weight-bold w-fit"
                    label={addLabel}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <GeneratingButton
        key={isLoading}
        id="title.load-suggestions"
        className="load-more-button"
        color="secondary"
        onClick={loadSuggestions}
        loadProgressSecs={5}
        loading={isLoading}
      >
        <Sync className="font-size-lg mr-2 mt-1" />
        Refresh Suggestions
      </GeneratingButton>
    </div>
  );
};

const GameSummarySuggestions = ({ component, gdd, input }) => {
  const bus = useBus();
  const { call } = useContext(APIContext);
  const { cache } = useContext(CacheContext);
  const { gddComponents } = cache;

  const { selectedProjectId } = cache;

  const [isLoading, setIsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState();
  const [currentInput, setCurrentInput] = useState(input);

  useEffect(() => {
    loadSuggestions(undefined, input);
  }, [input]);

  useListener(`${component.section}.typed`, () => setSuggestions());

  const currentText = useMemo(() => {
    return gdd?.sections?.find(({ name }) => name === gddComponents?.summary?.section)?.value?.text;
  }, [gdd]);

  async function loadSuggestions(text = currentText, input = currentInput) {
    setSuggestions([]);
    setIsLoading(true);

    let payload = {
      id: selectedProjectId,
      type: component.section,
      data: {
        hints: input ? [input] : [],
      },
    };

    setTimeout(async () => {
      let endpoint = !!text.trim()
        ? "suggestProjectDescriptionsFollowUp"
        : "suggestProjectDescriptions";
      let response = await call(endpoint, payload);
      if (response.ok)
        setSuggestions(
          response.body.map(({ text, description }, index) => {
            return {
              text: text || description,
              index,
            };
          })
        );
      setIsLoading(false);
    }, 300);
  }

  function onHover(suggestion) {
    bus.emit(`${component.section}.hover`, {
      text: suggestion?.text,
      isNew: true,
    });
  }

  function onClick(suggestion) {
    bus.emit(`${component.section}.hover`, undefined);
    bus.emit(`${component.section}.click`, { text: suggestion.text });
    setSuggestions([]);
  }

  async function onCopy(text) {
    await navigator.clipboard.writeText(text);
  }

  const explanation =
    suggestions?.length > 0
      ? "Expand description with Ludo's suggestions"
      : null;

  function onInputKeyDown(event) {
    if (event.key === "Enter") {
      loadSuggestions();
    }
  }

  return (
    <div className="d-flex flex-column suggestions">
      {explanation ? <span className="explanation">{explanation}</span> : null}
      <div style={{ padding: "0 15px" }}>
        <TextField
          className="text-field ludo-color"
          fullWidth
          placeholder="Enter a description (optional)"
          variant="standard"
          value={currentInput}
          margin="normal"
          onChange={(e) => setCurrentInput(e.target.value)}
          onKeyDown={(e) => onInputKeyDown(e)}
        />
      </div>
      <div
        className="suggestions-wrapper"
        onMouseLeave={() => onHover(undefined)}
      >
        {suggestions?.map((suggestion) => {
          const textToShow = currentText ? suggestion.text.replace(currentText, '...') : suggestion.text;
          return (
            <div
              className="suggestion"
              onMouseEnter={() => onHover(suggestion)}
            >
              <span className="option-index">
                Option {suggestion.index + 1}
              </span>
              <span>{textToShow}</span>
              <div className="actions">
                <Chip
                  onClick={() => onCopy(suggestion.text)}
                  className="copy-chip font-weight-bold w-fit"
                  label="Copy"
                />
                <div className="add">
                  <Chip
                    onClick={() => onClick(suggestion)}
                    className="text-white font-weight-bold w-fit"
                    label="Add Text"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <GeneratingButton
        key={isLoading}
        id="summary.load-suggestions"
        className="mx-auto mt-3"
        color="secondary"
        onClick={() => loadSuggestions()}
        loadProgressSecs={10}
        loading={isLoading}
      >
        <Sync className="font-size-lg mr-2 mt-1" />
        Refresh Suggestions
      </GeneratingButton>
    </div>
  );
};

export default GameSummaryMenu;
