import React, {
  useContext,
  useRef,
  useState,
} from "react";
import {
  ArrowRightOutlined,
  CloseOutlined,
  DeleteOutline,
  EditOutlined,
  ExpandLess,
  ExpandMore,
  Info,
  MoreVert,
  PeopleAltOutlined,
  PersonOutline,
  TryOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import GDDContext from "context/GDDContext";
import {
  ClickAwayListener,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
  Tooltip,
  Menu,
  TextField,
} from "@material-ui/core";
import { capitalize } from "@mui/material";
import {
  DeleteModal,
  ExportPDFButton,
  ExportTextButton,
  GDDModal,
  GenresSelection,
  HtmlTooltip,
  ShareTeamButton,
  ShareTooltip,
  UploadImage,
  WhiteHtmlTooltip,
} from "pages/GDD3/Helpers";
import ShowIf from "components/common/ShowIf";
import { Form, Formik } from "formik";
import { FormikSelectField } from "formik-material-fields";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { ShareToSlackButton } from "components/Sharing/ShareToSlack";
import moment from "moment/moment";
import { isTeamsPlan } from "scenes/SubscriptionPage/Plans";
import { useSnackbar } from "notistack";
import CacheContext from "context/CacheContext";
import AuthContext from "context/AuthContext";
import "./style.scss";
import APIContext from "../../../context/APIContext";
import PerformanceUtils from "../../../helpers/PerformanceUtils";

const EDIT_OPTIONS = [
  {
    value: "edit",
    label: (
      <div className="d-flex align-items-center">
        <EditOutlined className="font-size-lg mr-2" />
        Edit Mode
      </div>
    ),
    description: "Make changes to your document",
  },
  {
    value: "preview",
    label: (
      <div className="d-flex align-items-center">
        <VisibilityOutlined className="font-size-lg mr-2" />
        Review Mode
      </div>
    ),
    description:
      "See what your document will look like when exported and add comments",
  },
];

export const HeaderPopup = ({
  addRef,
  open,
  onClose,
  menus,
  addImages,
  onClick,
  headerUrl,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const [input, setInput] = useState("");
  const [data, setData] = useState();
  const onClickWrapper = (event, menu) => {
    event.stopPropagation();
    event.preventDefault();

    if (menus[menu].showInput) {
      setInput("");
      setData(menu);
      setMenuAnchorEl(event.currentTarget);
    } else {
      onClose();
      onClick(menu);
    }
  };

  function onInputKeyDown(event) {
    if (event.key === "Enter") {
      onSubmitInput();
    } else if (event.key === "Escape") {
      resetData();
    }
  }

  function onSubmitInput() {
    onClick(data, undefined, input);
    resetData();
  }

  function resetData() {
    setMenuAnchorEl();
    setInput();
    setData();
  }
  const onCloseWrapper = (event) => {
    event.stopPropagation();
    event.preventDefault();
    resetData();
    onClose();
  };

  const hasHeaderImage = !!headerUrl;

  return (
    <ClickAwayListener onClickAway={resetData}>
      <div className="header-popup-wrapper hide-preview">
        <div ref={addRef}>
          <WhiteHtmlTooltip
            interactive
            open={open}
            placement="bottom"
            title={
              <div className="header-popup d-flex flex-column">
                {menuAnchorEl && (
                  <div className="gdd-floating-input-wrapper" style={{left: "10px", top: "-50px"}}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={input}
                      margin="dense"
                      label="Input (optional)"
                      onChange={(e) => setInput(e.target.value)}
                      style={{ width: "200px"}}
                      onKeyDown={(e) => onInputKeyDown(e)}
                      autoFocus
                    />
                    <div className="icon-wrapper">
                      <ArrowRightOutlined onClick={onSubmitInput} />
                    </div>
                  </div>
                )}
                <span className="title">
                  {hasHeaderImage ? "Edit" : "Add"} Concept Art
                  <span
                    className="top-right"
                    style={{ top: "37px", right: "30px" }}
                    onClick={onCloseWrapper}
                  >
                    <CloseOutlined className="font-size-xxl pointer text-secondary" />
                  </span>
                </span>
                {Object.keys(menus).map((key) => {
                  return (
                    <span
                      className="item"
                      key={key}
                      onClick={(event) => onClickWrapper(event, menus[key].id)}
                    >
                      {menus[key].buttonLabel}
                      {menus[key].label}
                    </span>
                  );
                })}
                <UploadImage
                  addImages={addImages}
                  uploadText="Drag & Drop Image"
                />
              </div>
            }
          >
            <div />
          </WhiteHtmlTooltip>
        </div>
      </div>
    </ClickAwayListener>
  );
};

const DEFAULT_OBJECT = {};

export const ReviewBar = ({
  gdd,
  setPreview,
  changeGdd,
  changeAllGdd,
  shareToSlack,
  downloadPDF,
  project,
  editable,
  preview,
  numberAllowedProjects,
}) => {
  const { openMenu, menu } = useContext(GDDContext);
  const { cache, setCacheValue, trialExpired } = useContext(CacheContext);
  const { auth } = useContext(AuthContext);
  const [deleteModal, setDeleteModal] = useState(false);
  const [openShareTeam, setOpenShareTeam] = useState(false);
  const [openComments, setOpenComments] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [share, setShare] = useState(false);
  const [conceptAnchor, setConceptAnchor] = useState(null);

  const canUseTeam = isTeamsPlan(auth.subscription);
  const { gddComments = DEFAULT_OBJECT, selectedProjectId } = cache;

  const commentsRef = useRef();
  const shareTeamRef = useRef();
  const { archived = false, unarchived = true } = gddComments;

  function toggleOpenShareTeam() {
    setOpenShareTeam(!openShareTeam);
  }

  function openChat() {
    openMenu({
      component: { section: "chat" },
      option: "chat",
      section: undefined,
    });
  }

  const canOpenChat = !menu?.option;

  function onToggleComments(event, values) {
    function makeChanges(values) {
      values = { ...gddComments, ...values };
      setCacheValue("gddComments", values);
    }

    cancelEvent(event);
    if (!canUseTeam)
      return showMessage("GDD Comments are only available in the Studio plan!");
    if (values) {
      return makeChanges(values);
    }
  }

  function cancelEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  function showMessage(message) {
    let key = message + "" + moment().unix();
    enqueueSnackbar(message, {
      key,
      variant: "info",
      autoHideDuration: 5000,
      onClick: () => closeSnackbar(key),
    });
  }

  const isOwnProject = project.user_id === auth.user._id;

  const anyComments = gddComments.archived || gddComments.unarchived;

  let commentsClassName = anyComments || openComments ? "active mr-4" : "mr-4";

  let unarchivedClassName =
    "highlight " + (unarchived ? " active" : "inactive");
  let archivedClassName = "highlight " + (archived ? " active" : "inactive");
  if (!canUseTeam) {
    unarchivedClassName += " disabled";
    archivedClassName += " disabled";
  }

  return (
    <div className="review-bar">
      <GenresSelection
        project={project}
        gdd={gdd}
        changeGdd={changeGdd}
        changeAllGdd={changeAllGdd}
        preview={preview}
      />
      <ShareGDDModal
        gdd={gdd}
        open={share}
        project={project}
        setOpen={setShare}
        shareToSlack={shareToSlack}
        downloadPDF={downloadPDF}
      />
      <div className="right-section">
        <div className="icon-buttons">
          <ShowIf condition={!editable}>
            <div className="read-only mr-2">
              <span className="description">This concept is read only</span>
              <span className="hint-wrapper">
                <Tooltip
                  title={`Your subscription only allows for ${numberAllowedProjects} active concepts. Upgrade now to increase the number of editable concepts!`}
                  arrow
                  PopperProps={{
                    disablePortal: true,
                    className:
                      "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                  }}
                >
                  <span className="hint">
                    <Info className={"font-size-xxl ml-1 text-secondary"} />
                  </span>
                </Tooltip>
              </span>
            </div>
          </ShowIf>
          <ShowIf condition={canUseTeam && preview}>
            <span
              className={commentsClassName}
              ref={commentsRef}
              onClick={() => setOpenComments(!openComments)}
            >
              Comments
              {openComments ? (
                <ExpandLess className="font-size-lg ml-2" />
              ) : (
                <ExpandMore className="font-size-lg ml-2" />
              )}
            </span>
            <Popper
              open={openComments}
              anchorEl={commentsRef.current}
              role={undefined}
              transition
              disablePortal
              style={{ zIndex: 1 }}
              className="comments-popup"
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={(event) => {
                        if (
                          commentsRef.current &&
                          commentsRef.current.contains(event.target)
                        )
                          return;
                        setOpenComments(false);
                      }}
                    >
                      <MenuList
                        autoFocusItem={openComments}
                        id="menu-list-grow"
                      >
                        <MenuItem
                          onClick={(event) =>
                            onToggleComments(event, { unarchived: !unarchived })
                          }
                        >
                          <span className={unarchivedClassName}>
                            <span>Unsolved</span>
                            <div
                              style={{
                                display: "inline-flex",
                                position: "relative",
                                left: "15px",
                              }}
                            >
                              <Switch
                                checked={unarchived}
                                name="unarchived"
                                size="small"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            </div>
                          </span>
                        </MenuItem>
                        <MenuItem
                          onClick={(event) =>
                            onToggleComments(event, { archived: !archived })
                          }
                        >
                          <span className={archivedClassName}>
                            <span>Solved</span>
                            <div
                              style={{
                                display: "inline-flex",
                                position: "relative",
                                left: "15px",
                              }}
                            >
                              <Switch
                                checked={archived}
                                name="archived"
                                size="small"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            </div>
                          </span>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </ShowIf>
          <ShowIf condition={editable}>
            <Formik key="edit_mode_form" initialValues={{ edit_mode: "edit" }}>
              <Form style={{ width: "fit-content" }}>
                <FormikSelectField
                  className="edit-mode-selector white"
                  style={{ width: "150px" }}
                  onChange={(event) => {
                    setPreview(event.target.value === "preview");
                  }}
                  name="edit_mode"
                  options={EDIT_OPTIONS.map(({ value, label, description }) => {
                    return {
                      value: value,
                      label: (
                        <div className="d-flex flex-column">
                          <span className={"font-weight-bold"}>{label}</span>
                          <span
                            className="description ml-4 pl-1"
                            style={{ opacity: 0.8 }}
                          >
                            {description}
                          </span>
                        </div>
                      ),
                    };
                  })}
                  fullWidth
                />
              </Form>
            </Formik>
          </ShowIf>
          <ShowIf condition={canUseTeam}>
            <ClickAwayListener
              onClickAway={(event) => {
                if (
                  shareTeamRef.current &&
                  shareTeamRef.current.contains(event.target)
                )
                  return;
                setOpenShareTeam(false);
              }}
            >
              <div ref={shareTeamRef}>
                <HtmlTooltip
                  interactive
                  arrow
                  open={openShareTeam}
                  placement="bottom"
                  title={<ShareTooltip selectedProjectId={selectedProjectId} />}
                >
                  {!!project.team_id ? (
                    <PeopleAltOutlined
                      className="ml-4 pointer"
                      onClick={toggleOpenShareTeam}
                    />
                  ) : (
                    <PersonOutline
                      className="ml-4 pointer"
                      onClick={toggleOpenShareTeam}
                    />
                  )}
                </HtmlTooltip>
              </div>
            </ClickAwayListener>
          </ShowIf>
          <Tooltip
            title="Share"
            arrow
            PopperProps={{
              className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
            }}
            placement="top"
          >
            <div onClick={() => setShare(selectedProjectId)} className="ml-2">
              <ListItemIcon style={{ minWidth: "unset" }}>
                <IconButton
                  component="span"
                  style={{ width: "44px", height: "44px" }}
                >
                  <ShareOutlinedIcon className="font-size-xl" />
                </IconButton>
              </ListItemIcon>
            </div>
          </Tooltip>
          {isOwnProject && (
            <Tooltip
              title="More Options"
              arrow
              PopperProps={{
                className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
              }}
              placement="top"
            >
              <div
                onClick={(event) => {
                  setConceptAnchor({
                    id: selectedProjectId,
                    anchor: event.currentTarget,
                  });
                }}
              >
                <ListItemIcon style={{ minWidth: "unset" }}>
                  <IconButton
                    component="span"
                    style={{ width: "44px", height: "44px" }}
                  >
                    <MoreVert className="font-size-xxxl" />
                  </IconButton>
                </ListItemIcon>
              </div>
            </Tooltip>
          )}
        </div>
        {editable && canOpenChat && (
          <div onClick={openChat} className="ml-2 action ai-chat-icon">
            <div className="hvr-grow icon-wrapper">
              <TryOutlined className="font-size-xxl" />
              <span>Ask Ludo</span>
            </div>
          </div>
        )}
      </div>
      <DeleteModal id={deleteModal} onClose={() => setDeleteModal(false)} />
      <Menu
        anchorEl={conceptAnchor?.anchor}
        open={!!conceptAnchor?.id}
        onClose={(event) => {
          event.stopPropagation();
          event.preventDefault();
          setConceptAnchor();
        }}
      >
        <MenuItem
          className="text-red"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setDeleteModal(conceptAnchor.id);
            setConceptAnchor();
          }}
        >
          <ListItemIcon>
            <DeleteOutline className="font-size-xxxl" />
          </ListItemIcon>
          <ListItemText primary="Delete Project" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export const ShareGDDModal = ({
  open,
  setOpen,
  shareToSlack,
  downloadPDF,
  gdd,
  project,
  title = "Share this Game Concept",
}) => {
  const { call } = useContext(APIContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { cache, setCache, trialExpired } = useContext(CacheContext);
  const { auth } = useContext(AuthContext);
  const { subscription, user } = auth;

  const canUseTeam = isTeamsPlan(subscription);

  const { gddComponents } = cache;

  async function onClickText() {
    let text = "";

    function addTitle(str) {
      text += `# ${str}\n\n`;
    }

    function addParagraph(str) {
      text += `${str}\n\n`;
    }

    let summary = gdd.sections.find(({ name }) => name === "summary");
    addTitle(`Title: ${summary.value.title}`);
    if (gdd.genres?.length) addParagraph(`Genres: ${gdd.genres.join(", ")}`);
    if (gdd.platform) addParagraph(`Platform: ${gdd.platform}`);
    addParagraph(summary.value.text);

    for (let i = 1; i < gdd.sections.length; i++) {
      let section = gdd.sections[i];
      let name =
        section.value.title ||
        gddComponents[section.name]?.label ||
        capitalize(section.name);
      if (section.value.text) {
        addTitle(name);
        addParagraph(section.value.text);
      }
      if (section.value.elements?.length) {
        addTitle(name);
        section.value.elements.forEach((element) => {
          addParagraph(element.text);
        });
      }
    }

    await navigator.clipboard.writeText(text);
    let key = "onClickText";
    enqueueSnackbar("Game Concept text copied to clipboard!", {
      key,
      variant: "info",
      autoHideDuration: 5000,
      onClick: () => closeSnackbar(key),
    });
  }

  function showMessage(message) {
    let key = message + "" + moment().unix();
    enqueueSnackbar(message, {
      key,
      variant: "info",
      autoHideDuration: 5000,
      onClick: () => closeSnackbar(key),
    });
  }

  function onClickPDF() {
    if (trialExpired)
      return showMessage("PDF Export is not available in the Basic plan!");
    downloadPDF();
  }

  async function shareTeam() {
    let team_id = project.team_id ? undefined : user.teams?.[0];
    let response = await call("addProject", {
      project: { ...project, team_id },
    });
    if (response.ok) {
      setCache((prevState) => {
        return {
          ...prevState,
          projects: PerformanceUtils.editOrAdd(
            response.body,
            prevState.projects,
            "_id"
          ),
        };
      });
    }
  }

  return open ? (
    <GDDModal title={title} open={open} onClose={() => setOpen(false)}>
      <div className="d-flex flex-row centered share-content">
        <div className="px-4">
          <ExportTextButton onClick={onClickText} />
        </div>
        <div className="px-4">
          <ExportPDFButton onClick={onClickPDF} />
        </div>
        <div className="px-4">
          <ShareToSlackButton onClick={shareToSlack} />
        </div>
        {canUseTeam && (
          <div className="px-4">
            <ShareTeamButton onClick={shareTeam} isShared={project.team_id} />
          </div>
        )}
      </div>
    </GDDModal>
  ) : null;
};
