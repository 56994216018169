import React, { useContext, useEffect, useMemo, useState } from "react";
import APIContext from "context/APIContext";
import ShowIf from "components/common/ShowIf";
import { Badge, CircularProgress, Grid } from "@material-ui/core";
import CacheContext from "context/CacheContext";
import ImageGallery from "components/common/ImageGallery";
import GameGrid from "components/common/GameGrid";
import { TextResult } from "pages/FeatureGenerator";
import PageTitle from "components/layout-components/PageTitle";
import "./style.scss";
import { FormTab, FormTabs } from "components/common/FormTabs";
import DismissableMessage from "components/common/DismissableMessage";
import { GeneratedGamesGrid } from "../GameGenerator";
import { ThreeDAssets } from "../ThreeDGenerator";

const getAllActiveFavorites = "getAllActiveFavorites";
const getGamesInformation = "getGamesInformation";
const DEFAULT_ARRAY = [];

export const FAVORITE_TYPES = {
  image: "image",
  game: "game",
  generated_game: "generated_game",
  mechanic: "mechanic",
  feature: "feature",
  text: "text",
  three_d_asset: "three_d_asset",
};

const Favorites = ({}) => {
  const { setCacheValue, cache } = useContext(CacheContext);
  const { allFavorites = DEFAULT_ARRAY, selectedProjectId } = cache;
  const { call } = useContext(APIContext);
  const [isLoading, setIsLoading] = useState(true);

  const [tab, setTab] = useState(0);

  const gameIds = useMemo(
    () => filterFavorites(allFavorites, FAVORITE_TYPES.game, "payload_id"),
    [allFavorites]
  );
  const mechanics = useMemo(
    () => filterFavorites(allFavorites, FAVORITE_TYPES.mechanic, "text"),
    [allFavorites]
  );
  const features = useMemo(
    () => filterFavorites(allFavorites, FAVORITE_TYPES.feature, "text"),
    [allFavorites]
  );
  const text = useMemo(
    () => filterFavorites(allFavorites, FAVORITE_TYPES.text, "text"),
    [allFavorites]
  );
  const images = useMemo(
    () => filterFavorites(allFavorites, FAVORITE_TYPES.image),
    [allFavorites]
  );
  const generatedGames = useMemo(
    () => filterFavorites(allFavorites, FAVORITE_TYPES.generated_game),
    [allFavorites]
  );

  const threeD = useMemo(
    () => filterFavorites(allFavorites, FAVORITE_TYPES.three_d_asset),
    [allFavorites]
  );

  async function changeGeneratedGames(func) {
    let newGames = func(JSON.parse(JSON.stringify(generatedGames)));
    let modifiedGames = newGames.filter((game) => {
      let match = generatedGames.find((g) => g.id === game.id);
      if (match) {
        return JSON.stringify(match) !== JSON.stringify(game);
      }
    });

    let changes = allFavorites.map((fav) => {
      let match = modifiedGames.find((g) => g.id === fav.payload_id);
      if (match) {
        return {
          ...fav,
          generated_game: match,
        };
      }
      return fav;
    });
    setCacheValue("allFavorites", changes);

    await Promise.all(
      modifiedGames.map(async (game) => {
        await call("addFavoriteGeneratedGame", {
          game,
          projectId: selectedProjectId,
        });
      })
    );
  }

  useEffect(() => {
    async function getAndSaveFavorites() {
      let response = await call(getAllActiveFavorites);
      if (response.ok) setCacheValue("allFavorites", response.body);
      setIsLoading(false);
    }

    getAndSaveFavorites().catch((err) => {});
  }, []);

  const [gameElements, gameElementsTotal] = useMemo(() => {
    let results = {};
    let total = 0;
    text.forEach((result) => {
      results[result.type] = results[result.type] || [];
      results[result.type].push(result);
      total++;
    });
    mechanics.forEach((result) => {
      results["mechanics"] = results["mechanics"] || [];
      results["mechanics"].push({ ...result, type: "mechanics" });
      total++;
    });
    features.forEach((result) => {
      results["features"] = results["features"] || [];
      results["features"].push({ ...result, type: "features" });
      total++;
    });

    let arrayResult = [];
    Object.keys(results).forEach((key) => {
      arrayResult = [...arrayResult, ...results[key]];
    });
    return [arrayResult, total];
  }, [mechanics, features, text]);

  return (
    <div className="favorites">
      <PageTitle
        titleHeading="Favorites"
        titleDescription="All your saved Ideation and Research favorites in one place."
      ></PageTitle>
      <FormTabs value={tab} className="m-5">
        <FormTab
          label={
            <Badge badgeContent={generatedGames.length} color="secondary">
              <span onClick={() => setTab(0)}>Game Ideas</span>
            </Badge>
          }
          onClick={() => setTab(0)}
        />
        <FormTab
          label={
            <Badge badgeContent={gameElementsTotal} color="secondary">
              <span>Game Elements</span>
            </Badge>
          }
          onClick={() => setTab(1)}
        />
        <FormTab
          label={
            <Badge badgeContent={gameIds.length} color="secondary">
              <span>Games</span>
            </Badge>
          }
          onClick={() => setTab(2)}
        />
        <FormTab
          label={
            <Badge badgeContent={images.length} color="secondary">
              <span>Images</span>
            </Badge>
          }
          onClick={() => setTab(3)}
        />
        <FormTab
          label={
            <Badge badgeContent={threeD.length} color="secondary">
              <span>3D Assets</span>
            </Badge>
          }
          onClick={() => setTab(4)}
        />
      </FormTabs>
      <ShowIf condition={isLoading}>
        <CircularProgress size={55} className="m-5" />
      </ShowIf>
      <ShowIf condition={!isLoading}>
        <div className="content">
          <div className={"main-content tab-" + tab}>
            {tab === 0 && (
              <GeneratedGamesGrid
                key="generated-games"
                keyAdd="favorites"
                games={generatedGames}
                setGames={changeGeneratedGames}
              />
            )}
            <ShowIf condition={tab === 1}>
              <TextResult
                columnsCountBreakPoints={{ 350: 1, 600: 2, 1000: 3 }}
                data={gameElements}
              />
            </ShowIf>
            <ShowIf condition={tab === 2 || tab === 3}>
              <DismissableMessage
                id="22062022-favorites-moved"
                message={
                  <span>
                    Ludo's suggestions can now be found on the Game Search and
                    Image Search pages!
                  </span>
                }
                displayUntil="22072022"
                style={{
                  width: "fit-content",
                  maxWidth: "100%",
                  marginBottom: "20px",
                  paddingRight: "30px",
                }}
              />
            </ShowIf>
            <ShowIf condition={tab === 2}>
              <GameGridWithIds gameIds={gameIds} includeStats={true} />
            </ShowIf>
            <ShowIf condition={tab === 3}>
              <ImageGallery images={images} minImages={4} onImageClick={true} />
            </ShowIf>
            <ShowIf condition={tab === 4}>
              <ThreeDAssets assets={threeD} />
            </ShowIf>
          </div>
        </div>
      </ShowIf>
    </div>
  );
};

export const GameGridWithIds = ({
  gameIds,
  gameWidth,
  spacing,
  includeStats = false,
  allGenres,
  children,
  gameProps,
}) => {
  const { call } = useContext(APIContext);
  const [fetchedGames, setFetchedGames] = useState([]);

  const filteredGames = useMemo(
    () => fetchedGames.filter((game) => gameIds.includes(game._id)),
    [gameIds, fetchedGames]
  );
  const shouldFetch = gameIds.length !== filteredGames.length;

  useEffect(() => {
    if (shouldFetch) {
      call(getGamesInformation, { data: { ids: gameIds } }).then((response) => {
        if (response.ok) {
          setFetchedGames(response.body);
        }
      });
    }
  }, [gameIds, shouldFetch, gameProps?.location]);

  return (
    <GameGrid
      games={filteredGames}
      gameWidth={gameWidth}
      spacing={spacing}
      children={children}
      gameProps={gameProps}
      includeStats={includeStats}
      allGenres={allGenres}
    />
  );
};

export default Favorites;

export function filterFavorites(favorites = [], type, key) {
  return favorites
    .filter((favorite) => favorite.type === type)
    .map((favorite) => favorite[key || type]);
}
