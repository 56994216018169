import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  CardContent,
  IconButton,
  Dialog,
  Card,
  Grid,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import React, {
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ShowIf from "components/common/ShowIf";
import "./style.scss";
import { Link } from "react-router-dom";
import { GeneratedGameFavoriteButton } from "components/common/FavoriteButton";
import { ShareContextMenu } from "components/Sharing";
import DetailsPanelContext from "context/DetailsPanelContext";
import SocketContext from "context/SocketContext";
import CacheContext from "context/CacheContext";
import { addNewProject, FAIcon } from "pages/GDD3/Helpers";
import APIContext from "context/APIContext";
import { useHistory } from "react-router";
import WbIncandescentOutlinedIcon from "@mui/icons-material/WbIncandescentOutlined";
import {
  ArrowBackIosNewOutlined,
  ArrowForwardIosOutlined,
  CloseOutlined,
  ContentCopyOutlined,
  Favorite,
  FavoriteBorder,
  FileCopyOutlined,
  LightbulbOutlined,
  SearchOutlined,
  Sync,
  TrendingUpOutlined,
} from "@mui/icons-material";
import { IMAGE_TYPES, prepareGeneratedImage } from "pages/ImageGenerator";
import { GameIcon } from "components/common/GameCard";
import { FormTab, FormTabs } from "components/common/FormTabs";
import { Hint } from "scenes/Headquarters";
import MyButton from "components/Controls/MyButton";
import PerformanceUtils from "helpers/PerformanceUtils";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/RadioButtonUnchecked";
import LudoCarousel, {
  CustomLeftArrow,
  CustomRightArrow,
} from "../LudoCarousel";
import { ChartGame } from "../../../pages/TopCharts";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { UncontrolledCollapse } from "../Collapse";
import { useContainerQuery } from "react-container-query";
import { convertProxyUrl, MyImage } from "../ImageGallery";
import bgImage from "assets/images/background/score-bg.png";
import LinearProgress from "@material-ui/core/LinearProgress";
import { GameIcons } from "../../../pages/CompetitiveAnalysis";

const DEFAULT_OBJECT = {};
const DEFAULT_ARRAY = [];

const generateImagesForGenerations = "generateImagesForGenerations";

const GeneratedGameCard = ({
  game,
  className = "",
  showGeneratedFrom = true,
  onGenerateImage,
  extraGameActions,
  onClick,
  actions,
  smallVersion = false,
  index,
  onImageClickFunc,
  showDevelopButton = true,
}) => {
  const { track } = useContext(SocketContext);
  const { loading } = useContext(APIContext);
  const [showLoading, setShowLoading] = useState(false);
  const [showCreateGDD, setShowCreateGDD] = useState(false);

  const { title } = game;

  const image = useMemo(() => {
    if (game.image) return prepareGeneratedImage(game.image);
  }, [game.image]);

  async function onCopy() {
    await navigator.clipboard.writeText(game.description);
  }

  let finalClassName = "generated-game-card animate__animated animate__fadeIn";
  if (smallVersion) finalClassName += " small-version";
  if (className) finalClassName += " " + className;
  if (index !== undefined) finalClassName += " index-" + index;

  function trackWrapper(event, action) {
    track(action, { game });
  }

  function onClickConceptWrapper() {
    trackWrapper("develop-idea");
    setShowCreateGDD(true);
  }

  return (
    <div
      className={finalClassName}
      onClick={onClick ? () => onClick(game) : undefined}
    >
      {showCreateGDD && (
        <NewGDDModal
          open={showCreateGDD}
          onClose={() => setShowCreateGDD(false)}
          game={game}
          setLoading={setShowLoading}
        />
      )}
      <ShowIf condition={showLoading}>
        <div className="loading-wrapper">
          <CircularProgress size={45} />
        </div>
      </ShowIf>
      {showGeneratedFrom && <GeneratedFrom game={game} />}
      <Card className="inner-card card-box position-relative" id={game.id}>
        <CardContent className="p-1">
          <Grid container direction="row" spacing={1}>
            <Grid
              container
              item
              sm={12}
              lg={12}
              xl={12}
              alignItems="flex-start"
              className="h-100 text-element"
            >
              <GameActions game={game} actions={actions}>
                {extraGameActions}
              </GameActions>
              <Grid
                item
                md={12}
                sm={12}
                style={{
                  gap: "20px",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div className="d-flex flex-row title-wrapper">
                  <span className="font-size-xxl font-weight-bold pt-0 title">
                    {title || "Untitled"}
                  </span>
                </div>
                <GeneratedImage
                  score={game.score}
                  image={image}
                  imageProps={{ hideActions: true, onImageClickFunc }}
                  loading={loading[generateImagesForGenerations]}
                  onNewImage={onGenerateImage}
                />
                <GameInfo game={game} />
                <Description game={game} />
                {showDevelopButton && (
                  <div className="buttons">
                    <MyButton
                      className="gradient bold w-100"
                      onClick={onClickConceptWrapper}
                    >
                      <FileCopyOutlined className="mr-2" /> Develop This Idea
                    </MyButton>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export const GeneratedImage = ({
  image,
  onNewImage,
  loading,
  imageProps = DEFAULT_OBJECT,
  background = false,
  score,
}) => {
  const hasImage =
    !!image?.url && image?.url !== "data:image/png;base64, undefined";

  let finalImageProps = imageProps;

  if (onNewImage) {
    finalImageProps = {
      hideActions: true,
      imageControls: (image) => (
        <RefreshImageControls
          key={image.url}
          image={image}
          onNewImage={onNewImage}
        />
      ),
      ...finalImageProps,
    };
  }

  let className = "generated-image";
  if (image?.width < image?.height) className += " portrait";
  if (hasImage) className += " has-image";

  return (
    <div className={className}>
      {!hasImage && loading && (
        <div className="image-loading-wrapper">
          <CircularProgress className="d-block align-self-center" size={75} />
        </div>
      )}
      <LudoScore score={score} onlyBadge={true} />
      {hasImage && background ? (
        <div
          className="background"
          style={{
            background: `rgba(255, 255, 255, 0.5) url("${convertProxyUrl(
              image
            )}")`,
          }}
        />
      ) : null}
      {hasImage && (
        <MyImage
          key={image.id || image.url}
          image={{ ...image, originalImage: image, src: image.url }}
          imageStyle={{ height: "auto" }}
          {...finalImageProps}
        />
      )}
    </div>
  );
};

const RefreshImageControls = ({ image, onNewImage }) => {
  return (
    !!image.url && (
      <div className="image-actions">
        <FAIcon
          tooltip="Generate New Image"
          icon={<Sync className="font-size-xxxxxl" />}
          className="top-right clickable text-white"
          style={{ color: "white" }}
          onClick={onNewImage}
        />
      </div>
    )
  );
};

export function convertLudoScore(score) {
  return (score * 100).toFixed(0);
}

export function getLudoScoreDescription(
  score,
  includeGames = false,
  onClickMore
) {
  return score?.score !== undefined ? (
    <div className="ludo-score-details">
      {score.metrics.map((metric) => (
        <div className="metric">
          <span className="metric-title">{metric.name}</span>
          <LinearProgress
            variant="determinate"
            value={convertLudoScore(metric.score)}
          />
          {includeGames && (
            <GameIcons
              onClickMore={onClickMore ? () => onClickMore(metric) : null}
              ids={metric.supporting_games}
              showNumber={10}
            />
          )}
          <span>{metric.description}</span>
        </div>
      ))}
    </div>
  ) : null;
}

const LudoScore = ({ score, bg = false, onlyBadge = false }) => {
  const { loading } = useContext(APIContext);
  const isLoading = loading["getLudoScore"];
  if (score?.score === undefined) {
    return isLoading ? (
      <div className="ludo-score-wrapper">
        <CircularProgress className="text-white ml-2" />
      </div>
    ) : null;
  }

  const displayScore = score.score_text || convertLudoScore(score.score);
  const displayUnit = score.score_unit || "";
  const scoreDescription = getLudoScoreDescription(score);
  const badge = score.badge;
  const badgeDescription = (
    <span>{badge?.name + ": " + badge?.description}</span>
  );
  return (
    <div
      className="ludo-score-wrapper"
      style={bg ? { backgroundImage: `url("${bgImage}")` } : null}
    >
      {onlyBadge ? (
        badge && (
          <Tooltip
            arrow
            placement="top"
            title={badgeDescription}
            style={{ zIndex: 10 }}
            PopperProps={{
              disablePortal: true,
              className:
                "MuiTooltip-popper MuiTooltip-popperArrow ludo-score-tooltip-main pre",
            }}
          >
            <div className="ludo-score">
              <img alt={badgeDescription} src={badge.icon_url} />
            </div>
          </Tooltip>
        )
      ) : (
        <Tooltip
          arrow
          placement="top"
          title={scoreDescription}
          style={{ zIndex: 10 }}
          PopperProps={{
            disablePortal: true,
            className:
              "MuiTooltip-popper MuiTooltip-popperArrow ludo-score-tooltip-main pre",
          }}
        >
          <div className="ludo-score">
            <span className="score" data-text="Score">
              Score
            </span>
            <div className="number-wrapper">
              <span className="number" data-text={displayScore}>{displayScore}</span><span className="unit">{displayUnit}</span>
            </div>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export const NewGDDModal = ({
  game,
  gdd,
  open,
  onClose,
  setLoading,
  extraData,
}) => {
  const history = useHistory();
  const { setCache, cache } = useContext(CacheContext);
  const { gddComponents, componentGroups, gddPacks } = cache;
  const { call } = useContext(APIContext);
  const { track } = useContext(SocketContext);

  const { projects = [] } = cache;

  const [tab, setTab] = useState(0);
  const [useAI, setUseAI] = useState(true);
  const [customOptions, setCustomOptions] = useState([
    { type: gddComponents.summary.section, n: 1 },
  ]);

  const TABS = ["Choose Template", "Custom Sections"];

  async function onSubmit(sections = [], useAI) {
    track("packs-modal.submit", { sections, game, gdd, useAI });
    onClose();
    setLoading(true);

    let gddOptions = gdd || {
      art_style: game.image?.selected_style,
      perspective: game.perspective,
      genres: game.genres,
      step_choices: game.step_choices,
      sections: [],
    };

    if (
      !sections.find(({ type }) => type === gddComponents.summary.section) &&
      !gddOptions.sections.find(
        ({ name }) => name === gddComponents.summary.section
      )
    ) {
      sections = [{ type: gddComponents.summary.section, n: 1 }, ...sections];
    }

    let gdd2 = {
      ...gddOptions,
      header: {
        ...(game?.image || {}),
        loading: !game?.image?.url,
      },
      icon: {
        loading: true,
      },
      sections: [
        ...gddOptions.sections,
        ...sections.map(({ type, n }) => {
          let id = PerformanceUtils.generateId();
          const loading = useAI ? n : undefined;
          if (type === gddComponents.summary.section) {
            return {
              name: type,
              value: { text: game.description, title: game.title, id, _id: id },
            };
          } else if (type === gddComponents.moodboard.section) {
            return {
              name: type,
              id,
              _id: id,
              value: { images: [] },
              loading,
            };
          } else if (
            type === IMAGE_TYPES.art.value ||
            type === IMAGE_TYPES.screenshot.value
          ) {
            return {
              name: gddComponents.moodboard.section,
              value: { title: gddComponents[type].label, imageType: type },
              id,
              _id: id,
              loading,
            };
          } else {
            return { name: type, value: { title: "" }, id, _id: id, loading };
          }
        }),
      ],
    };

    function addSection(name, value, first = false) {
      let id = PerformanceUtils.generateId();
      if (first) gdd2.sections.splice(1, 0, { name, id, _id: id, value });
      else gdd2.sections.push({ name, id, _id: id, value });
    }

    if (extraData?.similar_games?.length > 0) {
      let games = extraData.similar_games.slice(0, 7).map((game) => game.id);
      addSection(gddComponents.games.section, {
        title: "Similar Games",
        games,
      });
    }

    if (extraData?.trending_games?.length > 0) {
      let games = extraData.trending_games.slice(0, 7).map((game) => game.id);
      addSection(gddComponents.games.section, {
        title: "Trending Games",
        games,
      });
    }

    let project = await addNewProject(
      projects,
      call,
      setCache,
      gdd?.platform || game?.platform,
      gdd2
    );
    if (project) {
      let path = {
        pathname: "/game-concept/" + project._id,
        state: {
          data: {
            extract: !!gdd,
            generateElements: useAI || !!game || !!gdd,
          },
        },
      };
      history.push(path);
    }
  }

  function clickedCustom(option, n = 1) {
    if (option.section) {
      setCustomOptions((prevState) => {
        let found = prevState.find((o) => o.type === option.section);
        if (found) return prevState.filter((o) => o.type !== option.section);
        return [...prevState, { type: option.section, n }];
      });
    }
  }

  function calculateCredits(sections = []) {
    return useAI ? Math.max(0, (sections.length - 1) * 0.5) : 0;
  }

  const groups = useMemo(() => {
    return componentGroups
      .map((group) => {
        return {
          ...group,
          components: group.components.filter(
            (component) => !component.hide && component.gameElements
          ),
        };
      })
      .filter((group) => group.components.length > 0);
  }, [componentGroups]);

  return (
    <Dialog open={open} onClose={onClose} className="new-gdd-modal">
      <span className="large-title">
        Create Game Concept
        <Hint
          hint="Select how you first want to expand this idea and Ludo.ai will create and prefill a game concept document based on your selection"
          iconClassName="help"
        />
      </span>
      <span className="top-right">
        <IconButton onClick={onClose}>
          <CloseOutlined className="font-size-xxxxl pointer text-white" />
        </IconButton>
      </span>
      <div className="new-gdd-modal-content">
        <FormTabs value={tab} className="mt-4 white">
          {TABS.map((value, index) => (
            <FormTab
              key={index}
              label={<span>{value}</span>}
              onClick={() => setTab(index)}
            />
          ))}
        </FormTabs>

        <div onClick={() => setUseAI(!useAI)} className="mb-4 pointer">
          <Checkbox checked={useAI} className="p-0 mr-2 white" />
          <span>
            Automatically fill up sections using Ludo's recommendations
            <Hint hint="Use Ludo's AI capabilities to generate content for the sections you select" />
          </span>
        </div>

        {tab === 0 && (
          <div className="options">
            <Grid container spacing={2}>
              {gddPacks.map((option, index) => {
                const numberCredits = calculateCredits(option.components);
                return (
                  <Grid item sm={12} md={6}>
                    <Tooltip
                      title={option.description}
                      arrow
                      PopperProps={{
                        className:
                          "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                      }}
                      placement="top"
                    >
                      <div
                        className="option"
                        onClick={() => onSubmit(option.components, useAI)}
                      >
                        <div className="title-wrapper">
                          <span className="title">{option.title}</span>
                          <div>
                            <span className="credits">
                              {numberCredits} credits
                            </span>
                          </div>
                        </div>
                        <ul>
                          {option.components?.map(({ type }) => (
                            <li key={type}>
                              {gddComponents[type]?.label || type}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Tooltip>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        )}
        {tab === 1 && (
          <>
            <div className="checkboxes">
              <div className="group">
                <span className="secondary-title">Game Presentation</span>
                <div className="options">
                  <Grid container spacing={2}>
                    <CheckboxOptions
                      values={[gddComponents.summary]}
                      customOptions={customOptions}
                    />
                  </Grid>
                </div>
              </div>
              {groups.map((group) => {
                return (
                  <div className="group">
                    <span className="secondary-title">
                      {group.title}
                      <Hint hint={group.description} iconClassName="help" />
                    </span>
                    <div className="options">
                      <Grid container spacing={0}>
                        <CheckboxOptions
                          values={group.components}
                          customOptions={customOptions}
                          onClick={clickedCustom}
                          max={6}
                        />
                      </Grid>
                    </div>
                  </div>
                );
              })}
              <div className="group">
                <span className="secondary-title">Standard Sections</span>
                <div className="options">
                  <Grid container spacing={0}>
                    <CheckboxOptions
                      values={[gddComponents.art, gddComponents.screenshot, gddComponents.assets, gddComponents.games]}
                      customOptions={customOptions}
                      onClick={(value) => clickedCustom(value, 3)}
                      max={6}
                    />
                  </Grid>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {tab === 1 && (
        <div className="custom-button-wrapper">
          <MyButton
            color="secondary"
            onClick={() => onSubmit(customOptions, useAI)}
          >
            Create Game Concept
            <span className="credits">
              {calculateCredits(customOptions)} credits
            </span>
          </MyButton>
        </div>
      )}
    </Dialog>
  );
};

const CheckboxOptions = ({ values, customOptions, onClick, max }) => {
  return values.map((value, index) => {
    let checked = !!customOptions.find(
      (option) => option.type === value.section
    );
    let disabled = !onClick;
    if (max && customOptions.length > 6 && !checked) disabled = true;

    return (
      <Grid
        item
        sm={12}
        md={6}
        key={index}
        onClick={onClick && !disabled ? () => onClick(value) : undefined}
        className="pointer"
      >
        <Checkbox
          icon={<CircleIcon />}
          checkedIcon={<CheckCircleOutlineIcon />}
          checked={checked}
          className="p-0 mr-2 white"
          disabled={disabled}
        />
        <span onClick={onClick}>{value.label}</span>
      </Grid>
    );
  });
};

export const GameActions = ({
  game,
  children,
  actions = { favorite: true, more: true, generateSimilar: true },
  afterClickedFavorite,
}) => {
  const { track } = useContext(SocketContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  function trackWrapper(event, action) {
    track(action, { game });
  }

  function onActionsClose(event) {
    event.stopPropagation();
    event.preventDefault();
    setMenuAnchorEl(null);
  }

  function onActionsClick(event) {
    track("generated-game-card.open-context", { game });
    event.stopPropagation();
    event.preventDefault();
    setMenuAnchorEl(event.currentTarget);
  }

  return (
    <div className="game-actions mr-4">
      <GameContextMenu
        game={game}
        onClose={onActionsClose}
        anchor={menuAnchorEl}
        actions={actions}
      />
      <ShowIf condition={!!children}>{children}</ShowIf>
      <ShowIf condition={!!actions.favorite}>
        <GeneratedGameFavoriteButton
          game={game}
          afterClickedFavorite={afterClickedFavorite}
        />
      </ShowIf>

      <ShowIf condition={!!actions.generateSimilar}>
        <Link
          to={{
            pathname: "/game-ideator",
            state: { data: { generated_game: game } },
          }}
          style={{ display: "contents" }}
          onClick={(event) =>
            trackWrapper(event, "context.generated-game.generate")
          }
        >
          <Tooltip
            title="Generate based on this idea"
            arrow
            PopperProps={{
              className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
            }}
            placement="top"
          >
            <IconButton
              aria-label="generate based on this idea"
              className="text-primary more-actions"
            >
              <WbIncandescentOutlinedIcon className="flip-vertical" />
            </IconButton>
          </Tooltip>
        </Link>
      </ShowIf>

      <ShowIf condition={!!actions.more}>
        <IconButton
          aria-label="more actions"
          className="text-primary more-actions"
          onClick={onActionsClick}
        >
          <MoreVertIcon />
        </IconButton>
      </ShowIf>
    </div>
  );
};

export const Description = ({ game }) => {
  const { description } = game;

  const description1 = useMemo(() => {
    return (description || "").split("\n\n")[0];
  }, [description]);

  return (
    <div className="description">
      <span className="description-0">
        <b>{description1}</b>
      </span>
    </div>
  );
};

export const GeneratedFrom = ({ game = DEFAULT_OBJECT }) => {
  const { track } = useContext(SocketContext);
  const { showGame } = useContext(DetailsPanelContext);

  async function onClickGame(game) {
    track("generated-game-card.clicked-generated-from-game", {
      game,
      blended_id: game._id,
    });
    showGame(game);
  }

  let total = game.blended_games?.length;

  return (
    <ShowIf condition={game.blended_games?.length > 0}>
      <div
        className="blending-games"
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        {game.blended_games?.map((game, index) => {
          let includePlus = index < total - 1;
          return (
            <React.Fragment key={game._id}>
              <Tooltip
                title={game.title}
                placement="top"
                PopperProps={{
                  className:
                    "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                }}
              >
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    onClickGame(game);
                  }}
                >
                  {!!game.icon && <GameIcon game={game} onClick={showGame} />}
                  {!game.icon && (
                    <div className="no-icon" onClick={() => showGame(game)}>
                      {(game?.title || "").charAt(0)}
                    </div>
                  )}
                </div>
              </Tooltip>
              {includePlus ? <span className="plus">+</span> : null}
            </React.Fragment>
          );
        })}
      </div>
    </ShowIf>
  );
};

const GameInfo = ({ game }) => {
  let { genres } = game;
  genres = genres || [];
  return (
    <ShowIf condition={genres.length > 0}>
      <div className="game-info">
        <div className="genres">
          {genres.map((genre) => (
            <Chip className="bg-blue" key={genre} size="small" label={genre} />
          ))}
        </div>
      </div>
    </ShowIf>
  );
};

const GameContextMenu = ({ game, onClose, anchor, actions = {} }) => {
  const { track } = useContext(SocketContext);

  function trackWrapper(event, action) {
    track(action, { game });
  }

  return (
    <Menu
      anchorEl={anchor}
      keepMounted
      open={!!anchor}
      onClose={onClose}
      key={game.id}
    >
      {!actions.generateSimilar && (
        <MenuItem onClick={onClose}>
          <Link
            to={{
              pathname: "/game-ideator",
              state: { data: { generated_game: game } },
            }}
            style={{ display: "contents" }}
            onClick={(event) =>
              trackWrapper(event, "context.generated-game.generate")
            }
          >
            <ListItemIcon>
              <WbIncandescentOutlinedIcon className="flip-vertical" />
            </ListItemIcon>
            <ListItemText primary="Generate based on this idea" />
          </Link>
        </MenuItem>
      )}
      <MenuItem onClick={onClose}>
        <Link
          to={{
            pathname: "/search",
            state: { data: { generated_game: game } },
          }}
          style={{ display: "contents" }}
          onClick={(event) =>
            trackWrapper(event, "context.generated-game.search-similar", true)
          }
        >
          <ListItemIcon>
            <SearchOutlined />
          </ListItemIcon>
          <ListItemText primary="Search similar" />
        </Link>
      </MenuItem>
      <MenuItem onClick={onClose}>
        <ShareContextMenu
          trackId="context.generated-game.share"
          trackOptions={{ game }}
          data={{ generated_game: game }}
        />
      </MenuItem>
    </Menu>
  );
};

const CONTAINER_QUERY = { small: { maxWidth: 767 } };

export const GeneratedGameDetailPanel = ({
  game,
  allowScroll = true,
  extraData = DEFAULT_OBJECT,
  currentIndex,
  totalNumber,
  onNext,
  onPrevious,
  smallVersion = false,
  onClose,
}) => {
  currentIndex += 1;

  const { track } = useContext(SocketContext);

  function trackWrapper(action, data = {}) {
    track("generated-game-details." + action, { game, ...data });
  }

  const topRef = useRef(null);

  useLayoutEffect(() => {
    if (allowScroll && topRef?.current) {
      const rect = topRef.current.getBoundingClientRect();
      const isVisible =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth);

      if (!isVisible) {
        topRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
  }, [topRef, allowScroll]);

  const [params, containerRef] = useContainerQuery(CONTAINER_QUERY);

  const { call } = useContext(APIContext);
  const history = useHistory();
  const { setCacheValue } = useContext(CacheContext);
  const { showTrendTopic, showGame } = useContext(DetailsPanelContext);

  const [showCreateGDD, setShowCreateGDD] = useState(false);
  const [loading, setLoading] = useState(false);

  const { cache, removeFavoriteFromCache, addFavoriteToCache } =
    useContext(CacheContext);
  const { selectedProjectId, allFavorites = DEFAULT_ARRAY } = cache;

  const {
    title,
    description,
    selected_genres = DEFAULT_ARRAY,
    platform,
    image,
    hints = DEFAULT_ARRAY,
  } = game;
  const style = image?.selected_style;
  const imageUrl = image?.url;
  const gameData = extraData[game.id] || DEFAULT_OBJECT;
  const { similar_games, trending_games, trending_topics } = gameData;

  const favoriteMatch = useMemo(() => {
    return allFavorites.find(
      (favorite) => favorite.payload_id && favorite.payload_id === game.id
    );
  }, [allFavorites, game.id]);

  function onGenerateVariations() {
    trackWrapper("generate-variations");
    history.push("/game-ideator", { data: { generated_game: game } });
  }

  async function onFavorite() {
    if (favoriteMatch) {
      let toRemove = { ...favoriteMatch };
      trackWrapper("favorite.add");
      let response = await call("removeFavorite", { id: favoriteMatch._id });
      if (response.ok) {
        removeFavoriteFromCache(toRemove);
      }
    } else {
      let response = await call("addFavoriteGeneratedGame", {
        game,
        projectId: selectedProjectId,
      });
      trackWrapper("favorite.remove");
      if (response.ok) {
        addFavoriteToCache(response.body);
      }
    }
  }

  function onClickedGame(game) {
    trackWrapper("open-game", { existing_game: game?.id });
    showGame(game);
  }

  function onClickedTopic(topic) {
    trackWrapper("topic", { topic });
    showTrendTopic(topic);
  }

  async function copyText() {
    trackWrapper("copy-text");
    await navigator.clipboard.writeText(description);
  }

  function shareIdea() {
    trackWrapper("share-idea");
    setCacheValue("shareOpen", { data: { generated_game: game } });
  }

  function developIdea() {
    trackWrapper("develop-idea");
    setShowCreateGDD(true);
  }

  function onPreviousWrapper() {
    trackWrapper("previous");
    onPrevious();
  }

  function onNextWrapper() {
    trackWrapper("next");
    onNext();
  }

  const hasPrevious = currentIndex > 1;
  const hasNext = currentIndex < totalNumber;

  const { description1, description2 } = useMemo(() => {
    let split = (description || "").split("\n\n");
    return {
      description1: split.length > 1 ? split[0] : undefined,
      description2:
        split.length > 1 ? split.slice(1).join("\n\n") : description,
    };
  }, [description]);

  smallVersion = smallVersion || params.small;

  let className = "generated-game-card-grid-detail";
  if (smallVersion) className += " small-version";

  return (
    <div className={className} ref={containerRef}>
      <LudoScore score={game.score} bg={true} />
      <div
        className="top-ref"
        ref={topRef}
        style={{ top: "-50px", position: "absolute" }}
      />
      {onClose && (
        <div className="close-button" onClick={onClose}>
          <CloseOutlined className="font-size-xxxxxxl clickable" />
        </div>
      )}
      {imageUrl && (
        <div
          className="image-bg"
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
      )}
      {showCreateGDD && (
        <NewGDDModal
          open={showCreateGDD}
          onClose={() => setShowCreateGDD(false)}
          game={game}
          setLoading={setLoading}
          extraData={extraData[game.id]}
        />
      )}
      <div className="padded-content">
        <div className="section horizontal info">
          <div className="section left">
            <div className="section main-info">
              <span className="main-title">{title}</span>
              {description1 && <span className="subtitle">{description1}</span>}
              <span className="description">{description2}</span>
              <UncontrolledCollapse
                disable={!smallVersion}
                title="Similar Trending Games"
              >
                <GameSlideshow
                  title="Similar Trending Games"
                  onClick={onClickedGame}
                  games={trending_games}
                  extraData={extraData}
                />
              </UncontrolledCollapse>
              <UncontrolledCollapse
                disable={!smallVersion}
                title="Similar Games"
              >
                <GameSlideshow
                  title="Similar Games"
                  onClick={onClickedGame}
                  games={similar_games}
                  extraData={extraData}
                />
              </UncontrolledCollapse>
            </div>
          </div>
          <div className="section right">
            <UncontrolledCollapse
              title="Related Game Trends"
              disable={!smallVersion}
            >
              <div className="section trends">
                <span className="small-title">Related Game Trends</span>
                <div className="chips">
                  {trending_topics ? (
                    trending_topics.map((topic) => (
                      <Chip
                        key={topic.topic_id}
                        label={topic.title}
                        onClick={() => onClickedTopic(topic)}
                      />
                    ))
                  ) : (
                    <CircularProgress size={20} />
                  )}
                </div>
              </div>
            </UncontrolledCollapse>
            <GenerationParameters
              hints={hints}
              selected_genres={selected_genres}
              platform={platform}
              style={style}
              smallVersion={smallVersion}
              onGenerateVariations={onGenerateVariations}
            />
          </div>
        </div>
      </div>
      <div className="main-button">
        <MyButton
          className="text-gradient bold w-100"
          onClick={developIdea}
          loading={loading}
        >
          <FileCopyOutlined className="mr-2" /> Develop This Idea
        </MyButton>
      </div>
      <div className="section actions">
        <hr />
        <div className="action" onClick={onFavorite}>
          {!!favoriteMatch ? (
            <>
              <Favorite />
              <span>Remove from Favorites</span>
            </>
          ) : (
            <>
              <FavoriteBorder /> <span>Add to Favorites</span>
            </>
          )}
        </div>
        <div className="action" onClick={copyText}>
          <ContentCopyOutlined /> <span>Copy Text</span>
        </div>
        <div className="action">
          <Link
            to={{
              pathname: "/search",
              state: { data: { generated_game: game } },
            }}
            style={{ display: "contents" }}
            onClick={() => trackWrapper("search-similar")}
          >
            <SearchOutlined /> <span>Search Similar</span>
          </Link>
        </div>
        <div className="action" onClick={onGenerateVariations}>
          <LightbulbOutlined className="lightbulb" />
          <span>Generate Variations</span>
        </div>
        <div className="action" onClick={shareIdea}>
          <ShareOutlinedIcon /> <span>Share Idea</span>
        </div>
        <hr />
      </div>
      {totalNumber && (
        <div className="navigation">
          <div
            className="arrow-wrapper"
            onClick={hasPrevious ? () => onPreviousWrapper() : undefined}
          >
            <ArrowBackIosNewOutlined
              className={`arrow previous ${hasPrevious ? "active" : ""}`}
            />
          </div>
          <div className="section text">
            <span>Game Idea</span>
            <span className="tiny-title">
              {currentIndex}/{totalNumber}
            </span>
          </div>
          <div
            className="arrow-wrapper"
            onClick={hasNext ? () => onNextWrapper() : undefined}
          >
            <ArrowForwardIosOutlined
              className={`arrow next ${hasNext ? "active" : ""}`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const GenerationParameters = ({
  hints,
  selected_genres,
  platform,
  style,
  smallVersion,
}) => {
  return (
    <UncontrolledCollapse
      disable={!smallVersion}
      title="Generation Parameters"
      className="last"
    >
      <div className="section generation-params">
        <div className="section horizontal">
          <span className="small-title">Generation Parameters</span>
        </div>
        <div className="section params">
          {hints && hints.length > 0 && (
            <div className="section horizontal generation-params">
              <span>{hints.join(", ")}</span>
            </div>
          )}

          <div className="section horizontal space-between">
            {selected_genres?.length > 0 && (
              <div className="section text">
                <span className="tiny-title">Genre</span>
                {selected_genres.map((genre) => (
                  <span key={genre}>{genre}</span>
                ))}
              </div>
            )}

            {platform && (
              <div className="section text">
                <span className="tiny-title">Platform</span>
                <span>{platform}</span>
              </div>
            )}

            {style && (
              <div className="section text">
                <span className="tiny-title">Art Style</span>
                <span>{style}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </UncontrolledCollapse>
  );
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="carousel-button-group">
      <CustomRightArrow
        onClick={() => {
          next();
        }}
      />
      <CustomLeftArrow
        disabled={currentSlide === 0}
        onClick={() => {
          previous();
        }}
      />
    </div>
  );
};

const GameSlideshow = ({ title, games, onClick, extraData }) => {
  return (
    <div className="games">
      <span className="small-title">{title}</span>
      <div className="games-list">
        {games ? (
          <LudoCarousel
            key="similar_trending_games"
            className="games-carousel"
            renderButtonGroupOutside={true}
            customButtonGroup={<ButtonGroup />}
            customLeftArrow={null}
            customRightArrow={null}
            arrows={false}
          >
            {games.map((game) => (
              <ChartGame
                key={game.id}
                game={game}
                includeActions={false}
                onSelectedGame={onClick}
                trend={(extraData[game.id]?.infos || DEFAULT_OBJECT)[0]}
                showChartPosition={true}
              />
            ))}
          </LudoCarousel>
        ) : (
          <CircularProgress size={20} />
        )}
      </div>
    </div>
  );
};

export default GeneratedGameCard;
