import {useBus} from "react-bus";
import React, {useContext, useEffect, useMemo, useState} from "react";
import ShowIf from "components/common/ShowIf";
import {GeneratingButton} from "components/Controls/MyButton";
import APIContext from "context/APIContext";
import {MenuTopBar} from "pages/GDD3/GDDSideMenu";
import CacheContext from "context/CacheContext";
import {FAVORITE_TYPES, filterFavorites, GameGridWithIds} from "pages/Favorites";
import GameGrid from "components/common/GameGrid";
import {
  AutoAwesomeOutlined,
  EmojiEventsOutlined,
  FavoriteOutlined,
  SearchOutlined,
  Sync,
  TrendingUpOutlined, TryOutlined
} from "@mui/icons-material";
import GDDContext from "context/GDDContext";
import Trends from "pages/Trends";
import TopCharts from "pages/TopCharts";
import Search, {MODES} from "pages/Search";
import Chat from "../../../components/common/Chat";

const DEFAULT_ARRAY = [];

export const MENUS = {
  suggestions: {
    id: 'suggestions',
    buttonLabel: <span className="px-2">
      <AutoAwesomeOutlined className="font-size-lg mr-2"/>
      Suggestions
    </span>
  },
  favorites: {
    id: 'favorites',
    label: 'Favorites',
    buttonLabel: <FavoriteOutlined/>
  },
  charts: {
    id: 'charts',
    label: 'Top Charts',
    buttonLabel: <EmojiEventsOutlined/>
  },
  trends: {
    id: 'trends',
    label: 'Trends',
    buttonLabel: <TrendingUpOutlined/>
  },
  search: {
    id: 'search',
    label: 'Search',
    buttonLabel: <SearchOutlined/>
  },
}

const NON_LISTED_MENUS = {
  chat: {
    id: 'chat',
    label: 'Ask Ludo',
    buttonLabel: <TryOutlined/>
  }
}

const GamesMenu = ({component, gdd}) => {

  const bus = useBus();
  const {menu} = useContext(GDDContext);

  const section = useMemo(() => {
    return gdd.sections.find(({ id, name }) => id === menu.section || name === menu.section);
  }, [gdd, menu]);

  function onClick(game) {
    bus.emit(`${menu?.section}.click`, game);
  }

  return (
    <div className="menu-section">
      <MenuTopBar/>
      <div className="menu-section-content">
        <ShowIf condition={menu?.option === NON_LISTED_MENUS.chat.id}>
          <Chat
            component={component}
            section={section?.id || component.section}
            element={menu.element?.id}
            sectionValue={section}
          />
        </ShowIf>
        <ShowIf condition={menu?.option === MENUS.suggestions.id}>
          <GamesSuggestions component={component} section={menu?.section}/>
        </ShowIf>
        <ShowIf condition={menu?.option === MENUS.favorites.id}>
          <GamesFavorites value={section?.value} onClick={onClick}/>
        </ShowIf>
        <ShowIf condition={menu?.option === MENUS.trends.id}>
          <GameTrends value={section?.value} onClick={onClick}/>
        </ShowIf>
        <ShowIf condition={menu?.option === MENUS.charts.id}>
          <TopChartsMenu value={section?.value} onClick={onClick}/>
        </ShowIf>
        <ShowIf condition={menu?.option === MENUS.search.id}>
          <MenuGamesSearch value={section?.value} onClick={onClick} gdd={gdd}/>
        </ShowIf>
      </div>
    </div>
  );
}

const GameTrends = ({value, onClick}) => (
  <div className="game-trends">
    <Trends
      defaultLocation={value?.country}
      fullVersion={false}
      gameWidth={150}
      spacing={2}
      gameProps={{
        onClick,
        lazyLoad: false,
        infoIcon: true
      }}
    />
  </div>
);

const TopChartsMenu = ({value, onClick}) => (
  <div className="top-charts">
    <TopCharts
      key={value?.country}
      fullVersion={false}
      defaultLocation={value?.country}
      gameProps={{
        lazyLoad: false,
        onClick,
      }}
    />
  </div>
);

const MenuGamesSearch = ({value, onClick, gdd}) => {
  return (
    <div className="game-search">
      <Search
        fullVersion={false}
        gameWidth={170}
        spacing={2}
        gameProps={{
          onClick,
          lazyLoad: false,
          infoIcon: true
        }}
        excludeIds={value?.games}
        initialValues={{genres: gdd.genres, platform: gdd.platform, search_mode: MODES.games.value}}
      />
    </div>
  )
}

export const GamesFavorites = ({value, onClick}) => {

  const {cache} = useContext(CacheContext);
  const {allFavorites} = cache;

  const sectionIds = value?.games || DEFAULT_ARRAY;

  const gameIds = useMemo(() => {
    let ids = filterFavorites(allFavorites, FAVORITE_TYPES.game, 'payload_id');
    return ids.filter(id => !sectionIds.includes(id));
  }, [allFavorites, sectionIds]);

  return (
    <div className="favorites-list py-4">
      <div className="px-4 pb-4">
        <ShowIf condition={gameIds.length === 0}>
          <span className="explanation">No game favorites found</span>
        </ShowIf>
        <ShowIf condition={gameIds.length > 0}>
          <span className="explanation">Add a favorite game to the Game Concept</span>
        </ShowIf>
      </div>
      <GameGridWithIds
        gameIds={gameIds}
        gameProps={{onClick, lazyLoad: false, infoIcon: true}}
      />
    </div>
  );
}

const suggestProjectGames = 'suggestProjectGames';

const GamesSuggestions = ({component, section}) => {

  const bus = useBus();
  const {call} = useContext(APIContext);
  const {cache} = useContext(CacheContext);
  const {selectedProjectId} = cache;

  const [isLoading, setIsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState();

  useEffect(() => {
    loadSuggestions();
  }, [])

  async function loadSuggestions() {
    setSuggestions([]);
    setIsLoading(true);

    let payload = {
      id: selectedProjectId,
      type: component.section,
      includeGdd: true,
      n: 8
    };

    setTimeout(async () => {
      let response = await call(suggestProjectGames, payload);
      if (response.ok) setSuggestions(response.body);
      setIsLoading(false);
    }, 300);
  }


  function onClick(value) {
    bus.emit(`${section}.click`, value);
    let newSuggestions = (suggestions || []).filter(({_id}) => _id !== value._id);
    setSuggestions(newSuggestions);
    if (newSuggestions.length === 0) loadSuggestions();
  }

  return (
    <div className="games-suggestions d-flex flex-column pt-2 pb-5">
      <div className="px-4 pb-4">
        <ShowIf condition={suggestions?.length > 0}>
          <span className="explanation">Add suggested games to the Game Concept</span>
        </ShowIf>
      </div>
      <div className="suggestions px-0 py-2">
        <GameGrid
          games={suggestions}
          gameProps={{
            lazyLoad: true,
            onClick,
            infoIcon: true
          }}
        />
      </div>
      <GeneratingButton
        id="games.load-suggestions"
        className="mx-auto mt-3"
        color="secondary"
        onClick={loadSuggestions}
        loadProgressSecs={5}
        loading={isLoading}
        loadingText="Searching..."
      >
        <Sync className="font-size-lg mr-2 mt-1" />
        Refresh Suggestions
      </GeneratingButton>
    </div>
  );
}

export default GamesMenu;
