import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Editable } from "pages/GDD3/Helpers";
import _ from "lodash";
import { AddOutlined } from "@mui/icons-material";
import { Menu } from "pages/GDD3/GDDGameSummary";
import { MENUS, ASSET_MENUS } from "pages/GDD3/GDDSideMenu/AssetsMenu";
import { useListener } from "react-bus";
import GDDContext from "context/GDDContext";
import {
  getAssetPayloadId,
  onDownload3DModel,
  ThreeDAsset,
} from "../ThreeDGenerator";
import APIContext from "../../context/APIContext";

const GDDAssets = ({
  component,
  active,
  loading,
  scrollTo,
  changeGdd,
  section,
  value,
}) => {
  const ref = useRef();
  const addRef = useRef();

  const { call } = useContext(APIContext);

  const { menu, openMenu, preview } = useContext(GDDContext);
  const [selected, setSelected] = useState();
  const [downloading, setDownloading] = useState({});

  useEffect(() => {
    if (active && !value?.assets?.length) {
      setSelected("add");
    }
  }, [active, value]);

  useEffect(() => {
    if (!active) setSelected(false);
  }, [active]);

  useEffect(() => {
    if (active && scrollTo && ref.current) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 150);
    }
  }, [active, scrollTo]);

  useEffect(() => {
    if (value?.title === undefined) {
      changeText("3D Assets", "title");
    }
  }, [value]);

  const onClick = useCallback(
    (asset) => addAsset(asset),
    [section, value, changeGdd]
  );
  useListener(`${section}.click`, onClick);

  const actionsActive = loading ? false : active;
  function addAssets(assets) {
    if (assets?.length > 0) {
      let newAssetsValue = [...(value?.assets || []), ...assets];
      let newValue = {
        ...(value || {}),
        assets: _.uniqBy(newAssetsValue, (asset) => getAssetPayloadId(asset)),
      };
      changeGdd(section, newValue, true);
    }
  }

  function addAsset(asset) {
    if (asset) {
      return addAssets([asset]);
    }
  }

  function removeAsset(asset) {
    if (asset) {
      let newValue = {
        ...(value || {}),
        assets: (value?.assets || []).filter(
          (currentAsset) =>
            getAssetPayloadId(asset) !== getAssetPayloadId(currentAsset)
        ),
      };
      changeGdd(section, newValue, true);
    }
  }

  function changeText(text, field = "text") {
    let newValue = {
      ...(value || {}),
      [field]: text,
    };
    changeGdd(section, newValue, true);
  }

  function onClickedOption(option, asset, input) {
    openMenu({option, component, section, asset, input});
  }
  async function onDownload(format, asset) {
    setDownloading((prevState) => ({
      ...prevState,
      [getAssetPayloadId(asset)]: true,
    }));
    await onDownload3DModel(format, asset, call);
    setDownloading((prevState) => ({
      ...prevState,
      [getAssetPayloadId(asset)]: false,
    }));
  }

  function mergeAsset(id, asset) {
    if (asset) {
      let newAssetsValue = [...(value?.assets || [])].map((currentAsset) => {
        let currentId = getAssetPayloadId(currentAsset);
        if (id === currentId) {
          return { ...currentAsset, asset };
        }
        return currentAsset;
      });
      changeGdd(section, { ...(value || {}), assets: newAssetsValue }, true);
    }
  }

  let className = "section assets";
  if (actionsActive) className += " active";
  if ((value?.images || []).length === 0) className += " empty";
  else className += " keep-together";

  let menuWrapperClassName =
    "menu-wrapper d-flex flex-column w-fit hide-preview";
  if (menu?.component?.section === section) menuWrapperClassName += " open";
  if (selected === "add") menuWrapperClassName += " selected";

  let titleClassName = "menu-wrapper editable section-subtitle";
  if (selected === "title") titleClassName += " selected";

  let textClassName = "menu-wrapper editable description";
  if (selected === "text") textClassName += " selected";

  function onClickedAssetOption(option, asset) {
    if (option === ASSET_MENUS.delete.id) {
      removeAsset(asset);
    } else if (option === ASSET_MENUS.download.id) {
      onDownload("glb", asset);
    } else {
      openMenu({ option, component, section, asset });
    }
  }

  const assetWrapper = useCallback(
    (asset, assetComponent) => {
      let id = getAssetPayloadId(asset);
      return (
        <AddAsset
          key={id}
          component={component}
          onClick={() => setSelected(id)}
          selected={id === selected}
          menus={ASSET_MENUS}
          onClickedAssetOption={(option) => onClickedAssetOption(option, asset)}
          asset={asset}
          assetComponent={assetComponent}
          downloading={downloading[id]}
        />
      );
    },
    [selected, downloading]
  );

  return (
    <div className={className} ref={ref}>
      <div className="left-content">
        {preview ? (
          <span className="section-subtitle">{value?.title}</span>
        ) : (
          <div className={titleClassName}>
            <Editable
              className="w-100"
              value={value?.title}
              setValue={(value = "") => changeText(value, "title")}
              placeholder="3D Assets"
              onFocus={() => setSelected("title")}
              onBlur={() => setSelected()}
            />
          </div>
        )}
        <div className={textClassName}>
          <Editable
            className="w-100"
            value={value?.text}
            setValue={(value = "") => changeText(value)}
            placeholder="Enter text (optional)"
            onFocus={() => setSelected("text")}
            onBlur={() => setSelected()}
          />
        </div>
      </div>
      <div className="right-content">
        <div className="three-d-assets">
          {(value?.assets || []).map((asset) => {
            const id = getAssetPayloadId(asset);
            return assetWrapper(
              asset,
              <ThreeDAsset
                hideActions={true}
                onClick={() => setSelected(id)}
                threeDAsset={asset}
                onNewVideo={(asset) => mergeAsset(id, asset)}
              />
            );
          })}
        </div>
        {/*<ThreeDAssets
          assets={value?.assets || DEFAULT_ARRAY}
          onClick={(asset) => setSelected(getAssetPayloadId(asset))}
          hideActions={true}
        />*/}
        <div
          className={menuWrapperClassName}
          ref={addRef}
          onClick={() => setSelected("add")}
        >
          <Menu id="assets" options={MENUS} onClick={onClickedOption} />
          <div className="add-image">
            <AddOutlined />
          </div>
        </div>
      </div>
    </div>
  );
};

const AddAsset = ({
  onClick,
  onClickedAssetOption,
  selected,
  menus,
  asset,
  component,
  assetComponent,
  downloading,
}) => {
  let imageMenuWrapperClassName = "menu-wrapper image-menu-wrapper";
  if (selected) imageMenuWrapperClassName += " selected";
  if (!asset) imageMenuWrapperClassName += " hide-preview";
  if (!!asset) imageMenuWrapperClassName += " has-image";

  let modifiedMenus = { ...menus };

  if (!asset?.asset?.id) {
    modifiedMenus = { ...modifiedMenus, [ASSET_MENUS.download.id]: undefined };
  }

  if (downloading) {
    modifiedMenus[ASSET_MENUS.download.id] = {
      ...modifiedMenus[ASSET_MENUS.download.id],
      loading: true,
    };
  }

  return (
    <div className={imageMenuWrapperClassName} onClick={onClick}>
      <Menu
        key={downloading}
        id={`elements.${component.section}.asset`}
        options={modifiedMenus}
        onClick={(option) => onClickedAssetOption(option, asset)}
      />
      {assetComponent}
    </div>
  );
};

export default GDDAssets;
