import React, { useContext, useState, useRef, useEffect, useMemo } from "react";
import "./style.scss";
import APIContext from "context/APIContext";
import CacheContext from "context/CacheContext";
import logo from "assets/images/logo-square.png";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  AddCircleOutline,
  AddCommentOutlined,
  ArrowForwardOutlined,
  AutoAwesomeOutlined,
  CloseOutlined,
  SyncOutlined,
} from "@mui/icons-material";
import _ from "lodash";
import PerformanceUtils from "helpers/PerformanceUtils";
import SocketContext from "context/SocketContext";
import ContentEditable from "react-contenteditable";
import striptags from "striptags";
import ReactMarkdown from "react-markdown";
import FileUpload, { toBase64 } from "../../Controls/FileUpload";
import ImageGallery from "../ImageGallery";
import { GDDModal } from "../../../pages/GDD3/Helpers";
import { FAVORITE_TYPES, filterFavorites } from "../../../pages/Favorites";
import {FollowUps, isValidMessage, MessageBody} from "../AssistantChat";

const cancelGeneration = "cancelGeneration";
const chatGdd = "chatGdd";
const uploadImage = "uploadImage";
export const END_OF_TEXT = "<|endoftext|>";
var requestId;

const DEFAULT_ARRAY = [];

const Chat = ({
  section,
  element,
  component,
  sectionValue,
  ludo_score,
  ludo_score_metric,
  ludo_score_label,
  initialMessage,
  includeFollowUps = true,
  guide_page,
  onStartNewChat,
}) => {
  const { call } = useContext(APIContext);
  const { track } = useContext(SocketContext);
  const { cache, setCacheValue } = useContext(CacheContext);
  const [messages, setMessages] = useState([]);
  const [value, setValue] = useState(initialMessage || "");
  const [waiting, setWaiting] = useState(false);
  const [followUps, setFollowUps] = useState([]);
  const {
    selectedProjectId,
    streamingMessage,
    gddComponents,
    allFavorites = DEFAULT_ARRAY,
  } = cache;

  const [shouldSubmit, setShouldSubmit] = useState(!!initialMessage);
  const [conversationId] = useState(PerformanceUtils.generateId());
  const [images, setImages] = useState([]);
  const [favoritesModal, setFavoritesModal] = useState(false);

  const inputRef = useRef(null);
  const chatRef = useRef(null);

  const favorites = useMemo(
    () => filterFavorites(allFavorites, FAVORITE_TYPES.image),
    [allFavorites]
  );

  useEffect(() => {
    if (shouldSubmit) {
      setShouldSubmit(false);
      sendChatMessage(value);
    }
  }, [shouldSubmit, value, messages, selectedProjectId]);

  useEffect(() => {
    if (!waiting) {
      inputRef.current?.focus();
    }
  }, [waiting]);

  useEffect(() => {
    if (!initialMessage && includeFollowUps) {
      sendChatMessage("", true);

      return () => {
        onCancel();
      };
    }
  }, [initialMessage, includeFollowUps]);

  async function onCancel() {
    if (requestId)
      call(
        cancelGeneration,
        { generationId: requestId },
        { hideErrorMessage: true }
      );
    setWaiting(false);
  }

  async function onRegenerate() {
    let oldMessages = [...messages];
    oldMessages.splice(-1);
    let userMessage = oldMessages.splice(-1)[0];
    return sendMessages(oldMessages, userMessage, true);
  }

  async function sendChatMessage(text = value, force = false) {
    if (!force && (!text || waiting)) return;

    text = text.split("<br>").join("\n");
    text = striptags(text).split("&nbsp;").join(" ");

    const userMessage = {
      role: "user",
      text,
      selected_section: section,
      selected_element: element,
      images,
      ludo_score,
      ludo_score_metric,
      guide_page,
    };

    const oldMessages = [...messages];
    return sendMessages(oldMessages, userMessage);
  }

  async function sendMessages(oldMessages, currentMessage, regenerate = false) {
    setValue("");

    const isEmpty = !currentMessage?.text;

    let newMessages = currentMessage?.text
      ? [...oldMessages, currentMessage]
      : [...oldMessages];

    track("gdd.chat.send-message", {
      ...currentMessage,
      history_size: oldMessages.length,
      regenerate: regenerate || undefined,
      project_id: selectedProjectId,
    });

    setMessages(newMessages);
    setCacheValue("streamingMessage", []);
    if (!isEmpty) setWaiting(true);

    setTimeout(() => {
      chatRef.current.scroll({ top: chatRef.current.scrollHeight });
    }, 300);

    requestId = PerformanceUtils.generateId(
      regenerate || isEmpty ? "00000000" : undefined
    );

    setFollowUps([]);

    let data = {
      id: selectedProjectId,
      data: {
        conversation_id: conversationId,
        request_id: requestId,
        message: currentMessage.text,
        message_history: oldMessages,
        selected_section: section,
        selected_element: element,
        is_regeneration: !!regenerate,
        images: currentMessage.images,
        ludo_score: currentMessage.ludo_score,
        ludo_score_metric: currentMessage.ludo_score_metric,
        guide_page: currentMessage.guide_page,
      },
    };

    setImages([]);
    let response = await call(chatGdd, data);

    if (response.ok && data.data.request_id === requestId) {
      let message = undefined;
      if (isValidMessage(response.body) && response.body?.role) {
        message = { ...response.body };
      }
      if (response.body?.followups) {
        setFollowUps(response.body.followups || []);
      }
      if (message) {
        delete message.followups;
        setMessages([...newMessages, message]);
      }

      setCacheValue("streamingMessage", []);
    } else if (!response.ok) {
      setValue(currentMessage?.text);
      setImages(data.images);
    }
    setWaiting(false);
  }

  function onClickFollowUp(text) {
    sendChatMessage(text);
  }

  const cleanedStreamingMessage = useMemo(() => {
    return _.sortBy(
      _.uniqBy(
        (streamingMessage || []).filter((data) => data.requestId === requestId),
        "index"
      ),
      ["index"]
    );
  }, [streamingMessage]);

  const label =
    sectionValue?.value?.title ||
    gddComponents[component?.section || section]?.label;
  const labelElement =
    component?.section === "title" || component?.section === "summary"
      ? undefined
      : !element
      ? "section"
      : "element";

  const thisDescription = useMemo(() => {
    if (ludo_score_label && ludo_score_label !== "Ludo Score")
      return `"${ludo_score_label}" Ludo Score`;
    if (ludo_score) return "Ludo Score";
    if (label && labelElement) return `${label} ${labelElement}`;
    if (label) return label;
    return "Game Concept";
  }, [label, labelElement, ludo_score, ludo_score_metric, ludo_score_label]);

  function setNewValue(value = "") {
    setValue(value);
  }

  function openFavorites(event) {
    event.stopPropagation();
    event.preventDefault();
    setFavoritesModal(true);
  }

  async function onFilesUpdated(files) {
    if (files.length > 0) {
      setFavoritesModal(false);
      let binaryStrings = await Promise.all(
        files.map((file) => toBase64(file, false))
      );
      let data = { files: binaryStrings };
      let response = await call(uploadImage, { data });
      if (response.ok) {
        let images = response.body;
        setImages((prevState) => {
          let newResult = [...prevState];
          images.forEach((image) => {
            newResult = PerformanceUtils.editOrAdd(image, prevState, "url");
          });
          return newResult;
        });
      }
    }
  }

  async function onClickedFavoriteImage(image) {
    setImages((prevState) =>
      PerformanceUtils.editOrAdd(image, prevState, "url")
    );
    setFavoritesModal(false);
  }

  return (
    <div className="chat">
      <GDDModal
        open={favoritesModal}
        onClose={() => setFavoritesModal(false)}
        className="image-generator-modal"
      >
        <span className="top-right">
          <IconButton onClick={() => setFavoritesModal(false)}>
            <CloseOutlined className="font-size-xxl pointer text-secondary" />
          </IconButton>
        </span>
        <div className="px-4 m-auto modal-content">
          <center>
            <span className="font-weight-bold text-secondary">
              Upload an Image
            </span>

            <FileUpload
              accept="image/*"
              title={null}
              onFilesUpdated={(files) => onFilesUpdated(files)}
              maxFiles={1}
            />

            <span className="font-weight-bold text-secondary py-3 d-block">
              Or Select From Your Favorites
            </span>
          </center>

          <ImageGallery
            images={favorites}
            minImages={2}
            enforceSize={false}
            onImageClick={true}
            onImageClickFunc={(image) => onClickedFavoriteImage(image)}
          />
        </div>
      </GDDModal>
      <div className="chat-content" ref={chatRef}>
        <div className="top-banner">
          <div className="logo-wrapper">
            <img width="80" alt="Ludo" className="logo" src={logo} />
          </div>
          <div className="top-text">
            What would you like <span className="purple">know or do</span> about
            this <span className="blue">{thisDescription}</span>?
          </div>
        </div>

        <div className="messages">
          {messages.map((message, index) => {
            let className = "message";
            if (message.role === "user") className += " own";

            const isAssistant = message.role === "assistant";
            const showRegenerate =
              index === messages.length - 1 && !waiting && isAssistant;

            return (
              <div key={index} className={className}>
                <span className="message-author">{message.role}</span>
                <div className="body-wrapper">
                  <MessageBody message={message} />
                  {!showRegenerate && isAssistant && (
                    <div className="regenerate" />
                  )}
                  {showRegenerate && (
                    <Tooltip
                      title="Regenerate response"
                      arrow
                      PopperProps={{
                        className:
                          "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                      }}
                      placement="top"
                    >
                      <IconButton onClick={onRegenerate} className="regenerate">
                        <SyncOutlined className="font-size-xxl pointer text-tertiary" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
            );
          })}
          {waiting && messages[messages.length - 1]?.role === "user" && (
            <div className="message waiting">
              <span className="message-author">assistant</span>
              <div className="body-wrapper">
                {
                  <pre className="message-body">
                    <ReactMarkdown
                      children={cleanedStreamingMessage
                        ?.map(({ tokens }) =>
                          tokens.split(END_OF_TEXT).join("")
                        )
                        .join("")}
                    />
                  </pre>
                }
                <div className="regenerate" />
              </div>
            </div>
          )}
          {followUps.length > 0 &&
            (followUps[0]?.type ? (
              <FollowUps
                trackPrefix="gdd.chat"
                followUps={followUps}
                onClick={onClickFollowUp}
              />
            ) : (
              <OldFollowUps followUps={followUps} onClick={onClickFollowUp} />
            ))}
        </div>
      </div>

      <div className="outside-container">
        {onStartNewChat && (
          <Tooltip
            title="Start New Chat"
            arrow
            PopperProps={{
              className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
            }}
            placement="top"
          >
            <IconButton
              onClick={onStartNewChat}
              className="clear-history"
              disabled={messages.length === 0}
            >
              <AddCommentOutlined className="font-size-xxl pointer text-white" />
            </IconButton>
          </Tooltip>
        )}
        <div className="input-container">
          <div className="horizontal">
            <ContentEditable
              key="123"
              innerRef={inputRef}
              className="input-box"
              disabled={waiting}
              placeholder="Write your question or request here..."
              html={value || ""}
              onChange={(event) =>
                waiting ? undefined : setNewValue(event.target.value)
              }
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.code === "Enter") {
                  if (!event.shiftKey) {
                    setShouldSubmit(true);
                    event.preventDefault();
                    event.stopPropagation();
                  }
                }
              }}
            />
            {!waiting && (
              <Tooltip
                title="Select attachment"
                arrow
                PopperProps={{
                  className:
                    "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                }}
                placement="top"
              >
                <IconButton onClick={openFavorites} className="add-attachment">
                  <AddCircleOutline className="font-size-xxxxl pointer text-tertiary" />
                </IconButton>
              </Tooltip>
            )}
            {!waiting && (
              <Tooltip
                title="Send message"
                arrow
                PopperProps={{
                  className:
                    "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                }}
                placement="top"
              >
                <IconButton
                  onClick={() => sendChatMessage(value)}
                  className="send-message"
                  disabled={!value?.trim()}
                >
                  <ArrowForwardOutlined className="font-size-xxl pointer text-tertiary" />
                </IconButton>
              </Tooltip>
            )}
            {waiting && (
              <div className="cancel-wrapper">
                <Tooltip
                  title="Cancel request"
                  arrow
                  PopperProps={{
                    className:
                      "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                  }}
                  placement="top"
                >
                  <IconButton onClick={onCancel} className="cancel">
                    <CloseOutlined className="font-size-xxl pointer text-tertiary" />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
          {images?.length > 0 && (
            <div className="images">
              {images.map((image) => (
                <div className="attachment">
                  <CloseOutlined
                    className="close"
                    onClick={() =>
                      setImages((prevState) =>
                        PerformanceUtils.removeFromArray(
                          image,
                          prevState,
                          "url"
                        )
                      )
                    }
                  />
                  <img key={image.url} className="attachment" src={image.url} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const OldFollowUps = ({ followUps, onClick }) => {
  return (
    <div className="follow-ups">
      {followUps.map((followUp, index) => {
        return (
          <span
            key={index}
            className="follow-up"
            onClick={() => onClick(followUp)}
          >
            <AutoAwesomeOutlined className="font-size-lg mr-2" /> {followUp}
          </span>
        );
      })}
    </div>
  );
};

export default Chat;
