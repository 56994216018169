import { useBus } from "react-bus";
import React, { useContext, useEffect, useMemo, useState } from "react";
import ShowIf from "components/common/ShowIf";
import { GeneratingButton } from "components/Controls/MyButton";
import APIContext from "context/APIContext";
import { MenuTopBar } from "pages/GDD3/GDDSideMenu";
import CacheContext from "context/CacheContext";
import { FAVORITE_TYPES, filterFavorites } from "pages/Favorites";
import ImageGallery from "components/common/ImageGallery";
import {
  AutoAwesomeOutlined,
  BurstModeOutlined,
  TryOutlined,
  FavoriteOutlined,
  SearchOutlined,
  Sync,
  ModeEditOutlined,
} from "@mui/icons-material";
import GDDContext from "context/GDDContext";
import {
  IMAGE_TYPES,
  ImageGeneratorResults,
  prepareGeneratedImage,
} from "pages/ImageGenerator";
import Search, { MODES } from "pages/Search";
import { useHistory } from "react-router";
import Chat from "components/common/Chat";
import { TextField } from "@material-ui/core";

const DEFAULT_ARRAY = [];

export const MENUS = {
  suggestionsGeneratedArt: {
    id: "suggestionsGeneratedArt",
    showInput: true,
    buttonLabel: (
      <span className="px-2">
        <AutoAwesomeOutlined className="font-size-lg mr-2" />
        Suggest Art
      </span>
    ),
  },
  suggestionsGeneratedGameplay: {
    id: "suggestionsGeneratedGameplay",
    showInput: true,
    buttonLabel: (
      <span className="px-2">
        <AutoAwesomeOutlined className="font-size-lg mr-2" />
        Suggest Gameplay
      </span>
    ),
  },
  favorites: {
    id: "favorites",
    label: "Favorites",
    buttonLabel: <FavoriteOutlined />,
  },
};

const NON_LISTED_MENUS = {
  chat: {
    id: "chat",
    label: "Ask Ludo",
    buttonLabel: <TryOutlined />,
  },
};

export const IMAGE_MENUS = {
  editImage: {
    id: "editImage",
    buttonLabel: (
      <span className="px-2">
        <ModeEditOutlined className="mr-2" />
        Edit Image
      </span>
    ),
  },
};

const MoodboardMenu = ({ component, gdd }) => {
  const { menu } = useContext(GDDContext);

  const section = useMemo(() => {
    return gdd.sections.find(
      ({ id, name }) => id === menu.section || name === menu.section
    );
  }, [gdd, menu]);

  return (
    <div className="menu-section moodboard">
      <MenuTopBar />
      <div className="menu-section-content">
        <ShowIf condition={menu?.option === MENUS.suggestionsGeneratedArt.id}>
          <MoodboardSuggestions
            section={menu?.section}
            value={section?.value}
            component={component}
            imageType={IMAGE_TYPES.art.value}
            generated={true}
            artStyle={gdd.art_style}
            input={menu?.input}
          />
        </ShowIf>
        <ShowIf
          condition={menu?.option === MENUS.suggestionsGeneratedGameplay.id}
        >
          <MoodboardSuggestions
            section={menu?.section}
            value={section?.value}
            component={component}
            imageType={IMAGE_TYPES.screenshot.value}
            generated={true}
            artStyle={gdd.art_style}
            input={menu?.input}
          />
        </ShowIf>
        <ShowIf condition={menu?.option === MENUS.favorites.id}>
          <MoodboardFavorites section={menu?.section} value={section?.value} />
        </ShowIf>
        <ShowIf condition={menu?.option === NON_LISTED_MENUS.chat.id}>
          <Chat
            component={component}
            section={section?.id || component.section}
            element={undefined}
            sectionValue={section}
          />
        </ShowIf>
        <ShowIf condition={menu?.option === IMAGE_MENUS.editImage.id}>
          <MoodboardGenerator
            key="edit"
            section={menu?.section}
            value={section?.value}
            artStyle={gdd.art_style}
            gdd={gdd}
            initialImage={menu.image}
          />
        </ShowIf>
      </div>
    </div>
  );
};

export const MoodboardGenerator = ({
  value,
  section,
  artStyle,
  imageType,
  gdd,
  initialImage,
}) => {
  const bus = useBus();
  const history = useHistory();

  function onClick(image) {
    bus.emit(`${section}.click`, image);
  }

  function onHover(value) {
    bus.emit(`${section}.hover`, { image: value });
  }

  useEffect(() => {
    if (initialImage) {
      history.replace({
        ...history.location,
        state: { data: { image: initialImage } },
      });
    }
  }, [initialImage]);

  return (
    <div className="image-generator pb-4">
      <ImageGeneratorResults
        fullVersion={false}
        imageProps={{
          onImageClick: true,
          onImageClickFunc: onClick,
        }}
        excludeImages={value?.images}
        onHover={onHover}
        artStyle={artStyle}
        initialValues={{ genres: gdd.genres, image_type: imageType }}
        clearInitialImage={true}
      />
    </div>
  );
};

export const MoodboardSearch = ({ value, section, gdd }) => {
  const bus = useBus();

  function onClick(image) {
    bus.emit(`${section}.click`, image);
  }

  function onHover(value) {
    bus.emit(`${section}.hover`, { image: value });
  }

  return (
    <div className="image-search pb-4">
      <Search
        fullVersion={false}
        imageProps={{
          onImageClick: true,
          onImageClickFunc: onClick,
        }}
        excludeImages={value?.images}
        onHover={onHover}
        initialValues={{
          genres: gdd.genres,
          platform: gdd.platform,
          search_mode: MODES.screenshot.value,
        }}
      />
    </div>
  );
};

export const MoodboardFavorites = ({ section, value, name = "Moodboard" }) => {
  const bus = useBus();

  const { cache } = useContext(CacheContext);
  const { allFavorites = DEFAULT_ARRAY } = cache;

  const excludeImages = value?.images || DEFAULT_ARRAY;

  const images = useMemo(() => {
    let excludeUrls = excludeImages.map(({ url }) => url);
    return filterFavorites(allFavorites, FAVORITE_TYPES.image).filter(
      ({ url }) => !excludeUrls.includes(url)
    );
  }, [allFavorites, excludeImages]);

  function onClick(image) {
    bus.emit(`${section}.click`, image);
  }

  function onHover(value) {
    bus.emit(`${section}.hover`, { image: value });
  }

  return (
    <div className="favorites-list p-3">
      <ShowIf condition={images.length === 0}>
        <span className="explanation">No image favorites found</span>
      </ShowIf>
      <ShowIf condition={images.length > 0}>
        <span className="explanation m-3">
          Add favorite images to the {name}
        </span>
      </ShowIf>
      <ImageGallery
        images={images}
        minImages={2}
        onImageClick={true}
        onImageClickFunc={onClick}
        onHover={onHover}
      />
    </div>
  );
};

const suggestProjectImages = "suggestProjectImages";
const suggestProjectGeneratedImages = "suggestProjectGeneratedImages";

export const MoodboardSuggestions = ({
  section,
  generated = false,
  imageType = IMAGE_TYPES.art.value,
  autoSuggest = true,
  artStyle,
  input,
}) => {
  const bus = useBus();
  const { call } = useContext(APIContext);
  const { cache } = useContext(CacheContext);
  const { selectedProjectId } = cache;
  const { menu } = useContext(GDDContext);

  const [isLoading, setIsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState();
  const [currentInput, setCurrentInput] = useState(input);

  useEffect(() => {
    loadSuggestions(input);
  }, [input]);

  function onClick(value) {
    bus.emit(`${section}.click`, value);
    let newSuggestions = (suggestions || []).filter(
      ({ url }) => url !== value.url
    );
    setSuggestions(newSuggestions);
    if (newSuggestions.length === 0 && autoSuggest) loadSuggestions();
  }

  function onHover(value) {
    bus.emit(`${section}.hover`, { image: value });
  }

  async function loadSuggestions(input = currentInput) {
    setSuggestions([]);
    setIsLoading(true);
    const hints = input ? [input] : [];

    let payload = {
      id: selectedProjectId,
      data: generated
        ? {
            image_type: imageType,
            n: generated ? 3 : undefined,
            art_style: artStyle,
            hints,
          }
        : undefined,
    };

    let ep = generated ? suggestProjectGeneratedImages : suggestProjectImages;

    setTimeout(async () => {
      let response = await call(ep, payload);
      if (response.ok) {
        let images = response.body;
        if (generated) {
          images = images.map(prepareGeneratedImage);
        }
        setSuggestions(images);
      }
      setIsLoading(false);
    }, 300);
  }

  let imageDescription = "images";
  if (imageType === IMAGE_TYPES.screenshot.value) {
    imageDescription = "gameplay images";
  } else if (imageType === IMAGE_TYPES.art.value) {
    imageDescription = "art";
  }

  function onInputKeyDown(event) {
    if (event.key === "Enter") {
      loadSuggestions(currentInput);
    }
  }

  return (
    <div className="suggestions p-3 d-flex flex-column">
      <span className="explanation m-3">
        Add suggested{generated ? " generated" : ""} {imageDescription} to the{" "}
        {menu?.subSection || menu?.component?.label || "Image Gallery"}
      </span>
      <div style={{ padding: "0 15px" }}>
        <TextField
          className="text-field ludo-color"
          fullWidth
          placeholder="Enter a description (optional)"
          variant="standard"
          value={currentInput}
          margin="normal"
          onChange={(e) => setCurrentInput(e.target.value)}
          onKeyDown={(e) => onInputKeyDown(e)}
        />
      </div>
      <ImageGallery
        minImages={2}
        images={suggestions || DEFAULT_ARRAY}
        onImageClick={true}
        onImageClickFunc={onClick}
        onHover={onHover}
      />
      <GeneratingButton
        id="moodboard.load-suggestions"
        className="mx-auto mt-3"
        color="secondary"
        onClick={loadSuggestions}
        loadProgressSecs={5}
        loading={isLoading}
        loadingText={generated ? "Generating... " : "Searching..."}
      >
        <Sync className="font-size-lg mr-2 mt-1" />
        Refresh Suggestions
      </GeneratingButton>
    </div>
  );
};

export default MoodboardMenu;
