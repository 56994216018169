import { useEffect, useState, useCallback } from "react";
import ReactPixel from "react-facebook-pixel";
import RedditPixel from "react-reddit-pixel";
import { hotjar } from "react-hotjar";
import TagManager from "react-gtm-module";
import { usePostHog } from "posthog-js/react";
import { getCookieConsentValue } from "react-cookie-consent";
import config from "config/config.json";
import usePersistedState from "./usePersistedState";

const useAnalytics = (user, setAuth, call, setTrackingIds) => {
  const posthog = usePostHog();
  const [consent, setConsent] = useState(false);

  const [currentTrackingIds, setCurrentTrackingIds] = usePersistedState(
    "trackingIds",
    {}
  );

  const urlParams = new URLSearchParams(window.location.search);

  const getTrackingId = (paramName, cookieName) => {
    const urlValue = urlParams.get(paramName);
    const cookieValue = document.cookie.split('; ').find(row => row.startsWith(cookieName))?.split('=')[1];
    return urlValue || cookieValue || null;
  };

  const redditClickId = getTrackingId("rdt_cid", "_rdt_cid");
  const facebookClickId = getTrackingId("fbclid", "_fbc");
  const facebookPixelId = getTrackingId("fbp", "_fbp");

  useEffect(() => {
    if (consent) {
      setCurrentTrackingIds((prevState) => {
        return {
          ...prevState,
          ["rdt_cid"]: redditClickId,
          ["fbclid"]: facebookClickId,
          ["fbp"]: facebookPixelId,
        };
      });
    }
  }, [redditClickId, facebookClickId, facebookPixelId, consent]);

  useEffect(() => {
    setTrackingIds(currentTrackingIds);
  }, [currentTrackingIds]);

  useEffect(() => {
    const cookieConsent = getCookieConsentValue(config.CONSENT_COOKIE);
    const userConsent = user?.cookie_consent;
    setConsent(cookieConsent || userConsent);
  }, [user]);

  const updateUserConsent = useCallback(async () => {
    if (consent && !!user?._id && !user?.cookie_consent) {
      const response = await call("updateUserInfo", {
        data: { cookie_consent: true },
      });
      if (response?.ok) {
        setAuth({ user: response.body }, true, false);
      }
    }
  }, [consent, user, call, setAuth]);

  useEffect(() => {
    updateUserConsent();
  }, [updateUserConsent]);

  useEffect(() => {
    if (consent && facebookClickId) {
      initializeFacebookPixel(user);
    }
  }, [consent, user, facebookClickId]);

  useEffect(() => {
    if (consent) {
      initializeRedditPixel(user);
      initializeHotjar(user);
      initializeGoogleTagManager(user);
      initializePostHog();
    }
  }, [consent, user, facebookClickId, redditClickId]);

  const initializeFacebookPixel = (user) => {
    if (config.FACEBOOK_PIXEL) {
      ReactPixel.init(
        config.FACEBOOK_PIXEL,
        {
          em: user?.email?.toLowerCase().trim(),
          external_id: user?._id,
        },
        {
          autoConfig: true,
        }
      );
      ReactPixel.grantConsent();
    }
  };

  const initializeRedditPixel = (user) => {
    if (config.REDDIT_PIXEL) {
      RedditPixel.init(config.REDDIT_PIXEL, {
        email: user?.email?.toLowerCase().trim(),
        externalId: user?._id,
      });
    }
  };

  const initializeHotjar = (user) => {
    if (config.HOTJAR_ID) {
      hotjar.initialize(config.HOTJAR_ID, "6");
      if (user) {
        hotjar.identify(user._id, {
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        });
      }
    }
  };

  const initializeGoogleTagManager = (user) => {
    if (config.GOOGLE_TAG_ID) {
      TagManager.initialize({ gtmId: config.GOOGLE_TAG_ID });
      if (user) {
        TagManager.dataLayer({
          dataLayer: {
            userId: user._id,
          },
        });
      }
    }
  };

  const initializePostHog = () => {
    if (config.PUBLIC_POSTHOG_KEY && config.PUBLIC_POSTHOG_HOST) {
      posthog.init(config.PUBLIC_POSTHOG_KEY, {
        api_host: config.PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
        autocapture: false,
      });
    }
  };

  const trackPageView = (pathname) => {
    if (consent) {
      if (config.FACEBOOK_PIXEL) {
        ReactPixel.pageView();
      }
      if (config.REDDIT_PIXEL) {
        RedditPixel.pageVisit();
      }
      trackTagManager("pageview", { pagePath: pathname });
    }
  };

  function trackTagManager(event, data = {}) {
    if (consent && !!config.GOOGLE_TAG_ID) {
      TagManager.dataLayer({
        dataLayer: {
          event,
          ...data,
        },
      });
    }
  }

  function trackPosthog(event) {
    posthog?.capture(event);
  }

  return {
    trackTagManager,
    posthog,
    consent,
    trackPageView,
    trackPosthog,
    redditClickId,
  };
};

export default useAnalytics;
