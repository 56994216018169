import React, {useContext, useMemo, useState} from "react";
import {EmojiObjectsOutlined} from "@mui/icons-material";
import CacheContext from "context/CacheContext";

export const LOADING_TIPS_SECTIONS = {
  search: 'search',
  gameSearch: 'gameSearch',
  imageSearch: 'imageSearch',
  dailyTrends: 'dailyTrends',
  gameIdeator: 'gameIdeator',
  threeDGenerator: '3d-generator',
  imageGenerator: 'imageGenerator',
  videoGenerator: 'videoGenerator',
  gameElements: 'gameElements',
  marketAnalysis: 'marketAnalysis',
}

const LoadingTip = ({section, visible, className, style}) => {

  const {cache} = useContext(CacheContext);
  const {loadingTips} = cache;

  const sectionData = useMemo(() => {
    return loadingTips?.find(tip => tip.index === section);
  }, [section, loadingTips]);

  const [chosenTipIndex] = useState(Math.floor(Math.random() * sectionData?.tips?.length));

  if (!sectionData || !visible) return null;

  const tip = sectionData.tips[chosenTipIndex];
  const title = sectionData.label;

  let loadingTipClassName = "loading-tip";
  if (className) loadingTipClassName += " " + className;

  return (
    <div className={loadingTipClassName} style={style}>
      <div className="icon">
        <EmojiObjectsOutlined className="font-size-xxxxxl"/>
      </div>
      <span className="title">{title}</span>
      <span>{tip?.text}</span>
    </div>
  )
}

export default LoadingTip;
