import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Switch,
  Tooltip,
} from "@material-ui/core";
import APIContext from "context/APIContext";
import {
  MenuItem,
  Select,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import PageTitle from "components/layout-components/PageTitle";
import MyButton, { GeneratingButton } from "components/Controls/MyButton";
import CacheContext from "context/CacheContext";
import usePersistedState from "hooks/usePersistedState";
import FormikPersist from "components/utils/FormikPersist";
import * as Yup from "yup";
import ShowIf from "components/common/ShowIf";
import PerformanceUtils from "helpers/PerformanceUtils";
import UniversalInput, {
  ChangeDataOnLocation,
  convertUniversalInput,
} from "components/Controls/UniversalInput";
import "./style.scss";
import ImageGallery, {
  convertProxyUrl,
  MyImage,
} from "components/common/ImageGallery";
import FileUpload, {
  ThumbnailPreview,
  toBase64,
} from "components/Controls/FileUpload";
import { GDDModal } from "pages/GDD3/Helpers";
import { FAVORITE_TYPES, filterFavorites } from "pages/Favorites";
import { useHistory, useLocation } from "react-router";
import { Hint } from "scenes/Headquarters";
import {
  AutorenewOutlined,
  CloseOutlined,
  CropFreeOutlined,
  DeleteOutline,
  DownloadOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ModeEditOutline,
  ThreeDRotationOutlined,
  ViewInArOutlined,
} from "@mui/icons-material";
import LoadingTip, { LOADING_TIPS_SECTIONS } from "components/utils/LoadingTip";
import SocketContext from "../../context/SocketContext";
import { Examples } from "../GameGenerator";
import ImageEditor from "../ImageGenerator/ImageEditor";
const cancelGeneration = "cancelGeneration";
const uploadImage = "uploadImage";
const getImagePrompts = "getImagePrompts";
const generate3DImages = "generate3DImages";
const imageTo3D = "imageTo3D";
const export3D = "export3D";
const addFavorite3d = "addFavorite3d";
const removeFavorite = "removeFavorite";

const DEFAULT_OBJECT = {};
const DEFAULT_ARRAY = [];

let cancelBatches = [];

const ThreeDGenerator = () => {
  return (
    <div className="w-100 three-d-generator">
      <PageTitle
        titleHeading="3D Asset Generator"
        titleDescription="Generate 3D assets based on your own words."
      ></PageTitle>
      <ThreeDGeneratorResults />
    </div>
  );
};

export const ThreeDGeneratorResults = ({
  fullVersion = true,
  clearInitialImage,
  onClick,
  initialImage,
}) => {
  const { track } = useContext(SocketContext);
  const { cache } = useContext(CacheContext);
  const { call, loading } = useContext(APIContext);

  const locationHook = useLocation();
  const history = useHistory();

  const [currentBatchId, setCurrentBatchId] = useState(undefined);

  let resultsKey = "ThreeDGenerator.results" + (!fullVersion ? ".small" : "");
  let videosKey = "ThreeDGenerator.videos" + (!fullVersion ? ".small" : "");
  let filesKey = "ThreeDGenerator.files2" + (!fullVersion ? ".small" : "");
  let keyCurrent = "ThreeDGenerator.current" + (!fullVersion ? ".small" : "");

  const [results, setResults, loadingResults] = usePersistedState(
    resultsKey,
    []
  );
  const [videos, setVideos, loadingVideos] = usePersistedState(videosKey, {});
  const [currentFormValues, setCurrentFormValues] = usePersistedState(
    keyCurrent,
    DEFAULT_OBJECT
  );
  const [files, setFiles] = usePersistedState(filesKey, []);
  const [editor, setEditor] = useState(false);

  useEffect(() => {
    if (clearInitialImage) {
      setFiles([]);
    }
  }, [clearInitialImage]);

  const loadingGenerateImages = loading[generate3DImages];
  const { projects, selectedProjectId } = cache;
  const project = useMemo(() => {
    return (
      (projects || []).find((project) => project._id === selectedProjectId) ||
      {}
    );
  }, [projects, selectedProjectId]);


  useEffect(() => {
    if(initialImage) {
      fileFromImage(initialImage).then((result) => {
        setFiles([result])
      });
    }
  },[initialImage])

  useEffect(() => {
    if (!!locationHook?.state?.data) {
      const { image } = locationHook.state?.data;
      if (image) {
        fileFromImage(image).then((result) => setFiles([result]));
      }
      history.replace({ ...history.location, state: {} });
    }
  }, [locationHook]);

  function cancelGenerationWrapper() {
    if (currentBatchId) {
      cancelBatches.push(currentBatchId);
      call(
        cancelGeneration,
        { generationId: currentBatchId },
        { hideErrorMessage: true }
      );
    }
    setCurrentBatchId(undefined);
  }

  function onLoadMoreImages() {
    track("three-d-generator.generate_more", { ...currentFormValues });
    generate(currentFormValues);
  }

  const generate = async (values, chosenFiles = files) => {
    cancelGenerationWrapper();
    setCurrentFormValues(values);
    let { search } = values;

    let imageData = chosenFiles[0];

    let data = {
      request_id: PerformanceUtils.generateId(),
      ...convertUniversalInput(search),
    };

    if (imageData) {
      if (imageData.image) data[imageData.submit_field] = imageData.image;
      if (imageData.cropped)
        data[imageData.submit_field] = {
          ...data[imageData.submit_field],
          ...imageData.cropped,
        };
      else data[imageData.submit_field] = imageData.image || imageData;
    }

    setCurrentBatchId(data.request_id);
    let response = await call(generate3DImages, { data });
    if (response.ok) {
      setResults((prevState) => [
        ...response.body.map(prepareGeneratedImage),
        ...prevState,
      ]);
    }
    setCurrentBatchId(undefined);
  };

  function onCreateNew3DModel(image, video) {
    image = { ...image, id: PerformanceUtils.generateId() };
    video = { ...video, id: image.id };
    setResults((prevState) => [image, ...prevState]);
    setVideos((prevState) => ({ ...prevState, [video.id]: video }));
  }

  const assets = useMemo(() => {
    return results.map((result) => ({
      image: result,
      asset: videos[result.id],
    }));
  }, [results, videos]);

  return (
    <div className="generator-wrapper">
      <div className="form-wrapper pb-3">
        <ImageGeneratorForm
          files={files}
          setFiles={setFiles}
          generate={generate}
          project={project}
          loading={loadingGenerateImages}
          onCancel={cancelGenerationWrapper}
          fullVersion={fullVersion}
          setEditor={setEditor}
          editor={editor}
        />
      </div>
      {!loadingVideos && !loadingResults && (
        <div className="main-generator-content">
          <LoadingTip
            style={{
              marginLeft: "60px",
              marginTop: "30px",
              marginBottom: "30px",
            }}
            section={LOADING_TIPS_SECTIONS.threeDGenerator}
            visible={loadingGenerateImages || results?.length === 0}
            key={loadingGenerateImages}
          />
          <div className="buttons">
            {!loadingGenerateImages && results?.length > 0 && (
              <MyButton
                className="primary generate-more-button"
                color="primary"
                onClick={() => onLoadMoreImages()}
              >
                Generate More
              </MyButton>
            )}
            {results?.length > 0 && (
              <Tooltip
                title="Clear History"
                arrow
                PopperProps={{
                  className:
                    "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                }}
                placement="top"
              >
                <span>
                  <IconButton onClick={() => setResults([])}>
                    <DeleteOutline className="pointer text-blue" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </div>
          <ShowIf condition={results?.length > 0}>
            <ThreeDAssets
              assets={assets}
              onCreateNew3DModel={onCreateNew3DModel}
              onNewVideo={(video) =>
                setVideos((prevState) => ({ ...prevState, [video.id]: video }))
              }
              onClick={onClick}
            />
          </ShowIf>
        </div>
      )}
    </div>
  );
};

export const ThreeDAssets = ({
  assets,
  onNewVideo,
  onCreateNew3DModel,
  onClick,
  hideActions,
}) => {
  return (
    <div className="d-flex w-100 justify-content-center p-4 three-d-assets">
      {assets.map((asset) => (
        <ThreeDAsset
          key={asset.image.id}
          threeDAsset={asset}
          onNewVideo={onNewVideo}
          onCreateNew3DModel={onCreateNew3DModel}
          onClick={onClick}
          hideActions={hideActions}
        />
      ))}
    </div>
  );
};

const MODES = {
  image: "image",
  threeD: "threeD",
};

export const ThreeDAsset = ({
  threeDAsset,
  onNewVideo,
  onCreateNew3DModel,
  onClick,
  hideActions,
}) => {
  const { call } = useContext(APIContext);
  const { track } = useContext(SocketContext);
  const { removeFavoriteFromCache, addFavoriteToCache, cache } =
    useContext(CacheContext);

  const history = useHistory();

  const { allFavorites = DEFAULT_ARRAY, selectedProjectId } = cache;

  const [loading3D, setLoading3D] = useState(false);
  const [togglingFavorite, setTogglingFavorite] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const [downloading3D, setDownloading3D] = useState(false);
  const [mesh, setMesh] = useState(false);
  const [editor, setEditor] = useState(false);
  const [mode, setMode] = useState(asset ? MODES.threeD : MODES.image);

  const [asset, setAsset] = useState(threeDAsset.asset);
  const [image] = useState(prepareGeneratedImage(threeDAsset.image));

  function onEditImage() {
    track("three-d-generator.edit-image", { image });
    let data = {
      image,
    };
    history.push("/3d-generator", { data });
  }

  useEffect(() => {
    setAsset(threeDAsset.asset);
    if (threeDAsset.asset?.video_b64) {
      setMode(MODES.threeD);
    }
  }, [threeDAsset.asset]);

  const chosenVideo = mesh ? asset?.mesh_video_b64 : asset?.video_b64;

  const payloadId = getAssetPayloadId(threeDAsset);

  const favoriteMatch = useMemo(() => {
    return allFavorites.find(
      (favorite) => favorite.payload_id && favorite.payload_id === payloadId
    );
  }, [allFavorites, payloadId]);

  function onCancelVideo(id) {
    call(cancelGeneration, { generationId: id }, { hideErrorMessage: true });
  }

  const onGenerate3DModel = async (
    image,
    render_mesh,
    render_video,
    render_snapshots
  ) => {
    track("three-d-generator.generate-3d-model", { image });
    setLoading3D(true);
    let response = await call(imageTo3D, {
      image,
      render_mesh,
      render_video,
      render_snapshots,
    });
    if (response.ok && response.body) {
      if (chosenVideo && onCreateNew3DModel) {
        onCreateNew3DModel(image, response.body);
      } else {
        setAsset(response.body);
        if (onNewVideo) {
          onNewVideo(response.body);
        }
        setMode(MODES.threeD);
        if (!!favoriteMatch) {
          await removeFavoriteWrapper();
          await addFavorite(image, response.body);
        }
      }
    }
    setLoading3D(false);
  };

  let className = "three-d-asset " + mode;

  const imageElement = (
    <MyImage
      key={image.id || image.url}
      image={image}
      hideActions={true}
      onImageClickFunc={onClick ? () => onClick(threeDAsset) : undefined}
    />
  );

  async function toggleFavorite() {
    setTogglingFavorite(true);

    if (!!favoriteMatch) {
      track("three-d-generator.remove-favorite", { image, asset });
      await removeFavoriteWrapper();
    } else {
      track("three-d-generator.add-favorite", { image, asset });
      await addFavorite();
    }

    setTogglingFavorite(false);
  }

  async function removeFavoriteWrapper() {
    let toRemove = { ...favoriteMatch };
    let response = await call(removeFavorite, { id: favoriteMatch._id });
    if (response.ok) {
      removeFavoriteFromCache(toRemove);
    }
  }

  async function addFavorite(
    image = threeDAsset.image,
    asset = threeDAsset.asset
  ) {
    let response = await call(addFavorite3d, {
      data: { image, asset },
      projectId: selectedProjectId,
    });
    if (response.ok) {
      addFavoriteToCache(response.body);
    }
  }

  return (
    <div className={className}>
      {!hideActions && (
        <div className="controls">
          {asset?.mesh_video_b64 && (
            <Tooltip
              PopperProps={{
                className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
              }}
              title={mesh ? "Switch to Standard View" : "Switch to Mesh View"}
              arrow
              placement="top"
            >
              <div className="mesh-toggle">
                <Switch
                  checked={mesh}
                  onChange={() => {
                    track("three-d-generator.change-3d-mode", { mesh: !mesh });
                    setMesh(!mesh);
                  }}
                  color="primary"
                  icon={<ViewInArOutlined />}
                  checkedIcon={<ThreeDRotationOutlined />}
                />
              </div>
            </Tooltip>
          )}
          <Tooltip
            title="Edit Image"
            arrow
            PopperProps={{
              className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
            }}
            placement="top"
          >
            <IconButton
              onClick={() => setEditor(true)}
              className="edit-button button"
            >
              <ModeEditOutline className="font-size-xl" />
            </IconButton>
          </Tooltip>
          {chosenVideo && onNewVideo && (
            <Tooltip
              title="Generate New 3D Object"
              arrow
              PopperProps={{
                className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
              }}
              placement="top"
            >
              <IconButton
                disabled={loading3D}
                onClick={() => onGenerate3DModel(image, true, true, false)}
                className="regenerate-button button"
              >
                {loading3D ? (
                  <CircularProgress size={20} />
                ) : (
                  <AutorenewOutlined />
                )}
              </IconButton>
            </Tooltip>
          )}
          {chosenVideo && (
            <Tooltip
              title="Download 3D Object"
              arrow
              PopperProps={{
                className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
              }}
              placement="top"
            >
              <IconButton
                disabled={downloading3D}
                onClick={() => setShowDownloadPopup(true)}
                className="download-button button"
              >
                {downloading3D ? (
                  <CircularProgress size={20} />
                ) : (
                  <DownloadOutlined />
                )}
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title={
              !!favoriteMatch ? "Remove from Favorites" : "Add to Favorites"
            }
            arrow
            PopperProps={{
              className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
            }}
            placement="top"
          >
            <IconButton
              disabled={downloading3D}
              onClick={toggleFavorite}
              className="favorite-button button"
            >
              {togglingFavorite ? (
                <CircularProgress size={20} />
              ) : !!favoriteMatch ? (
                <FavoriteOutlined />
              ) : (
                <FavoriteBorderOutlined />
              )}
            </IconButton>
          </Tooltip>
        </div>
      )}
      {mode === MODES.image && (
        <>
          {imageElement}
          {asset && (
            <div
              className="video-thumbnail"
              onClick={() => setMode(MODES.threeD)}
            >
              <video
                src={chosenVideo}
                controls={false}
                autoPlay={true}
                playsInline={true}
                muted={true}
                loop={true}
              />
            </div>
          )}
          {!chosenVideo && (
            <GeneratingButton
              id="3d-asset.generate"
              className="gradient"
              loading={loading3D}
              onCancel={() => {
                onCancelVideo(image.id);
              }}
              onClick={() => onGenerate3DModel(image, true, true, false)}
              loadProgressSecs={5}
              style={{ margin: 0 }}
              trackOptions={{
                image,
              }}
            >
              Generate 3D Object
            </GeneratingButton>
          )}
        </>
      )}
      {mode === MODES.threeD && (
        <>
          <div className="image-placeholder">{imageElement}</div>
          {chosenVideo ? (
            <video
              src={chosenVideo}
              controls={false}
              autoPlay={true}
              playsInline={true}
              muted={true}
              loop={true}
              onClick={onClick ? () => onClick(threeDAsset) : undefined}
            />
          ) : null}
          <div className="image-thumbnail">
            <div
              style={{ display: "contents" }}
              onClick={() => setMode(MODES.image)}
            >
              {imageElement}
            </div>
          </div>

          <DownloadPopup
            open={showDownloadPopup}
            onClose={() => setShowDownloadPopup(false)}
            onDownload={async (format) => {
              track("three-d-generator.download-3d", { format });
              setDownloading3D(true);
              await onDownload3DModel(format, threeDAsset, call)
              setDownloading3D(false);
            }}
          />
        </>
      )}
      {editor && (
        <ImageEditor
          url={image.url}
          initialImage={image}
          onClose={() => setEditor(false)}
          formValues={{}}
          onResults={async (results) => {
            if (results.length > 0) {
              let resultImage = results[0];
              if (resultImage.id !== image.id) {
                onEditImage(resultImage);
              }
            }
            setEditor(false);
          }}
        />
      )}
    </div>
  );
};

const DownloadPopup = ({ open, onClose, onDownload }) => {
  const [format, setFormat] = useState("glb");

  const handleDownload = () => {
    onDownload(format);
    onClose();
  };

  return (
    <GDDModal open={open} onClose={onClose} title="Download 3D Object">
      <DialogContent>
        <FormControl className="w-100">
          <InputLabel>Format</InputLabel>
          <Select
            value={format}
            onChange={(e) => setFormat(e.target.value)}
            fullWidth
            margin="dense"
          >
            {["glb", "stl", "ply", "obj", "off"].map((f) => (
              <MenuItem key={f} value={f}>
                {f.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <MyButton onClick={handleDownload} color="primary">
          Download
        </MyButton>
      </DialogActions>
    </GDDModal>
  );
};

export function prepareGeneratedImage(image) {
  if (!image) return;
  const isEmbedded = !!image.image;
  let url = image.url || `data:image/png;base64, ${image.image}`;
  image = { ...image, url, embedded: isEmbedded };
  return {
    ...image,
    src: url,
    originalImage: image,
  };
}

async function fileFromImage(image, submit_field, rawData) {
  const urlToObject = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], "image.jpg", { type: blob.type });
  };

  let proxyUrl = convertProxyUrl(image, true);
  let file = await urlToObject(proxyUrl);
  file.preview = URL.createObjectURL(file);
  file.image = image;
  file.mask = rawData?.mask;
  file.cropped = rawData?.cropped;
  const isGameImage = image?.game_id;
  file.submit_field =
    submit_field ||
    (isGameImage ? "initial_game_image" : "initial_generated_image");
  return file;
}

const ImageGeneratorForm = ({
  generate,
  project = DEFAULT_OBJECT,
  loading,
  onCancel,
  files,
  setFiles,
  fullVersion,
  editor,
  setEditor,
}) => {
  const location = useLocation();
  const { cache } = useContext(CacheContext);
  const { call } = useContext(APIContext);
  const { allFavorites = DEFAULT_ARRAY } = cache;
  const [persistedData, setPersistedData] = useState();
  const [favoritesModal, setFavoritesModal] = useState(false);
  const [processedFiles, setProcessedFiles] = useState([]);
  const [examples, setExamples] = useState(DEFAULT_ARRAY);
  const [examplesKey, setExamplesKey] = useState(1);

  const favorites = useMemo(
    () => filterFavorites(allFavorites, FAVORITE_TYPES.image),
    [allFavorites]
  );

  const finalInitialValues = useMemo(() => {
    return {
      search: [],
    };
  }, []);

  const formKey =
    "ThreeDGenerator" +
    project._id +
    (!fullVersion ? "-small-" : "") +
    JSON.stringify(finalInitialValues || {});

  useEffect(() => {
    getExamplesWrapper("3d");
  }, [examplesKey]);

  async function getExamplesWrapper(image_type) {
    let data = {
      image_type,
      filters: {
        n: 1,
      },
    };
    let response = await call(getImagePrompts, { data });
    if (response.ok) {
      setExamples(response.body);
    }
  }

  useEffect(() => {
    if (files.length > 0) {
      Promise.all(
        files.map(async (file) => {
          return fileFromImage(file.image || file, undefined, file);
        })
      ).then((newFiles) => {
        setProcessedFiles(newFiles);
      });
    } else {
      setProcessedFiles([]);
    }
  }, [files]);

  async function receiveGenerateSimilar(data, formik) {
    let values = { ...formik.values };

    if (data.currentPath !== location.pathname)
      values = { ...finalInitialValues };
    else {
      values = { ...values, search: [] };
    }

    let newFiles = files;
    if (data.image) {
      newFiles = [await fileFromImage(data.image)];
      setFiles(newFiles);
      setTimeout(() => {
        //loading current value from cache, when generating
        //similar from outside page, will override this. so we
        //wait to make sure we get the correct image in the preview
        setFiles(newFiles);
      }, 150);
    }

    let image_type = data.image_type || data.image?.image_type;
    if (image_type) values.image_type = image_type;
    formik.setValues(values);
    //generate(values, newFiles);
  }

  async function onClickedFavoriteImage(image, formik) {
    formik.setFieldValue("strength", 50);
    setFiles([await fileFromImage(image)]);
    setFavoritesModal(false);
  }

  function openFavorites(event) {
    event.stopPropagation();
    event.preventDefault();
    setFavoritesModal(true);
  }

  async function onFilesUpdated(files, formik) {
    if (files.length > 0) {
      setFavoritesModal(false);
      formik.setFieldValue("strength", 50);
      let binaryStrings = await Promise.all(
        files.map((file) => toBase64(file, false))
      );
      let data = { files: binaryStrings };
      let response = await call(uploadImage, { data });
      if (response.ok) {
        let images = response.body;
        images = images.map((image) => {
          return { ...image, submit_field: "initial_image_file" };
        });
        setFiles(images);
      }
    }
  }

  function onRefresh() {
    setExamplesKey(examplesKey + 1);
  }

  return (
    <Formik
      key={formKey}
      initialValues={finalInitialValues}
      validateOnChange={true}
      validateOnBlur={false}
      validationSchema={BySentenceValidationSchema}
      onSubmit={(values) => generate(values, files)}
    >
      {(formik) => (
        <>
          {editor && (
            <ImageEditor
              url={files?.[0]?.url || files?.[0]?.image?.url}
              initialImage={files?.[0]?.image || files?.[0]}
              submitField={files?.[0]?.submit_field}
              onClose={() => setEditor(false)}
              formValues={formik.values}
              onResults={async (results) => {
                if (results.length > 0) {
                  setFiles([
                    await fileFromImage(results[0], "initial_generated_image"),
                  ]);
                }
                setEditor(false);
              }}
            />
          )}
          <FormikPersist
            name={formKey}
            onLoad={(data) => setPersistedData(data)}
          />
          {!!persistedData && (
            <ChangeDataOnLocation
              initialValues={finalInitialValues}
              fields={["search", "genres", "image_type"]}
              shouldOverride={() => true}
              override={receiveGenerateSimilar}
            />
          )}
          <Form>
            {!!persistedData && (
              <div className="d-flex flex-column">
                <Grid container>
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-end"
                  >
                    <Grid
                      item
                      sm={12}
                      md={12}
                      xs={12}
                      className="input-fields-wrapper"
                    >
                      <div className="d-flex input-fields full-border-radius">
                        <UniversalInput
                          name="search"
                          label="Leave empty, type keywords, phrases, game titles..."
                          formik={formik}
                          onSetData={(data) => {
                            formik.setFieldValue("search", data);
                          }}
                          value={formik.values.search}
                          allowed={[
                            "text",
                            "game",
                            "generated_game",
                            "gdd",
                            "topic",
                          ]}
                        />
                      </div>
                      <Examples
                        examples={examples}
                        formik={formik}
                        number={1}
                        mainIcon={false}
                        onRefresh={onRefresh}
                      />
                    </Grid>
                    <div className="image-options-wrapper">
                      <Grid
                        item
                        xs={fullVersion ? 4 : "auto"}
                        sm="auto"
                        md="auto"
                        className="file-wrapper-grid"
                      >
                        <div className="file-wrapper">
                          {files.length > 0 && (
                            <ThumbnailPreview
                              files={processedFiles}
                              removeFile={() => setFiles([])}
                            />
                          )}
                          {files.length === 0 && (
                            <div className="row">
                              <MyButton
                                onClick={openFavorites}
                                className="initial-image-button"
                              >
                                Choose asset image (optional)
                              </MyButton>
                              <Hint
                                iconClassName="mt-4"
                                hint="Choose an asset image to generate a 3D model from"
                              />
                            </div>
                          )}
                        </div>
                      </Grid>
                      <ShowIf condition={files.length > 0}>
                        <Grid
                          item
                          xs={fullVersion ? 12 : "auto"}
                          sm="auto"
                          md="auto"
                        >
                          <div className="slider-wrapper">
                            <MyButton
                              className="text-gradient"
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setEditor(true);
                              }}
                            >
                              <CropFreeOutlined className="mr-2" />
                              Open In Editor
                            </MyButton>
                          </div>
                        </Grid>
                      </ShowIf>
                    </div>
                  </Grid>
                  <div className="d-flex">
                    <GeneratingButton
                      id="image-generator.generate"
                      label="Start New Generation"
                      className="gradient"
                      loading={loading}
                      onCancel={onCancel}
                      style={{ margin: 0, marginLeft: "15px" }}
                      trackOptions={{
                        ...formik.values,
                        search: convertUniversalInput(formik.values.search),
                      }}
                      disabled={formik.values.search.length === 0 && files.length === 0}
                      loadProgressSecs={30}
                    />
                  </div>
                </Grid>
                <GDDModal
                  open={favoritesModal}
                  onClose={() => setFavoritesModal(false)}
                  className="image-generator-modal"
                >
                  <span className="top-right">
                    <IconButton onClick={() => setFavoritesModal(false)}>
                      <CloseOutlined className="font-size-xxl pointer text-secondary" />
                    </IconButton>
                  </span>
                  <div className="px-4 m-auto modal-content">
                    <center>
                      <span className="font-weight-bold text-secondary">
                        Upload an Image
                      </span>

                      <FileUpload
                        accept="image/*"
                        title={null}
                        onFilesUpdated={(files) =>
                          onFilesUpdated(files, formik)
                        }
                        maxFiles={1}
                      />

                      <span className="font-weight-bold text-secondary py-3 d-block">
                        Or Select From Your Favorites
                      </span>
                    </center>

                    <ImageGallery
                      images={favorites}
                      minImages={2}
                      enforceSize={false}
                      onImageClick={true}
                      onImageClickFunc={(image) =>
                        onClickedFavoriteImage(image, formik)
                      }
                    />
                  </div>
                </GDDModal>
              </div>
            )}
          </Form>
        </>
      )}
    </Formik>
  );
};

export default ThreeDGenerator;

const BySentenceValidationSchema = Yup.object().shape({
  search: Yup.array(),
});

export function getAssetPayloadId(threeDAsset) {
  return threeDAsset.image.id + threeDAsset.asset?.id;
}

export async function onDownload3DModel(format, asset, call) {
  let data = {
    request_id: asset.asset.request_id,
    format,
    asset_generation: asset.asset,
  };
  let response = await call(export3D, { data });
  if (response?.ok && response.body) {
    let base64 = response.body.data;
    let binaryString = atob(base64);
    let len = binaryString.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    let blob = new Blob([bytes], { type: response.body.type });
    let link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    const hints = Array.isArray(asset.image.hints) ? asset.image.hints : [];
    const hintString = hints.join(" ").replace(/\W+/g, "-").slice(0, 50);
    let name = hintString || "model";
    link.download = `${name}.${format}`;
    link.click();
  }
}
